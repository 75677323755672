<template>
  <div class="login-register-buttons__container">
    <ul class="wk-nav utd-right">
      <li v-if="showRegisterButton" role="presentation" class="wk-orange"><!--
        --><a :href="registerHref" v-text="registerButtonText" /><!--
    --></li>
      <li v-if="!isLoggedIn" role="presentation" class="wk-green"><!--
        --><a href="/login" v-text="loginButtonText" /><!--
    --></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('user', [ 'userLoggedIn' ]),
        ...mapGetters('login', [ 'loginRegisterButton', 'loginHref' ]),
        registerButtonText() {
            return this.$t('LOGIN.REGISTER');
        },
        loginButtonText() {
            return this.$t('MISC.LOG_IN');
        },
        registerHref() {
            return `${this.loginHref}?source=header`;
        },
        isLoggedIn() {
            return this.userLoggedIn;
        },
        showRegisterButton() {
            return this.loginRegisterButton
                && !this.isLoggedIn;
        }
    }
};
</script>

<style lang="less">
@media (min-width: 37.5rem) {
  .utdWkHeader .wk-navbar .wk-navbar-container {
    .mobileonDesktop&,
    .isSmallScreen&,
    .isTablet & {
      .login-register-buttons__container .wk-nav li {
        margin: 7px 7px 7px 16px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .utdDeskBanner {
      .login-register-buttons__container {
        display: none;
      }

      &.navOverflowed {
        .login-register-buttons__container {
          display: block;

          .fixedToolbar& {
            visibility: hidden;
          }
        }
      }
    }

    #utdNavRoot {
      .login-register-buttons__container {
        display: block;
        position: absolute;
        right: 0;
      }

      &.navOverflowed .login-register-buttons__container {
          visibility: hidden;
      }
    }

    .wk-header > .wk-header-container {
      .utd-header-top-right > .wk-navbar > .login-register-buttons__container {
        background: none;
      }
      .login-register-buttons__container {
        .wk-nav > li > a {
          height: 13px;
          padding: 8px 16px 11px;
          font-size: 13.3px;

          &:hover {
           color: #fff;
          }
        }

        .wk-green {
          padding-left: 25px;
        }
      }
    }
  }
}
</style>
