<template>
  <header>
    <!-- Desktop Account links -->
    <header v-if="isDesktopView"
            id="utdDeskBanner"
            class="wk-header utdDeskBanner"
            :class="{'navOverflowed': navOverflowed}"
            role="banner">
      <div class="wk-header-container">
        <div class="utd-header-top-left">
          <navigation-skip-links />
          <utd-logo />
          <utd-content-searchbar v-if="searchBarHeader" />
        </div>
        <div class="utd-header-top-right"
             role="menubar">
          <ul class="wk-nav" role="menu" aria-label="header user">
            <li v-if="showResourceCenterGuide">
              <utd-pendo-resource-center />
            </li>
            <li id="wkUtdWelcomeName"
                v-hover-delay="{ afterDelay: () => null,
                                 afterUnhoverDelay: hideAcctNav,
                                 sharedHoverState: 'accountNav' }"
                class="wkUtdWelcomeName"
                :class="{'loggedIn': isLoggedIn}"
                :aria-expanded="isShowAcctNav"
                aria-haspopup="true"
                @keyup.enter="toggleAcctNav()">
              <a tabindex="-1"
                 role="menuitem">
                <span>
                  <div v-hover-delay="{ afterDelay: showAcctNav,
                                        afterUnhoverDelay: () => null,
                                        sharedHoverState: 'accountNav' }"
                       class="chevron-down"
                       tabindex="0"
                       role="button"
                       aria-label="Account navigation"
                       @click="toggleAcctNav()" />
                  <span v-hover-delay="{ afterDelay: showAcctNav,
                                         afterUnhoverDelay: () => null,
                                         sharedHoverState: 'accountNav' }"
                        class="welcomeLabel"
                        @click="toggleAcctNav()"
                        v-text="welcomeName" />
                </span>
              </a>

              <account-menu v-if="isDesktopView"
                            v-show="isShowAcctNav"
                            id="utdAcctNav"
                            class="utdAcctNav"
                            @hide-nav="hideAcctNav"
                            @click-into="hideAcctNav" />
            </li>
            <li v-show="showTicker"
                class="utd-user-cme"><!--
                --><a :id="cmeTickerId('Header')"
                      tabindex="0"
                      :href="dashboardLink"
                      role="menuitem"
                      @focus="hideAcctNav()"><!--
                      --><utd-cme-ticker /></a>
            </li>
            <li v-show="isLoggedIn"
                class="utd-log-inout"><!--
                --><a href="/logout"
                      role="menuitem"
                      tabindex="0"
                      @click="resolveLogout('/logout')"
                      @focus="hideAcctNav() && hideContents()"
                      v-text="$t('LOGIN.SIGN_OUT')" />
            </li>
          </ul>
          <ul class="wk-nav utd-right">
            <li role="presentation"><trial-subscribe /></li>
          </ul>
          <login-register-buttons v-if="renderHeaderNavButtons"
                                  class="wk-navbar" />
        </div>
      </div>
    </header>

    <nav id="utdNavRoot"
         class="wk-navbar utdNavRoot"
         role="navigation"
         :class="{'open': isNavOpen,
                  'topicNav': isShowTopicNav,
                  'navOverflowed': navOverflowed}">
      <!-- Desktop Navbar -->
      <div class="wk-navbar-container" role="menubar">
        <nav id="utdMainNav"
             v-hover-delay="{ afterDelay: showTopicNav,
                              afterUnhoverDelay: hideTopicNav,
                              sharedHoverState: 'utdMainNav' }"
             class="wk-navbar utdMainNav"
             role="navigation">
          <ul v-if="!isProspectView"
              class="wk-nav"
              role="menu"
              aria-label="navigation">
            <li v-if="isNotDesktopView"
                id="homeHeader">
              <a href="/"
                 tabindex="0"
                 role="menuitem"
                 @focus="hideContents"
                 v-text="$t('HOME')" />
            </li>

            <li v-if="shouldShowToc"
                id="tocHeader"
                v-hover-delay="{ afterDelay: () => null,
                                 afterUnhoverDelay: hideContents,
                                 sharedHoverState: 'utdMainNav' }"
                class="tocHeader"
                :class="{'active': isShowContents}"
                @keypress.enter="toggleContents($event)">
              <a :href="localizeAnchor('/contents/', getTocBaseUrl, language)"
                 role="menuitem"
                 tabindex="0"
                 aria-haspopup="true"
                 :aria-expanded="isShowContents"
                 @click="toggleContents($event)">
                <span>
                  <span v-text="$t('HEADER.CONTENTS')" />
                </span>
              </a>
              <toc-home-menu v-if="isDesktopView" @dismiss-contents-menu="onContentMenuDismiss" />
            </li>

            <li v-if="shouldShowCalculators"
                id="calcHeader">
              <a :href="localizeAnchor('/contents/',
                                       'table-of-contents/calculators/alphabetized', language)"
                 role="menuitem"
                 tabindex="0"
                 @focus="hideContents"
                 v-text="$t('HEADER.CALCULATORS')" />
            </li>

            <li v-if="isUccState"
                id="pcuHeader">
              <a href="/contents/practice-changing-updates"
                 role="menuitem"
                 tabindex="0"
                 @focus="hideContents"
                 v-text="$t('HEADER.PRACTICE_CHANGING_UPDATES')" />
            </li>

            <li v-if="isUccState"
                id="whatsNewHeader">
              <a :href="localizeAnchor('/contents/','table-of-contents/whats-new', language)"
                 tabindex="0"
                 role="menuitem"
                 v-text="$t('HEADER.WHATS_NEW')" />
            </li>

            <li v-if="isUccState"
                id="patientEdHeader">
              <a :href="localizeAnchor('/contents/','table-of-contents/patient-education', language)"
                 tabindex="0"
                 role="menuitem"
                 v-text="$t('HEADER.PATIENT_EDUCATION')" />
            </li>

            <li v-if="showDrugInteractionsApp && !isUccState"
                id="drugIntHeader">
              <a href="/drug-interactions?source=responsive_home"
                 tabindex="0"
                 role="menuitem"
                 v-text="$t('HEADER.DRUG_INTERACTIONS')" />
            </li>

            <li v-if="!isUccState"
                v-show="hasPathwaysAccess"
                id="pathwaysHeader">
              <a :href="localizeAnchor('/contents/',
                                       'table-of-contents/pathways', language)"
                 role="menuitem"
                 v-text="$t('SEARCH.UPTODATE_PATHWAYS')" />
            </li>

            <li v-if="!isUccState && isShowRxTransitions"
                id="rxTransitionsHeader">
              <a href="/rxtransitions?source=responsive_home"
                 role="menuitem"
                 v-text="$t('HEADER.RX_TRANSITIONS')" />
            </li>
          </ul>
          <account-menu v-if="isNotDesktopView" />
        </nav>

        <!-- Mobile logged in user view -->
        <ul v-if="shouldShowMobileCme"
            id="utdMobileCme"
            class="wk-nav utd-left"
            role="menu">
          <li v-if="welcomeName"
              id="wkUtdWelcomeName"
              class="wkUtdWelcomeName"
              role="menuitem"
              v-text="welcomeName" />
          <li v-if="showTicker"
              id="wkutdCmeLi"
              class="wkutdCmeLi"
              role="menuitem">
            <a :id="cmeTickerId('Header')"
               :href="dashboardLink"
               class="headerCmeTicker"
               @click="hideMyAccountSidebar()">
              <utd-cme-ticker />
            </a>
          </li>
        </ul>

        <!-- Mobile user search results view -->
        <div v-if="shouldShowMobileSearchView" id="utdMobileBar" class="utdMobileBar">
          <button v-if="showBackButton"
                  v-utd-back
                  class="search-bar-left"
                  @click="navGoBack()" />
          <utd-content-searchbar />
        </div>

        <!-- Mobile authenticated -->
        <login-register-buttons v-if="renderHeaderNavButtons" id="loginRegisterButtons" />
        <ul class="wk-nav utd-right">
          <li role="presentation"><trial-subscribe /></li>
        </ul>
        <ul v-hover-delay="{ afterDelay: showTopicNav,
                             afterUnhoverDelay: hideTopicNav,
                             sharedHoverState: 'utdMainNav' }"
            class="wk-more-menu"
            @click="toggleNavMenu()">
          <li tabindex="0"
              aria-haspopup="true"
              aria-controls="scroll-topic-menu"
              :aria-expanded="isShowTopicNav"
              @focus="hideAcctNav"
              @keypress.enter="toggleNavMenu">
            <div v-if="!hideForOidcPartner" class="utd-hamburger">
              <div class="bar one">&zwj;</div>
              <div class="bar two">&zwj;</div>
              <div class="bar three">&zwj;</div>
            </div>
            <label v-text="$t('HEADER.MENU')" />
          </li>
        </ul>
      </div>
    </nav>
    <prospect-banner v-if="renderHeaderMarketingBanner" />
    <div id="utdAppShield"
         :class="{'coverApp': isNavOpen}"
         @click="toggleNavMenu()" />
  </header>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getWindow, getDocument, retainElementFocus, getDimensions } from '_acaSrc/utility/DOM';
import { safeTimeout } from '_acaSrc/utility/timers';
import { C_UI, C_CME_UI_LINKS } from '_acaSrc/utility/constants';
import { dispatchEvent } from '_acaSrc/utility/Events';

import UtdLogo from '_acaSrc/components/header/utdLogo/UtdLogo.vue';
import NavigationSkipLinks from '_acaSrc/components/header/NavigationSkipLinks.vue';
import UtdContentSearchbar from '_acaSrc/components/contents/search/SearchBar.vue';
import AccountMenu from '_acaSrc/components/header/AccountMenu.vue';
import UtdCmeTicker from '_acaSrc/components/header/UtdCmeTicker.vue';
import TrialSubscribe from '_acaSrc/components/header/TrialSubscribe.vue';
import LoginRegisterButtons from '_acaSrc/components/header/LoginRegisterButtons.vue';
import TocHomeMenu from '_acaSrc/components/header/TocHomeMenu.vue';
import ProspectBanner from '_acaSrc/components/ui/ProspectBanner.vue';

import HoverDelay from '_acaSrc/components/shared/utd/UtdHoverDelay.vue';
import utdBack from '_acaSrc/directives/UtdBack.directive';
import PubSub from '_acaSrc/utility/PubSub';
import { SET_IS_FIXED_TOOLBAR, SET_BODY_SCROLL_LOCK } from '_acaSrc/store/app.store';
import UtdPendoResourceCenter from '_acaSrc/components/shared/utd/UtdPendoResourceCenter.vue';

const FIXED_TOOLBAR_POSITION_LANDING = 20;

export default {
    components: {
        UtdPendoResourceCenter,
        UtdLogo,
        NavigationSkipLinks,
        UtdContentSearchbar,
        AccountMenu,
        UtdCmeTicker,
        TrialSubscribe,
        LoginRegisterButtons,
        TocHomeMenu,
        ProspectBanner
    },
    directives: {
        HoverDelay,
        utdBack
    },
    data() {
        return {
            toOverflow: null,
            navOverflowed: false,
            cancelOverflowTimeout: null,
            cancelResizeTimeout: null,
            startUrl: '',
            isShowTopicNav: false,
            isShowAcctNav: false,
            isNavOpen: false,
            isShowContents: false,
            dashboardLink: C_CME_UI_LINKS.dashboard
        };
    },
    computed: {
        ...mapGetters('app', [
            'isUccState',
            'isProspectView',
            'hideForOidcPartner',
            'transitions',
            'routeLoading',
            'isSearchView',
            'isLandingHome',
            'isFixedToolbar',
            'router'
        ]),
        ...mapGetters('toc', [ 'isTocView' ]),
        ...mapGetters('user', [ 'language', 'userLoggedIn', 'isCustomer' ]),
        ...mapGetters('profile', [ 'welcomeName' ]),
        ...mapGetters('cme', [ 'showTicker', 'cmeTickerId' ]),
        ...mapGetters('topic', [ 'isTopicView' ]),
        ...mapGetters('graphic', [ 'isGraphicView' ]),
        ...mapGetters('search', [ 'searchBarHeader', 'isSearchView', 'isHomeSearchEmpty' ]),
        ...mapGetters('device', [ 'isDesktopView', 'isNotDesktopView' ]),
        ...mapGetters('feature', [
            'showDrugInteractionsApp',
            'hasPathwaysAccess',
            'isShowRxTransitions',
            'topicRedesign'
        ]),
        ...mapGetters('pendo', [ 'showResourceCenterGuide' ]),
        isLoggedIn() {
            return this.userLoggedIn;
        },
        renderHeaderNavButtons() {
            return (this.routeLoading === 'search'
                        && this.isHomeSearchEmpty)
                    || (this.isDesktopView && !this.isLoggedIn);
        },
        getTocBaseUrl() {
            return this.topicRedesign && this.isNotDesktopView
                ? 'table-of-contents-home'
                : 'table-of-contents';
        },
        hasNavigated() {
            return this.startUrl !== getWindow().location.href;
        },
        showBackButton() {
            return !this.hideForOidcPartner || this.hasNavigated;
        },
        shouldShowMobileCme() {
            return (this.userLoggedIn)
                    && !this.searchBarHeader
                    && this.isNotDesktopView;
        },
        shouldShowMobileSearchView() {
            return this.searchBarHeader && this.isNotDesktopView;
        },
        shouldShowToc() {
            return this.isCustomer || this.userLoggedIn;
        },
        shouldShowCalculators() {
            return (this.isCustomer || this.userLoggedIn)
                    && !this.isUccState;
        },
        renderHeaderMarketingBanner() {
            return this.routeLoading === 'topic'
                    || this.routeLoading === 'topicLanguage';
        }
    },
    mounted() {
        this.startUrl = getWindow().location.href;
        this.setupListeners();
        this.setupTransitions();
    },
    beforeUnmount() {
        if (this.toOverflow) {
            this.cancelOverflowTimeout();
        }
        this.cancelResizeTimeout();
        this.clearResizeListener(this.overflowResizeListener);
        this.clearResizeListener(this.navMenuResizeListener);
        this.clearScrollListener(this.scrollListener);
        document.removeEventListener('click', this.handleClickEvent);
        new PubSub().unsubscribe('wkutd.repositionDialog', this.checkDesktopOverflows);
    },
    methods: {
        ...mapActions('login', [ 'resolveLogout' ]),
        ...mapMutations('app', [ 'SET_IS_FIXED_TOOLBAR', 'SET_BODY_SCROLL_LOCK' ]),
        navGoBack() {
            new PubSub().publish('wkutd.closeMyAccountSidebar');
        },
        setupListeners() {
            this.overflowResizeListener = this.setResizeListener(
                getWindow(), this.checkDesktopOverflows
            );
            this.navMenuResizeListener = this.setResizeListener(getWindow(), this.checkResizeScreen);
            this.scrollListener = this.setScrollListener(getWindow(), this.checkFixedToolbarState);
            document.addEventListener('click', this.handleClickEvent);
            this.cancelResizeTimeout = safeTimeout(() => {
                dispatchEvent('resize');
            }, C_UI.MODAL_CLOSING_DELAY_MS, { cancelHook: true }).cancelTimeout;
            new PubSub().subscribe('wkutd.repositionDialog', this.checkDesktopOverflows);
        },
        setupTransitions() {
            this.transitions.onBefore({}, () => {
                this.isShowTopicNav = false;
                if (this.isNavOpen) {
                    this.closeNavMenu();
                }
                if (this.isShowAcctNav) {
                    this.hideAcctNav();
                }
            });
            this.transitions.onSuccess({ from: 'login' }, () => {
                // This resize event will force a redraw of the main nav bar for desktop after
                // login to ensure that the "RX Transitions" menu entry does not wrap at smaller
                // browser widths (< 950px). It is recommended to not remove the transition filter,
                // as that may introduce potentially hard to track down UI anomalies.
                dispatchEvent('resize');
            });
        },
        hideAcctNav() {
            this.isShowAcctNav = false;
        },
        showAcctNav() {
            this.isShowAcctNav = true;
            // hide other menus and/or modals
            this.isShowTopicNav = false;
            new PubSub().publish('wkutd.dismissAutoComplete');
            new PubSub().publish('wkutd.closeMyAccountSidebar');
        },
        toggleAcctNav() {
            if (!this.isShowAcctNav) {
                return this.showAcctNav();
            }
            this.hideAcctNav();
        },
        showContents() {
            if (!this.isDesktopView || this.isShowContents) {
                return;
            }
            this.isShowContents = true;
        },
        hideContents() {
            if (!this.isDesktopView || !this.isShowContents) {
                return;
            }
            this.isShowContents = false;
        },
        showTopicNav() {
            if (!this.isDesktopView) {
                return;
            }
            this.hideAcctNav();
            this.isShowTopicNav = true;
        },
        hideTopicNav() {
            if (!this.isDesktopView) {
                return;
            }
            this.isShowTopicNav = false;
        },
        toggleNavMenu() {
            if (this.isNotDesktopView) {
                this.isNavOpen = !this.isNavOpen;
                this[SET_BODY_SCROLL_LOCK](this.isNavOpen);
                if (this.isNavOpen) {
                    new PubSub().publish('wkutd.dismissAutoComplete');
                    new PubSub().publish('wkutd.closeMyAccountSidebar');
                }
            }
            else if (!this.isShowTopicNav) {
                this.showTopicNav();
                new PubSub().publish('wkutd.dismissAutoComplete');
                retainElementFocus('#tocHeader a');
            }
            else {
                this.hideTopicNav();
            }
        },
        checkNavOverflow() {
            // If user is not logged in, check if 'Register'/'Log In'
            // buttons would overflow the main navigation container
            if (!this.userLoggedIn) {
                // "Register" & "Login" buttons are considered overflowed if the left edge
                // of their container is less than the right edge of the width of the nav
                // bar container contents.
                const wkNavBar = getDimensions(
                    document.querySelector('.utdMainNav .wk-nav'));
                const wkNavUtdRgt = getDimensions(
                    document.querySelector('.utdNavRoot login-register-buttons'));
                if (wkNavUtdRgt && wkNavBar) {
                    this.navOverflowed = wkNavUtdRgt.left < wkNavBar.width;
                }
            }
            else {
                // If user is logged in, clear navOverflowed flag
                // as it is only relevant for unauthenticated Enterprise views
                this.navOverflowed = false;
            }
        },
        checkDesktopOverflows() {
            if (this.toOverflow !== null) {
                this.cancelOverflowTimeout();
            }
            const { promise, cancelTimeout } = safeTimeout(() => {
                // These tests are only applicable to desktop views
                if (this.isDesktopView) {
                    this.checkNavOverflow();
                }
                else {
                    // Ensure navOverflowed flag gets shut off
                    // when not in desktop mode.
                    this.navOverflowed = false;
                }
            }, C_UI.DESKTOP_OVERFLOW_DELAY_MS, { cancelHook: true });
            this.cancelOverflowTimeout = cancelTimeout;
            this.toOverflow = promise;
        },
        toggleContents(event) {
            if (this.isDesktopView) {
                event.preventDefault();
                event.stopImmediatePropagation();
                this.isShowContents = !this.isShowContents;
                return false;
            }
        },
        // eslint-disable-next-line complexity
        checkFixedToolbarState() {
            if (this.rxTransitionsHamburger()) {
                this[SET_IS_FIXED_TOOLBAR](true);
                return;
            }
            if (!this.isSearchView
             && !this.isTopicView
             && !this.isTocView
             && !this.isGraphicView
             && !this.isLandingHome) {
                if (this.isFixedToolbar) {
                    this[SET_IS_FIXED_TOOLBAR](false);
                }
                return;
            }
            const fixedOffsetPosition = this.isLandingHome
                ? FIXED_TOOLBAR_POSITION_LANDING : C_UI.FIXED_TOOLBAR_POSITION_PX;

            const isScrolledTop = getWindow().pageYOffset >= fixedOffsetPosition;
            if (this.isFixedToolbar !== isScrolledTop) {
                this[SET_IS_FIXED_TOOLBAR](isScrolledTop);
            }
        },
        rxTransitionsHamburger() {
            const screenWidth = getDocument().documentElement.clientWidth;
            if (this.isShowRxTransitions
             && this.hasPathwaysAccess
             && screenWidth < C_UI.RX_TRANSITIONS_MENU_BREAKPOINT) {
                return true;
            }
        },
        checkResizeScreen() {
            this.closeNavMenu();
            this.checkFixedToolbarState();
        },
        closeNavMenu() {
            if (this.isNavOpen) {
                this.isNavOpen = false;
                this[SET_BODY_SCROLL_LOCK](false);
            }
        },
        handleClickEvent(event) {
            if (this.isNotDesktopView) {
                this.handleMobileClickEvent(event);
            }
            return true;
        },
        handleMobileClickEvent(event) {
            const utdMainNav = document.getElementById('utdMainNav');
            const wkMoreMenu = document.querySelector(
                '#utdNavRoot .wk-navbar-container .wk-more-menu'
            );
            let doClose = false;
            if (!utdMainNav || !wkMoreMenu) {
                return;
            }

            // Close the nav menu if user has clicked outside of
            // the main navigation, and outside of the hamburger
            // menu activation trigger.
            // Or close the nav menu if user has clicked anywhere
            // inside of the nav menu
            if ((!utdMainNav.contains(event.target) && !wkMoreMenu.contains(event.target))
                    || utdMainNav.contains(event.target)) {
                doClose = true;
            }
            if (doClose) {
                this.closeNavMenu();
            }
        },
        onContentMenuDismiss() {
            this.isShowContents = false;
        },
        hideMyAccountSidebar() {
            new PubSub().publish('wkutd.closeMyAccountSidebar');
        }
    }
};
</script>
<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

.wk-nav li.wkUtdWelcomeName > a {
  .ds1-pl-0();
}

.utdWkHeader .wk-navbar .wk-more-menu .utd-hamburger {
  margin: 19px 10px;
}

@media only screen and (max-width: 850px) {
  .isDesktop.utdWkHeader {
    &.fixedToolbar {
      .utd-header-top-right {
        .utd-user-cme,
        .utd-log-inout {
          display: none;
        }
      }
    }

    .utdWkHeader .wk-nav > li.utd-user-cme > a {
      .ds1-pt-0();
    }
  }
}

@media only screen and (min-width: 1023px) {
  .utd-header-top-right {
    padding-right: 17px;
  }
}

@media only screen and (min-width: 1439px) {
  .utd-header-top-right {
    padding-right: 25px;
  }
}

.navBarHamburgerMenu {
  .isDesktop.utdWkHeader {
    &.fixedToolbar {
      #appContainer {
        > header {
          > header {
            position: fixed;
            display: block;
            width: auto;
            top: 0;
            left: 0;
            right: 86px;
            height: @WK-HEADER-CONTAINER-HEIGHT;

            .search-bar-ac-unit {
              right: 0;

              .utd-logo-cc& {
                right: 5em;
              }
            }

            .search-bar-container {
              top: 0;
            }
          }

          > .wk-navbar {
            position: fixed;
            top: 0;

            &.topicNav {
              #utdMainNav {
                display: block;
                background-color: @WKCE-BLUE-SHADE1;
              }

              #utdMainNav .wk-nav > .tocHeader.active > a {
                background-color: @WKCE-BLUE;
              }

              .wk-more-menu {
                background-color: @WKCE-BLUE-SHADE1;
              }
            }

            #utdMainNav {
              display: none;
              background-color: @WKCE-BLUE;
              position: fixed;
              min-width: 200px;
              top: @WK-HEADER-CONTAINER-HEIGHT;
              bottom: auto;
              right: 0;
              left: auto;

              .wk-nav {
                display: block;
                margin: 0;
                padding: 8px 0;
                float: none;

                & > li {
                  display: block;
                  position: relative;
                  border-bottom: 0;
                  padding: 0;
                  margin: 0;
                  float: none;

                  & > a {
                    display: block;
                    color: #fff;
                    background-color: @WKCE-BLUE-SHADE1;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                    line-height: 16px;
                    padding: 8px 24px;

                    &:hover {
                      background-color: @WKCE-BLUE;
                    }
                  }
                }
              }
            }

            .wk-more-menu {
              cursor: pointer;
              display: block;
              height: @WK-HEADER-CONTAINER-HEIGHT;
              padding: 0 16px;

              .utd-hamburger {
                display: inline-block;
                width: 12px;
                height: 13px;
                margin: 0;

                .bar {
                  height: 1px;

                  &.two {
                    .css-transform(scaleX(1) translateY(5px));
                    .css-transform-origin(1px 5px);
                  }

                  &.three {
                    .css-transform(translateY(10px));
                  }
                }
              }

              label {
                cursor: pointer;
                display: inline-block;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 15px;
                line-height: @WK-HEADER-CONTAINER-HEIGHT;
                padding-left: 4px;
                color: #fff;
                width: 38px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .navBarHamburgerMenu();
}
</style>
