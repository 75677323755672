<template>
  <div class="server-test__wrapper">
    <div class="server-test__container">
      <h1>UpToDate Web Server Access Test</h1>

      <h2>What's New?</h2>
      <p>UpToDate has deployed a fully-redundant global web environment.
        This global load balancing upgrade deploys web server facilities in
        multiple locations, providing fully-distributed delivery of UpToDate online,
        the web-based UpToDate application.</p>

      <h2>Benefit to your Institution:</h2>
      <p>The system will automatically route users to the fastest available
        web server. This will provide superior performance, along with the
        highest-possible level of availability. With global load balancing,
        it will be necessary for your institution to allow access to <i>all</i> UpToDate
        IP addresses to ensure continuous access for your UpToDate online users.
      </p>

      <h2>Verify your Access:</h2>
      <p>Please verify that your facility can access all web server location</p>

      <div class="wk-js2">
        <table class="wk-table wk-table-extra-small">
          <tr>
            <th>Region</th>
            <th>Status</th>
          </tr>
          <tr>
            <td>New Jersey (US East Coast)</td>
            <td>
              <span :class="statusIconClassNJ" />
              <small>{{ statusLabelNJ }}</small>
              <img class="server-test__ping" target="_blank" alt=""><img>
            </td>
          </tr>
          <tr>
            <td>San Jose (US West Coast)</td>
            <td>
              <span :class="statusIconClassSJ" />
              <small>{{ statusLabelSJ }}</small>
              <img class="server-test__ping" target="_blank" alt=""><img>
            </td>
          </tr>
          <tr>
            <td>Tokyo, Japan (Asia)</td>
            <td>
              <span :class="statusIconClassTK" />
              <small>{{ statusLabelTK }}</small>
              <img class="server-test__ping" target="_blank" alt=""><img>
            </td>
          </tr>
          <tr>
            <td>Frankfurt, Germany (Europe)</td>
            <td>
              <span :class="statusIconClassFF" />
              <small>{{ statusLabelFF }}</small>
              <img class="server-test__ping" target="_blank" alt=""><img>
            </td>
          </tr>
        </table>
      </div>

      <utd-button class="server-test__button"
                  @click="beginTest">
        Test again
      </utd-button>

      <div v-show="showResults">
        <h2>Next Steps:</h2>
        <div v-if="showSuccess">
          <span class="wkce-icon-filled-check-circle" />
          <strong>Congratulations! All of the tests completed successfully!</strong>
          No further action is required.
        </div>
        <div v-else>
          <span>
            <span class="wkce-icon-filled-stop-sign" />
          </span>
          <span class="server-test__error--text">
            One or more of the tests failed.
          </span>
          Please forward the technical information below to your IT department as
          further action is required.
        </div>
      </div>

      <h2>Requirements:</h2>
      <p>Institutions using firewalls, proxy servers, and VPN connections must
        permit access to all of the UpToDate IP addresses, both new and existing,
        as listed below.</p>

      <div class="wk-js2">
        <table class="wk-table wk-table-bordered">
          <tr>
            <th colspan="6">
              UpToDate IP Addresses
            </th>
          </tr>
          <tr>
            <th>Site</th>
            <th>San Jose, Ca.</th>
            <th>
              Secaucus, NJ
              <br>Site 1<br>
            </th>
            <th>
              Secaucus, NJ
              <br>Site 2<br>
            </th>
            <th>Tokyo, Japan</th>
            <th>Frankfurt, Germany</th>
          </tr>
          <tr>
            <th>Net Addr</th>
            <td>12.130.132.32/27</td>
            <td>199.107.238.192/27</td>
            <td>63.240.11.64/27</td>
            <td>203.163.124.32/27</td>
            <td>195.33.169.32/27</td>
          </tr>
          <tr>
            <th>First Host</th>
            <td>12.130.132.33</td>
            <td>199.107.238.193</td>
            <td>63.240.11.65</td>
            <td>203.163.124.33</td>
            <td>195.33.169.33</td>
          </tr>
          <tr>
            <th>Last Host</th>
            <td>12.130.132.62</td>
            <td>199.107.238.222</td>
            <td>63.240.11.94</td>
            <td>203.163.124.62</td>
            <td>195.33.169.62</td>
          </tr>
          <tr>
            <th>No. Hosts</th>
            <td>30</td>
            <td>30</td>
            <td>30</td>
            <td>30</td>
            <td>30</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';

export const C_TEST = {
    PING: {
        IN_PROGRESS: {
            LABEL: 'Testing . . .',
            ICON: 'wkce-icon-filled-spinner-alt wk-spin'
        },
        SUCCESS: {
            LABEL: 'Success!',
            ICON: 'wkce-icon-filled-check-circle'
        },
        FAILURE: {
            LABEL: 'Failed!',
            ICON: 'wkce-icon-filled-stop-sign'
        }
    },
    SERVERS: {
        COLLECTION: [],
        SERVER_NJ: {
            LABEL: 'nj',
            IP: '199.107.238.205'
        },
        SERVER_SJ: {
            LABEL: 'sj',
            IP: '12.130.132.46'
        },
        SERVER_TK: {
            LABEL: 'tk',
            IP: '203.163.124.46'
        },
        SERVER_FF: {
            LABEL: 'ff',
            IP: '195.33.169.46'
        }
    }
};

export default {
    components: {
        UtdButton
    },
    data() {
        return {
            test: C_TEST,
            statuses: {}
        };
    },
    computed: {
        showSuccess() {
            return Object.values(this.statuses).every(value => {
                return value === this.test.PING.SUCCESS.LABEL;
            });
        },
        showResults() {
            return Object.values(this.statuses).every(value => {
                return value === this.test.PING.SUCCESS.LABEL
                    || value === this.test.PING.FAILURE.LABEL;
            });
        },
        statusIconClassNJ() {
            return this.statusIconClass(this.test.SERVERS.SERVER_NJ.LABEL);
        },
        statusIconClassSJ() {
            return this.statusIconClass(this.test.SERVERS.SERVER_SJ.LABEL);
        },
        statusIconClassTK() {
            return this.statusIconClass(this.test.SERVERS.SERVER_TK.LABEL);
        },
        statusIconClassFF() {
            return this.statusIconClass(this.test.SERVERS.SERVER_FF.LABEL);
        },
        statusLabelNJ() {
            return this.statuses[this.test.SERVERS.SERVER_NJ.LABEL];
        },
        statusLabelSJ() {
            return this.statuses[this.test.SERVERS.SERVER_SJ.LABEL];
        },
        statusLabelTK() {
            return this.statuses[this.test.SERVERS.SERVER_TK.LABEL];
        },
        statusLabelFF() {
            return this.statuses[this.test.SERVERS.SERVER_FF.LABEL];
        }
    },
    mounted() {
        this.test.SERVERS.COLLECTION = [
            this.test.SERVERS.SERVER_NJ,
            this.test.SERVERS.SERVER_SJ,
            this.test.SERVERS.SERVER_TK,
            this.test.SERVERS.SERVER_FF
        ];
        this.statuses = {
            nj: this.test.PING.IN_PROGRESS.LABEL,
            sj: this.test.PING.IN_PROGRESS.LABEL,
            tk: this.test.PING.IN_PROGRESS.LABEL,
            ff: this.test.PING.IN_PROGRESS.LABEL
        };

        this.beginTest();
    },
    methods: {
        doReset() {
            const toPingList = this.getImageCollection(this.$el);

            for (let i = 0; i < toPingList.length; i++) {
                const toPing = toPingList[i];

                toPing.onload = null;
                toPing.onerror = null;
                toPing.src = '';
            }

            Object.keys(this.statuses).forEach(key => {
                this.statuses[key] = this.test.PING.IN_PROGRESS.LABEL;
            });
        },
        beginTest() {
            this.doReset();
            this.doTest();
        },
        getImageCollection(el) {
            return (el
                 && el.querySelectorAll
                 && el.querySelectorAll('.server-test__ping'))
                 || [];
        },
        doTest() {
            this.getImageCollection(this.$el).forEach((toPing, idx) => {
                const currServer = this.test.SERVERS.COLLECTION[idx];

                toPing.onload = () => {
                    this.statuses[currServer.LABEL] = this.test.PING.SUCCESS.LABEL;
                };

                toPing.onerror = () => {
                    this.statuses[currServer.LABEL] = this.test.PING.FAILURE.LABEL;
                };

                toPing.src = `http://${currServer.IP}/images/spacer.gif`;
            });
        },
        statusIconClass(serverName) {
            const currStatus = this.statuses[serverName];
            const ping = this.test.PING;
            if (currStatus === ping.IN_PROGRESS.LABEL) {
                return ping.IN_PROGRESS.ICON;
            }
            else if (currStatus === ping.SUCCESS.LABEL) {
                return ping.SUCCESS.ICON;
            }
            return ping.FAILURE.ICON;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) "~_acaAssets/wkce/colors/wkce-app-styles";
@DS1-UTD-LOADING: @WKCE-GRAY-SHADE2;

.server-test__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .server-test__container {
    max-width: 720px;
  }

  .server-test__button {
    .ds1-mt-1();
    .ds1-mb-2();
  }

  .server-test__error--text {
    .ds1-utd-weight-bold();
    color: @DS1-UTD-ERROR;
  }

  .wk-table {
    th,
    td {
      .ds1-pa-1();
    }

    th {
      .ds1-utd-weight-bold();
    }
  }

  .wkce-icon-filled-check-circle {
    color: @DS1-UTD-SUCCESS;
  }

  .wkce-icon-filled-stop-sign {
    color: @DS1-UTD-ERROR;
  }

  .wkce-icon-filled-spinner-alt {
    color: @DS1-UTD-LOADING;
  }
}
</style>