<template>
  <div class="print-header">
    <div class="print-header-section-left">
      <template v-if="isRebrandRedesignEnabled">
        <img class="wk-wheel"
             src="/logos/utd-wheel-icon.svg"
             alt="Wolters Kluwer Health">
        <img class="utd-print-logo"
             :src="utdLogoRebrandSrc"
             :alt="utdLogoAlt">
      </template>
      <img v-else
           class="utd-print-logo-old"
           :src="utdLogoSrc"
           :alt="utdLogoAlt">
      <div class="print-header-text">
        <p v-html="$t('HEADER.OFFICIAL_REPRINT')" />
        <a href="https://www.uptodate.com" aria-label="UpToDate home page">www.uptodate.com</a>
        <utd-copyright-provider :full="true" />
      </div>
    </div>
    <div class="print-header-section-right">
      <img v-if="!isRebrandRedesignEnabled"
           src="/footer/wkh-logo200w.png"
           alt="Wolters Kluwer Health">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';
import { utdCcLogoAlternateText } from '_acaSrc/utility/constants';

export default {
    components: {
        UtdCopyrightProvider
    },
    data() {
        return {
            utdCcLogoAlt: utdCcLogoAlternateText
        };
    },
    computed: {
        ...mapGetters('app', [ 'isUccState' ]),
        ...mapGetters('feature', [ 'isRebrandRedesignEnabled' ]),
        utdLogoSrc() {
            return `/app/utd-menu/utd${this.isUccState ? 'cc' : ''}-logo-desktop.svg`;
        },
        utdLogoRebrandSrc() {
            return `/app/utd-menu/utd${this.isUccState ? 'cc' : ''}-logo-redesign.svg`;
        },
        utdLogoAlt() {
            return this.isUccState ? utdCcLogoAlternateText : 'UpToDate';
        }
    }
};
</script>

<style lang="less" scoped>
@import '~_acaAssets/global/variables';
@import '~_acaAssets/wkce/colors/wkce-app-styles';

.print-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: @NOTOSANS_FONT_STACK;
  .ds1-utd-size-1();
  .ds1-pt-1();

  > .print-header-section-left {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    > img {
      .ds1-mr-2();
    }

    > .wk-wheel {
      width: 40px;
      height: auto;
      .ds1-ml-2();
    }

    > .print-header-text {
      .ds1-pb-3();

      > p {
        .ds1-ma-0();
      }
    }
  }

  > .print-header-section-right {
    .ds1-mr-2();
  }

  .copyright {
    display: inline-block;
  }
}

.utd-print-logo-old {
  width: 115px;
  height: auto;
}

.utd-print-logo {
  width: 130px;
  height: auto;
}
</style>

<style lang="less">
@import '~_acaAssets/wkce/colors/wkce-app-styles';

.is-rebrand {
  &.utd-logo-cc {
    .copyright {
      font-size: 9px;
      line-height: 13px;
    }

    .print-header-text {
      font-size: 9px;
      line-height: 13px;
    }
  }

  .print-header-text {
    .ds1-pb-3();
    font-size: 10px;
    line-height: 14px;
    padding-top: 6px;

    > p {
      .ds1-ma-0();
    }
  }

  .copyright {
    font-size: 10px;
    line-height: 14px;
    color: @DS1-UTD-TOPIC-TEXT-COLOR;
  }
}

.utd-logo-cc {
  .utd-print-logo-old {
    width: 225px;
    height: 28px;
  }

  .utd-print-logo {
    width: 210px;
    height: auto;
    margin-top: 4px;
  }
}
</style>