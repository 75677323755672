<template>
  <footer>
    <div id="wk-copyright-tier">
      <utd-copyright-provider v-if="showCopyright && isDesktopView" :full="true" />
      <ul v-if="isDesktopView">
        <li>
          <a id="language"
             href="#"
             @click="showChangeLanguageDialog($event)">Language</a>
        </li>
        <li v-if="isFooterLoaded && isUccState">
          <a id="help"
             v-utd-new-tab="true"
             :href="getFooterUrl('help')"
             v-text="getFooterTitle('help')" />
        </li>
      </ul>
    </div>
    <section v-if="isFooterLoaded" v-show="isAppDataLoaded">
      <div class="tiers tier1">
        <div class="column">
          <ul>
            <li id="language">
              <a href="/settings/change-language">Language</a>
            </li>
            <li v-if="isUccState" id="help">
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('help')"
                 v-text="getFooterTitle('help')" />
            </li>
            <li v-if="showManageCookiesLink">
              <a id="ot-sdk-btn"
                 class="ot-sdk-show-settings">{{ $t('MYACCOUNT.COOKIE_PREFERENCES') }}</a>
            </li>
            <li v-if="!isUccState" id="sinfo_footer">
              <a tabindex="0"
                 role="link"
                 @click="showSupportTag($event)"
                 v-text="getFooterTitle('supportTag')" />
            </li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <li id="sla_footer">
              <a :href="getFooterUrl('sla')"
                 :target="getFooterTarget('sla')"
                 :rel="getFooterRel('sla')"
                 v-text="getFooterTitle('sla')" />
            </li>
            <li data-autotest="policiesFooter">
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('policies')"
                 v-text="getFooterTitle('policies')" />
            </li>
            <li v-if="showResearchGuideLink" @click="launchResearchGuide()">
              <a tabindex="0"
                 role="link">Usability Research</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tiers tier2">
        <div class="column">
          <ul id="fTier2">
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('contactUs')"
                 v-text="getFooterTitle('contactUs')" />
            </li>
            <li v-if="!isUccState">
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('uptodateNews')"
                 v-text="getFooterTitle('uptodateNews')" />
            </li>
            <li v-if="!isUccState">
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('trainingCenter')"
                 v-text="getFooterTitle('trainingCenter')" />
            </li>
            <li v-if="isUccState" id="sinfo_footer">
              <a tabindex="0"
                 role="link"
                 @click="showSupportTag($event)"
                 v-text="getFooterTitle('supportTag')" />
            </li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('aboutUs')"
                 v-text="getFooterTitle('aboutUs')" />
            </li>
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('accessOptions')"
                 v-text="getFooterTitle('accessOptions')" />
            </li>
            <li v-if="!isUccState">
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('demos')"
                 v-text="getFooterTitle('demos')" />
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isTier3Visible" class="tiers tier3">
        <ul>
          <li class="social-circle facebook">
            <a id="sfiFacebook"
               v-utd-new-tab="true"
               class="wkce-icon-filled-facebook"
               href="https://www.facebook.com/UpToDateEBM"
               aria-label="UpToDate Facebook" />
          </li>
          <li class="social-circle twitter">
            <a id="sfiTwitter"
               v-utd-new-tab="true"
               class="wkce-icon-filled-twitter"
               href="https://twitter.com/UpToDate"
               aria-label="UpToDate Twitter" />
          </li>
          <li class="social-circle linkedin">
            <a id="sfiLinkedIn"
               v-utd-new-tab="true"
               class="wkce-icon-filled-linkedin"
               href="https://www.linkedin.com/company/uptodate?trk=hb_tab_compy_id_21924"
               aria-label="UpToDate LinkedIn" />
          </li>
          <li class="social-circle youtube">
            <a id="sfiYoutube"
               v-utd-new-tab="true"
               class="wkce-icon-filled-youtube"
               href="https://www.youtube.com/uptodateebm"
               aria-label="UpToDate YouTube" />
          </li>
        </ul>
      </div>
      <div v-if="isTier4Visible" class="tiers tier4">
        <ul id="fTier3">
          <li>
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/health">Wolters Kluwer Health
            </a>
          </li>
          <li>
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/solutions/uptodate/why-uptodate">
              UpToDate<sup>&reg;</sup>
            </a>
          </li>
          <li>
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/solutions/medi-span">Medi-Span<sup>&reg;</sup>
            </a>
          </li>
        </ul>
      </div>
      <utd-copyright-provider v-if="isNotDesktopView" :full="true" :center="true" />
    </section>
  </footer>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import { safeTimeout } from '_acaSrc/utility/timers';

import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';
import ResearchGuide from '_acaSrc/utility/PendoGuides/ResearchGuide';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS } from '_acaSrc/utility/constants';

export default {
    directives: {
        UtdNewTab
    },
    components: {
        UtdCopyrightProvider
    },
    data() {
        return {
            showResearchGuideLink: false,
            researchGuide: null,
            cancelOrientationChangeTimeout: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'isAppDataLoaded',
            'isUccState'
        ]),
        ...mapGetters('footer', [
            'getFooterTitle',
            'getFooterUrl',
            'getFooterTarget',
            'getFooterRel',
            'isTier3Visible',
            'isTier4Visible',
            'isFooterLoaded'
        ]),
        ...mapGetters('search', [ 'isHomeSearchEmpty' ]),
        ...mapGetters('device', [
            'isDesktopView',
            'isNotDesktopView',
            'showManageCookiesLink',
            'isMobileOnDesktop'
        ]),
        showCopyright() {
            return (this.isDesktopView && !this.isMobileOnDesktop)
                      || this.isHomeSearchEmpty;
        }
    },
    beforeMount() {
        this.setupResearchGuide('research-guides-ready');
    },
    mounted() {
        this.$nextTick(this.setup);
    },
    beforeUnmount() {
        this.cancelOrientationChangeTimeout();
        this.clearOrientationChangeListener(this.onOrientationChange);
    },
    methods: {
        ...mapActions('app', [
            'openSupportTag',
            'broadcast',
            'subscribe',
            'openChangeLanguageDialog'
        ]),
        ...mapActions('footer', [
            'openSupportTag'
        ]),
        setup() {
            this.cancelOrientationChangeTimeout = safeTimeout(() => {
                this.setOrientationChangeListener(this.onOrientationChange);
            }, 1, { cancelHook: true }).cancelTimeout;
        },
        setupResearchGuide(eventName) {
            this.researchGuide = new ResearchGuide(eventName);
            // Initial Pendo Research Guide check.
            this.getResearchGuideLink();

            // Subsequent checks (after login, for example)
            this.subscribe({ eventName, handlerFn: this.getResearchGuideLink });
        },
        onOrientationChange() {
            new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
                reset: true,
                fromLocation: 'wk-utd-footer.directive: orientationchange'
            });
        },
        showSupportTag(event) {
            this.openSupportTag({ event });
        },
        launchResearchGuide() {
            this.researchGuide && this.researchGuide.launchGuide();
        },
        getResearchGuideLink() {
            this.researchGuide.isShowGuide().then(shouldShow => {
                this.showResearchGuideLink = shouldShow;
            });
        },
        showChangeLanguageDialog(event) {
            this.openChangeLanguageDialog(event);
        }
    }
};
</script>

<style scoped lang="less">
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

  /*Styles below include the !important rule
  to override styles applied to the button via the OneTrust script, which also use !important*/
  #ot-sdk-btn.ot-sdk-show-settings {
    color: @WKCE-GRAY !important;

    &:hover {
      color: @WKCE-GRAY !important;
    }
  }
</style>