<template>
  <div :class="utdLogoClass">
    <a class="wk-logo"
       role="img"
       aria-label="UpToDate logo"
       href="/contents/search"
       tabindex="0">
      <img :src="isIconRebrand" class="wk-wheel" alt="Wolters Kluwer"><!--
   --><img :src="isLogoRebrand" class="utd-logo" alt="UpToDate">
    </a>
    <span v-if="isCobrandingEnabled && !searchBarHeader"
          id="cobrandingLogoRes"
          class="cobrandingLogoRes">
      <a :href="cobrandingAnchorHref"
         role="img"
         aria-label="Co-branding logo"
         target="_blank"
         tabindex="0"
         rel="noopener noreferrer">
        <img :src="cobrandingImageUrl" alt="Co-brand Logo">
      </a>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { C_APP } from '_acaSrc/utility/constants';

export default {
    computed: {
        ...mapGetters('app', [
            'isUccState',
            'isCobrandingEnabled',
            'cobrandingImageUrl',
            'cobrandingAnchorHref'
        ]),
        ...mapGetters('search', [ 'searchBarHeader' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('feature', [ 'isRebrandRedesignEnabled' ]),
        utdLogoClass() {
            return this.isUccState
                ? C_APP.LOGO_FILENAMES.UCC
                : '';
        },
        utdLogoSrc() {
            return `/app/utd-menu/utd${this.isUccState ? 'cc' : ''}-logo-desktop.svg`;
        },
        wkWheelIconSrc() {
            return `/app/utd-menu/wk-wheel-icon${this.isDesktopView ? '' : '-mobile'}.png`;
        },
        utdLogoRebrandSrc() {
            return `/app/utd-menu/utd${this.isUccState ? 'cc' : ''}-logo-redesign.svg`;
        },
        wkWheelIconRebrandSrc() {
            return '/app/utd-menu/wk-wheel-icon-redesign.svg';
        },
        isLogoRebrand() {
            return this.isRebrandRedesignEnabled
                ? this.utdLogoRebrandSrc
                : this.utdLogoSrc;
        },
        isIconRebrand() {
            return this.isRebrandRedesignEnabled
                ? this.wkWheelIconRebrandSrc
                : this.wkWheelIconSrc;
        }
    }
};
</script>

<style lang="less">
@import './UtdLogo.less';
</style>
