<template>
  <div v-show="toolbarOverflowVisible"
       id="overflowTopicDropdown"
       class="overflowTopicDropdown">
    <ul>
      <li class="topoverflowTopicDropdown" />
      <li v-show="printLinkVisible"
          id="patientInfo"
          class="mobileOverflow">
        <a id="printGraphic"
           class="wkce-icon-print"
           :href="printUrl">{{ $t("CONTENT.TOOLS_PRINT") }}</a>
      </li>
      <li v-show="shareLinkVisible" id="topicShare" class="mobileOverflow">
        <topic-toolbar-share :share-type="'graphic'" />
      </li>
    </ul>
  </div>
</template>

<script>

import { createPositionManager } from '_acaSrc/utility/DOM';
import topicToolbarShare from '_acaSrc/components/contents/topic/toolbar/TopicToolbarShare.vue';
import { mapGetters, mapMutations } from 'vuex';
import { C_TOPIC } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';
import { SET_OVERFLOW_GRAPHIC_TOOLS } from '_acaSrc/store/graphic.store';

export default {
    components: {
        topicToolbarShare
    },
    computed: {
        ...mapGetters('graphic', [
            'printLinkVisible',
            'shareLinkVisible',
            'overflowGraphicTools',
            'printUrl'
        ]),
        toolbarOverflowVisible() {
            return this.overflowGraphicTools;
        }
    },
    watch: {
        toolbarOverflowVisible(newVal) {
            if (newVal) {
                new PubSub().publish('wkutd.close-toolbar-menus', this.uuid);
                this.$nextTick(() => {
                    this.positionManager.update();
                    this.utdLockScroll();
                });
            }
            else {
                this.utdUnlockScroll();
            }
        }
    },
    mounted() {
        this.toolbarOverflowVisible && this.utdLockScroll();
        this.positionManager = createPositionManager(C_TOPIC.TOOLS_MENU_BTN_SELECTOR, this.$el);
        new PubSub().subscribe('wkutd.close-toolbar-menus', this.closeMenu);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.close-toolbar-menus', this.closeMenu);
        this.toolbarOverflowVisible && this.utdUnlockScroll();
    },
    methods: {
        ...mapMutations('graphic', [
            SET_OVERFLOW_GRAPHIC_TOOLS
        ]),
        closeMenu(exceptMenuUUID) {
            const isNotThisMenu = !this.uuid || !exceptMenuUUID || exceptMenuUUID !== this.uuid;
            if (isNotThisMenu) {
                this[SET_OVERFLOW_GRAPHIC_TOOLS](false);
                this.utdUnlockScroll();
            }
        }
    }
};
</script>

<style lang="less" scoped>
  .mobileOverflow {
    white-space: nowrap;
  }
  .overflowTopicDropdown {
      height: fit-content;
  }
  #overflowTopicDropdown :deep(.wkce-icon-share),
  .overflowTopicDropdown a[class^='wkce-icon-']::before {
    display: none;
  }
</style>