<template>
  <div id="topic-toolbar" class="graphicToolbar">
    <toolbar-back-link :show-back-button="isDesktopView" />
    <div id="topic-tools" class="graphic-tools">
      <ul>
        <li v-show="powerpointLinkVisible">
          <a id="exportPowerpoint"
             class="wkce-icon-file-powerpoint"
             :href="powerpointUrl"
             target="_blank">
            <span v-utd-tooltip-ellipsis>
              {{ $t("CONTENT.EXPORT_TO_POWERPOINT") }}
            </span>
          </a>
        </li>
        <li v-show="printLinkVisible"
            id="patientInfo"
            class="mobileOverflow">
          <a id="printGraphic"
             class="wkce-icon-print"
             :href="printUrl">{{ $t("CONTENT.TOOLS_PRINT") }}</a>
        </li>
        <li v-show="shareLinkVisible" id="topicShare" class="mobileOverflow">
          <topic-toolbar-share :share-type="'graphic'" />
        </li>
        <li v-show="showBookmark"
            id="bookmarkGraphic"
            class="liBookmark"
            @click="graphicLoaded && bufferBookmark();">
          <utd-bookmark id="gfxBookmark"
                        :bookmark-active="toolsBookmarkIsActive"
                        :bookmark-tooltip="tooltip"
                        @tooltip-closed="closeTooltip" />
        </li>
        <utd-toolbar-menu v-show="showRatingMenu"
                          class="toolbar_rateGraphic">
          <template v-slot:menuActivatorLabel>
            <span aria-hidden="true" class="wkce-icon-star" />
            <span class="rateLabel" aria-label="Rate this graphic" v-text="$t('RATE')" />
          </template>
          <utd-star-rating id="graphicStarRating"
                           class="utdStarRating_graphicMobile star-rating-wide"
                           :options="{ showLabel: false }" />
        </utd-toolbar-menu>
        <li v-show="printLinkVisible || shareLinkVisible"
            id="toolsMenu"
            :class="{'overflowOn': tools.overflowGraphicTools}"
            @click="graphicLoaded && toggleOverflowGraphicMenu()">
          <a>{{ $t("CONTENT.TOOLS") }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { SET_OVERFLOW_GRAPHIC_TOOLS, SET_BOOKMARK_IS_ACTIVE } from '_acaSrc/store/graphic.store';
import { C_EVENTS } from '_acaSrc/utility/constants';
import { getWindow } from '_acaSrc/utility/DOM';
import { lockRatingControls } from '_acaSrc/utility/PendoGuides/RatingGuide';
import PubSub from '_acaSrc/utility/PubSub';
import topicToolbarShare from '_acaSrc/components/contents/topic/toolbar/TopicToolbarShare.vue';
import utdBookmark from '_acaSrc/components/shared/utd/UtdBookmark.vue';
import toolbarBackLink from '_acaSrc/components/shared/utd/UtdToolbarBackLink.vue';
import utdTooltipEllipsis from '_acaSrc/directives/UtdTooltipEllipsis.directive';
import UtdToolbarMenu from '_acaSrc/components/shared/stdlib/UtdToolbarMenu.vue';
import UtdStarRating from '_acaSrc/components/shared/stdlib/UtdStarRating.vue';
import {
    BOOKMARK_BUFFER_PAUSE_MS,
    SET_BOOKMARK_BUFFER_DELAY,
    TOGGLE_TOOLTIP_HIDE,
    RESET_TOOLTIP
} from '_acaSrc/store/profile.store';
import { toggleBookmark } from '_acaSrc/utility/BookmarkHelper';

export default {
    directives: {
        utdTooltipEllipsis
    },
    components: {
        topicToolbarShare,
        utdBookmark,
        toolbarBackLink,
        UtdToolbarMenu,
        UtdStarRating
    },
    computed: {
        ...mapGetters('graphic', [
            'powerpointLinkVisible',
            'printLinkVisible',
            'graphicLoaded',
            'shareLinkVisible',
            'overflowGraphicTools',
            'printUrl',
            'powerpointUrl',
            'tools',
            'toolsBookmarkIsActive',
            'toolsBookmarkedGraphics',
            'graphic',
            'graphicId',
            'graphicVersion',
            'graphicTitle',
            'isMultiGraphic' ]),
        ...mapGetters('pendo', [ 'showRatingGuide' ]),
        ...mapGetters('profile', [
            'permissions',
            'tooltip',
            'bookmarkBufferDelayMs'
        ]),
        ...mapGetters('device', [ 'isDesktopView', 'isBrowserTypeDesktop' ]),
        showBookmark() {
            return this.graphic.showBookmarkLink
             && this.permissions.bookmarks;
        },
        showRatingMenu() {
            return this.showRatingGuide
              && !this.isMultiGraphic;
        }
    },
    mounted() {
        this.resizeListener = this.setResizeListener(getWindow(), this.onResizeCloseOverflow);
        this.subscribe({
            eventName: 'wkutd.star-rating',
            handlerFn: this.doRatingGuide
        });
    },
    beforeUnmount() {
        this.clearResizeListener(this.resizeListener);
        this.unsubscribe({
            eventName: 'wkutd.star-rating',
            handlerFn: this.doRatingGuide
        });
    },
    methods: {
        ...mapMutations('graphic', [
            SET_OVERFLOW_GRAPHIC_TOOLS,
            SET_BOOKMARK_IS_ACTIVE ]),
        ...mapMutations('profile', [
            RESET_TOOLTIP,
            SET_BOOKMARK_BUFFER_DELAY,
            TOGGLE_TOOLTIP_HIDE ]),
        ...mapActions('profile', [ 'resetBookmarkDelay' ]),
        ...mapActions('graphic', [
            'bookmarkGraphic',
            'deleteBookmark'
        ]),
        ...mapActions('app', [
            'subscribe',
            'unsubscribe',
            'publish'
        ]),
        ...mapActions('pendo', [ 'launchGuide' ]),
        toggleOverflowGraphicMenu() {
            if (this.overflowGraphicTools) {
                this[SET_OVERFLOW_GRAPHIC_TOOLS](false);
            }
            else {
                // Hide tooltip if visible...
                this[TOGGLE_TOOLTIP_HIDE]();

                this[SET_OVERFLOW_GRAPHIC_TOOLS](true);
            }
            // Reset tooltip after toogling overflow graphic menu
            this[RESET_TOOLTIP]();
        },
        bufferBookmark() {
            if (this.toBookmark) {
                clearTimeout(this.toBookmark);
            }
            this.toBookmark = setTimeout(() => {
                toggleBookmark({
                    tools: this.tools,
                    tooltip: this.tooltip,
                    bookmarks: this.toolsBookmarkedGraphics,
                    permissions: this.permissions,
                    fnAddBookmark: this.bookmarkGraphic,
                    fnRemoveBookmark: this.deleteBookmark,
                    fnCloseTooltip: this.closeTooltip,
                    fnSetBookmarkActive: this[SET_BOOKMARK_IS_ACTIVE]
                });
                // Long pause before resetting delay back to 0
                this.resetBookmarkDelay();
            }, this.bookmarkBufferDelayMs);

            this[SET_BOOKMARK_BUFFER_DELAY](BOOKMARK_BUFFER_PAUSE_MS);
        },
        closeTooltip(tooltipId) {
            if (this.toolsBookmarkedGraphics.length
                >= this.permissions.bookmarkLimit) {
                this[RESET_TOOLTIP]();
            }
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, { uiElementName: tooltipId });
            this[TOGGLE_TOOLTIP_HIDE]();
        },
        onResizeCloseOverflow() {
            if (this.isBrowserTypeDesktop) {
                this[SET_OVERFLOW_GRAPHIC_TOOLS](false);
            }
        },
        doRatingGuide(eventData) {
            if (!(eventData && eventData.id === 'graphicStarRating')) {
                return;
            }

            lockRatingControls();

            this.launchGuide({
                guideName: 'RatingGuide',
                guideData: {
                    graphicId: this.graphicId,
                    graphicTitle: this.graphicTitle,
                    graphicVersion: this.graphicVersion,
                    element: eventData.id,
                    rating: eventData.rating
                }
            });

            // Dismiss rating menu on selecting star, as Pendo will provide feedback
            this.publish({ eventName: 'wkutd.close-toolbar-menus' });
        }
    }
};
</script>
<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#outerGraphicContainer {
  #topic-toolbar #topic-title {
    display: none;
  }
}

.toolbar_rateGraphic {
  .rateLabel {
    cursor: pointer;
  }

  :deep(.toolbar-menu__drawer) {
    .ds1-pa-2();
    background-color: @DS1-UTD-TOOLBAR-BG-COLOR;
  }

  // These rules are unfortunately necessary to override global
  // CSS rules in app.less with overly broad specificity.
  :deep(.star-rating) {
    padding: 0;
    margin: 0;
    text-align: center;
    white-space: nowrap;

    & > .rating-list {
        padding: 0;
    }
  }
}

// css specificity shims
#topic-toolbar #topic-tools .toolbar_rateGraphic :deep(ul.star-rating) {
    margin: 0;

    > li.rating-list {
      padding: 0;
      margin: 0 0 0 8px;

      &:first-child {
        margin-left: 0;
      }
    }
}

.graphicToolbar {
  a[class^='wkce-icon-']::before {
    .ds1-mr-1();
    display: none;
  }

  a {
    vertical-align: initial;
  }

  &#topic-toolbar {
    .graphic-tools {
      > ul {
        > li {
          display: inline-flex;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    &.fixedToolbar {
      #topic-toolbar.graphicToolbar {
        position: fixed;
        top: @WK-HEADER-CONTAINER-HEIGHT;

        #topic-tools {
          top: 0;
        }
      }
    }

    .graphicToolbar#topic-toolbar {
      display: flex;
      justify-content: space-between;

      a[class^='wkce-icon-']::before {
        display: inline;
      }

      .toolbar-back-link {
        height: 40px;
        line-height: 40px;
        margin-left: 20px;
        white-space: nowrap;

        .utdWkHeader & {
          display: inline-block;
        }
      }

      .wkProspect & {
        top: 104px;
      }

      .graphic-tools {
        display: flex;
        min-width: 0;
        max-width: none;
        width: auto;

        .utdWkHeader& #bookmarkGraphic {
          padding-right: 0;
        }

        > ul {
          display: flex;

          > li {
            display: flex;
            align-items: center;
            white-space: nowrap;

            &:first-child {
              min-width: 0;

              > a {
                width: 100%;

                > span {
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
            }

            > a {
              display: flex;
            }

            > a,
            :deep(a),
            :deep(.utd-bookmark .utd-button) {
              .ds1-utd-size-3();
            }
          }
        }
      }
    }

    .toolbar_rateGraphic :deep(.wkce-icon-star) {
      position: relative;
      top: -2px;
    }

    :deep(.star-rating) {
      padding: 0;
      margin: 0;
      text-align: center;
      white-space: nowrap;

      & > .rating-list {
        padding: 0;
        margin-left: 0;

        &:first-child {
          margin-left: 8px;
        }

        &:last-child {
          margin-right: 8px;
        }
      }
    }

    // specificty shims start ratings.
    #topic-toolbar #topic-tools .toolbar_rateGraphic :deep(ul.star-rating) {
      margin: 0;

      > li.rating-list {
        padding: 0;
        margin-left: 0;

        &:first-child {
          margin-left: 8px;
        }

        &:last-child {
          margin-right: 8px;
        }
      }
    }
  }
}

</style>
