<template>
  <div v-if="isPrintView"
       id="printControls"
       class="utd-print"
       :class="printControlClasses">
    <div class="print-controls-title">
      <h5>{{ $t('CONTENT.PRINT_OPTIONS') }}</h5>
    </div>

    <div v-if="hasAlternateLanguages" class="language-select">
      <utd-select v-if="currentLanguage !== null"
                  id="selectLanguage"
                  v-model="currentLanguage"
                  small
                  name="selectLanguage"
                  :use-index="false"
                  class="wk-js2"
                  placeholder="Select a Language"
                  :options="alternateLanguages"
                  value-prop="id"
                  label-prop="name"
                  @utd-select="changeLanguage()" />
    </div>
    <div v-if="isTopic">
      <div v-for="section in visibleSections"
           :key="section.ctrlId"
           class="row">
        <utd-checkbox :id="section.ctrlId"
                      class="value"
                      tabindex="0"
                      :checked="section.showSection"
                      @checkbox-changed="(showSection) => toggleSection(section, {showSection})" />

        <label class="label" :for="section.ctrlId">{{ $t(section.label) }}</label>
      </div>
    </div>
    <utd-button class="print-button"
                tabindex="0"
                button-icon-position="right"
                button-icon="arrow-right"
                @click="print()">
      {{ $t('CONTENT.TOOLS_PRINT') }}
    </utd-button>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getWindow } from '_acaSrc/utility/DOM';
import utdBack from '_acaSrc/directives/UtdBack.directive';
import UtdCheckbox from '_acaSrc/components/shared/stdlib/UtdCheckbox.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdSelect from '_acaSrc/components/shared/input/UtdSelect.vue';

import {
    SET_GRAPHIC_URL_LANGUAGE
} from '_acaSrc/store/graphic.store';
import {
    SET_TOPIC_LANGUAGE
} from '_acaSrc/store/topic/topic.store';

const UI_RENDER_DELAY_MS = 100;
const HIDDEN_CLASS = 'print-section-hidden';
const TEXT_ELEMENT_ID = 'topicText';
export default {
    directives: {
        utdBack
    },
    components: {
        UtdCheckbox,
        UtdSelect,
        UtdButton
    },
    props: {
        alternateLanguages: {
            type: Array,
            default() {
                return [];
            }
        },
        type: String // Can be: graphic, topic
    },
    data() {
        return {
            currentLanguage: null,
            sections: [{
                ctrlId: 'inpText', // #id of checkbox control
                label: 'CONTENT.PRINT_TEXT', // This is the translation string
                domEleId: 'topicText', // #id of section
                showSection: true, // Flag controlling showing/hiding of section
                eleObjId: 'undefined', // Stores actual reference to section object in DOM
                hasSection: false // If true, will render control row
            }, {
                ctrlId: 'inpRefs',
                label: 'CONTENT.PRINT_REFERENCES',
                domEleId: 'references',
                showSection: true,
                eleObjId: 'undefined',
                hasSection: false
            }, {
                ctrlId: 'inpGfxs',
                label: 'CONTENT.PRINT_GRAPHICS',
                domEleId: 'printGraphics',
                showSection: true,
                eleObjId: 'undefined',
                hasSection: false
            }, {
                ctrlId: 'inpDisc',
                label: 'CONTENT.PRINT_DISCLOSURES',
                domEleId: 'printDisclosures',
                showSection: true,
                eleObjId: 'undefined',
                hasSection: false
            }]
        };
    },
    computed: {
        ...mapGetters('app', [
            'router',
            'isProspectMode',
            'isPrintView'
        ]),
        ...mapGetters('topic', [ 'topicLanguage' ]),
        ...mapGetters('graphic', [
            'graphicLanguage'
        ]),
        hasAlternateLanguages() {
            return this.alternateLanguages.length > 1;
        },
        isTopic() {
            return this.type === 'topic';
        },
        isGraphic() {
            return this.type === 'graphic';
        },
        language() {
            if (this.isTopic) {
                return this.topicLanguage;
            }
            return this.graphicLanguage;
        },
        visibleSections() {
            return this.sections.filter(x => x.hasSection);
        },
        isOnlyPrint() {
            if (!this.isTopic) {
                return true;
            }
            return (this.visibleSections.length === 1
                   && this.visibleSections[0].domEleId === TEXT_ELEMENT_ID);
        },
        printControlClasses() {
            const printOnlyClass = this.isOnlyPrint && !this.hasAlternateLanguages
                ? 'is-print-only' : '';
            const hideSectionClass = this.isOnlyPrint && 'hide-sections' || '';
            return [
                this.currentLanguage,
                hideSectionClass,
                printOnlyClass
            ];
        }
    },
    mounted() {
        setTimeout(() => {
            this.activate();
        }, UI_RENDER_DELAY_MS);
    },
    methods: {
        ...mapMutations('topic', [
            SET_TOPIC_LANGUAGE
        ]),
        ...mapMutations('graphic', [
            SET_GRAPHIC_URL_LANGUAGE
        ]),
        ...mapActions('topic', [
            'hideOrShowPathways',
            'showRxTransitionsLinks',
            'logTopicPrintEvent'
        ]),
        print() {
            getWindow().print();
            this.isTopic && this.logTopicPrintEvent({ requestedView: 'print' });
        },
        updatePrintUrlLang() {
            // Adds language to URL path so language is maintained on print page refresh
            // back/forward (we don't show english in url); DE10176 kevin f
            const printPath = this.router.url();
            let printPathLang = '';
            for (const lang in this.alternateLanguages) {
                if (printPath.indexOf(`/${this.alternateLanguages[lang].id}/`) > -1) {
                    if (this.currentLanguage === 'en-US') {
                        printPathLang
                        = printPath.replace(`${this.alternateLanguages[lang].id}/`, '');
                        break;
                    }
                    printPathLang = printPath.replace(`/${this.alternateLanguages[lang].id}/`,
                        `/${this.currentLanguage}/`);
                    break;
                }
            }
            if (printPathLang === '' && this.currentLanguage !== 'en-US') {
                printPathLang = this.updatePrintPathUrlLang(printPath);
            }
            getWindow().location.href = printPathLang;
        },
        updatePrintPathUrlLang(printPath) {
            let printPathLang = '';
            const pageLang = printPath.match(new RegExp('contents/(.*)/image'));
            if (pageLang !== null) {
                printPathLang = printPath.replace(pageLang[1], `${this.currentLanguage}`);
            }
            else {
                printPathLang
                = printPath.replace('contents', `contents/${this.currentLanguage}`);
            }
            return printPathLang;
        },
        changeLanguage() {
            if (this.isTopic) {
                this.changeTopicLang();
            }
            else if (this.isGraphic) {
                this.changeGraphicLang();
            }
        },
        changeTopicLang() {
            this[SET_TOPIC_LANGUAGE](this.currentLanguage);
            this.updatePrintUrlLang();
        },
        changeGraphicLang() {
            this[SET_GRAPHIC_URL_LANGUAGE](this.currentLanguage);
            this.updatePrintUrlLang();
        },
        toggleSection(section, options = { showSection: false }) {
            section.showSection = options.showSection;
            if (!(section.eleObjId && section.eleObjId.classList)) {
                return;
            }
            if (section.showSection) {
                section.eleObjId.classList.remove(HIDDEN_CLASS);
            }
            else {
                section.eleObjId.classList.add(HIDDEN_CLASS);
            }
        },
        activate() {
            // If we have alternate languages, initialize select control's value
            if (this.hasAlternateLanguages) {
                this.alternateLanguages.some(lang => {
                    if (lang.id === this.language) {
                        this.currentLanguage = lang.id;
                        return true;
                    }
                    return false;
                });

                this.alternateLanguages
                    .sort((a, b) => a.name.localeCompare(b.name));
            }

            if (!this.isTopic) {
                return;
            }

            this.sections.forEach(section => {
                // Attempt to get a handle to the section DOM element
                section.eleObjId = document.getElementById(section.domEleId);

                // Indicate whether the section was located
                section.hasSection = !!section.eleObjId;

                // If 'articleOnly' argument passed, hide any other sections on load
                if (section.ctrlId !== 'inpText' && this.router.getParams('articleOnly')) {
                    section.showSection = false;
                    section.eleObjId && section.eleObjId.classList.add(HIDDEN_CLASS);
                }

                // If prospect view, disable graphic print.
                if (section.ctrlId === 'inpGfxs' && this.isProspectMode) {
                    section.eleObjId && section.eleObjId.classList.add(HIDDEN_CLASS);
                    section.hasSection = false;
                    section.showSection = false;
                }
            });

            this.hideOrShowPathways();
            this.showRxTransitionsLinks();
        },
        isBackButtonDisabled() {
            return getWindow().history.length < 2;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.topicPrint {
  * {
    box-sizing: border-box;
  }

  .print-controls-title {
    .ds1-ph-3();
    padding: 14px 12px 8px 20px;
    background-color: @DS1-UTD-PRINT-OPTIONS-BG-COLOR;
    border-top: @DS1-UTD-PRIMARY-CARD-BORDER;
    border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;

    h5 {
      .ds1-utd-h5();
      .ds1-ma-0();
    }
  }
}

.utd-print {
  display: block;
  position: fixed;
  top: 64px;
  max-width: 168px;
  right: 24px;
  width: 100%;
  padding-bottom: 42px;
  border: @DS1-UTD-GRAY-SINGLE-BORDER;
  border-bottom-width: 0;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  box-shadow: @DS1-BOX-SHADOW-PRINT-TOOLS;

  .is-rebrand & {
    top: 16px;
  }

  &.ar {
    right: auto;
    left: 24px;
  }

  .print-button {
    .ds1-ph-2();
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 42px;
    font-size: 14px;
    text-align: right;
    font-weight: bold;
  }

  .row {
    .ds1-pt-compact();
    display: flex;
    align-items: center;
    margin: 6px 0;
    padding-left: 20px;
    padding-right: 20px;

    .label {
      max-width: 92px;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    &:first-child {
      margin-top: 14px;
    }

    &:last-child {
      .ds1-mb-2();
    }
  }

  .language-select {
    padding: 16px 20px 0;

    .wk-field-select {
      height: 32px;
    }

    .wk-field-select-container::after {
      top: 2px;
    }
  }

  &.is-print-only {
    max-width: 98px;
    box-shadow: none;
    border-width: 0;
    padding: 0;

    .print-controls-title,
    .row {
      display: none;
    }

    .print-button {
      position: static;
      text-align: center;
    }
  }

  &.hide-sections {
    padding-bottom: 60px;

    .row {
      display: none;
    }
  }
}
</style>