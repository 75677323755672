/* eslint-disable max-len */
export const C_APP = Object.freeze({
    CLIENT_URLS: [
        /\/(contents|settings|landing|legal|cme)\/.*/,
        /\/content-not-avaliable-icg/,
        /\/login$/,
        /\/account\/reset-password/,
        /\/account\/change-password/,
        /\/account\/consolidate-accounts/,
        /\/account\/error/,
        /\/guid-error/,
        /\/validate-code/,
        /\/server-test/,
        /\/cme/,
        /\/view-error/,
        /\/content-not-available/,
        /\/page-not-found/,
        /\/myuptodatemobile/,
        /^\/pathway\/\d+\/contributor.*/,
        /^\/pathway\/\d+\/formulary-info.*/,
        /^\/rxtransitions\/contributor.*/
    ],
    /* eslint-disable no-useless-escape */
    DESTINATION_URLS: [
        /^\/contents\/.*?([^\/]+)$/,
        /^\/contents\/search\?/,
        /^\/drug-interactions\/\?/,
        /^\/contents\/grade\/([^\/]+)$/,
        /^\/account\/cme\/([^\/]+)$/,
        /^\/account\/cme\/moc\/([^\/]+)$/,
        /^\/pathway\/\d+\/contributor.*/,
        /^\/rxtransitions\/contributor.*/,
        /\/idp\/auth/
    ],
    /* eslint-enable no-useless-escape */
    META_DESCRIPTION: 'UpToDate, electronic clinical resource tool for physicians and patients that provides information on Adult Primary Care and Internal Medicine, Allergy and Immunology, Cardiovascular Medicine, Emergency Medicine, Endocrinology and Diabetes, Family Medicine, Gastroenterology and Hepatology, Hematology, Infectious Diseases, Nephrology and Hypertension, Neurology, Obstetrics, Gynecology, and Women\'s Health, Oncology, Pediatrics, Pulmonary, Critical Care, Sleep Medicine, Rheumatology, Surgery, and more.',
    PRODUCT_NAMES: {
        UTD: 'UpToDate<sup>&reg;</sup>',
        UCC: 'UpToDate \u4E34\u5E8A\u987E\u95EE'
    },
    COUNTRY_CODES: {
        UNITED_STATES: 'US',
        CHINA: 'CN'
    },
    LOGO_FILENAMES: {
        UTD: 'utd-logo',
        UCC: 'utd-logo-cc'
    },
    LOG_NAME: 'UTDWEBCLIENT',
    Z_INDEX: {
        MAX_ZINDEX: 999999999
    }
});

export const C_HTTP = Object.freeze({
    REST_BASE_PATH: '/services/app/',
    DEFAULT_HOMEPAGE_PATH: '/contents/search',
    DEFAULT_LOGIN: '/login',
    DEFAULT_TIMEOUT: 35000,
    WARNING_AFTER: 25000
});

export const C_HTTP_STATUS = Object.freeze({
    OK: 200,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503
});

export const C_REST_URL = Object.freeze({
    GRAPHIC_IMAGE_REST_PREFIX_URL: `${C_HTTP.REST_BASE_PATH}contents/graphic/view/`
});

export const C_I18N = Object.freeze({
    UTD_NAMESPACE: 'utd'
});

export const C_EXPIRE_MESSAGE = Object.freeze({
    EXPIRED: 'EXPIRED',
    EXPIRING: 'EXPIRING',
    EXPIRES_TODAY: 'EXPIRES_TODAY'
});

export const C_KEYCODES = Object.freeze({
    ESCAPE_KEYCODE: 27,
    SPACE_KEY: 32,
    ENTER_KEY: 13,
    LEFT_ARROW: 37,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    UP_ARROW: 38,
    HOME_KEYCODE: 36,
    END_KEYCODE: 35,
    TAB_KEY: 9
});

export const C_UI = Object.freeze({
    MENU_HIDE_DELAY_MS: 500,
    MENU_SHOW_DELAY_MS: 500,
    RESIZE_DELAY_MS: 150,
    RESIZE_DELAY_FAST_MS: 10,
    SCROLLING_CHECK_DELAY_LONG_MS: 250,
    SCROLLING_CHECK_DELAY_FAST_MS: 10,
    SAFARI_SCROLL_RESET_DELAY_MS: 100,
    DEFAULT_SPINNER_DELAY_MS: 2000,
    WINDOW_RESIZE_DELAY_MS: 50,
    MODAL_CLOSING_DELAY_MS: 500,
    DESKTOP_OVERFLOW_DELAY_MS: 25,
    VIEWPORT_GUTTER_PX: 8,
    CLICK_EVENT_DELAY_MS: 2000,
    FIXED_TOOLBAR_POSITION_PX: 40,
    HISTORY_ITEMS_PER_PAGE: 20,
    SMALL_MOBILE_BREAKPOINT: 450,
    MOBILE_BREAKPOINT: 768,
    RX_TRANSITIONS_MENU_BREAKPOINT: 950,
    DELAYED_REFRESH_TIMEOUT: 3000,
    AUTOCOMPLETE_DROPDOWN_DELAY: 350
});

export const C_BROWSER = Object.freeze({
    TYPE_SMALL_SCREEN: 'isSmallScreen',
    TYPE_TABLET: 'isTablet',
    TYPE_DESKTOP: 'isDesktop',
    OS_APPLE_MOBILE: 'ios',
    OS_APPLE_DESKTOP: 'macos',
    NAME_MICROSOFT_IE: 'InternetExplorer',
    NAME_MICROSOFT_EDGE: 'Edge',
    NAME_APPLE_SAFARI: 'Safari',
    NAME_CHROME_FOR_IOS: 'ChromeforiOS',
    NAME_FIREFOX_FOR_MOBILE: 'FirefoxforMobile',
    NAME_ANDROID_CHROME: 'AndroidChrome',
    NAME_MOBILE_SAFARI: 'MobileSafari',
    VENDOR_APPLE: 'Apple'
});

export const C_RESPONSIVE = Object.freeze({
    IS_NOT_DESKTOP: '',
    MOBILE_VIEW: 'mobile',
    DESKTOP_VIEW: 'desktop',
    MIN_DESKTOP_THRESHOLD_PX: 768,
    MAX_TABLET_THRESHOLD_PX: 1024
});

export const C_DOMAINS = Object.freeze({
    OPTIMIZELY_CDN: 'https://rollouts-cdn.uptodate.com',
    QUALTRICS_CDN: 'https://research.uptodate.com'
});

export const C_PATHS = Object.freeze({
    TOC_PREFIX: 'table-of-contents/',
    FOOTER_REST_EP: 'config/footer/json'
});

export const C_CME = Object.freeze({
    HISTORY_ITEMS_PER_PAGE: 20
});

export const C_CME_UI_LINKS = Object.freeze({
    dashboard: '/cme/dashboard',
    redeem: '/cme/redeem',
    history: '/cme/history',
    settings: '/cme/settings',
    help: '/cme/help',
    accreditation: '/home/cmececpd-accreditations',
    error: '/cme/error',
    confirmation: '/cme/confirmation',
    register: '/cme/register',
    redeemEvaluate: '/cme/redeem/evaluate'
});

export const C_MOC_UI_LINKS = Object.freeze({
    dashboard: '/cme/moc/dashboard',
    submit: '/cme/moc/submit',
    status: '/cme/moc/status',
    settings: '/cme/settings/moc',
    help: 'https://uptodate-communities.force.com/customers/s/article/What-is-my-MOC-CC-board-ID-for-CME-e-submissions?language=en_US',
    deadlineHelp: 'https://wkhealthce.my.site.com/customers/s/article/When-is-the-deadline-for-submitting-CME-credits-to-the-boards-participating-in-direct-credit-reporting?language=en_US'
});

export const C_MOC = Object.freeze({
    HEADER_COLUMNS: [
        'Credits',
        'Search Term',
        'Topic(s) Reviewed',
        'Search Goal',
        'Application',
        'Searched',
        'CME Redeemed'
    ],
    MOC_TYPE_STATE_CME: 'stateCme',
    MOC_TYPE_MOC: 'moc',
    MOC_SETTINGS_KEY_STATE_CME: 'stateCme',
    MOC_SETTINGS_KEY_MOC: 'moc',
    MOC_AUTHORITY_TYPE_STATE_CME: 'STATE'
});

export const C_STATE_CME_UI_LINKS = Object.freeze({
    dashboard: '/cme/state/dashboard',
    submit: '/cme/state/submit',
    status: '/cme/state/status',
    settings: '/cme/settings/state',
    help: 'https://uptodate-communities.force.com/customers/s/article/What-is-my-state-medical-board-ID-for-CME-e-submissions?language=en_US'
});

export const C_TOPIC = Object.freeze({
    VIEW_TOPIC_KEY: 'CONTENT.VIEW_TOPIC',
    VIEW_OUTLINE_KEY: 'CONTENT.VIEW_OUTLINE',
    ANCHOR_HASH_PREFIX: 'xhash_',
    SCROLL_TARGET_SUFFIX: '_scrollTarget',
    SCROLL_TOP_POSITION: -100,
    TOOLBAR_SCROLL_OFFSET_PX: 16,
    TOPIC_CONTENT_STABLE_DELAY_MS: 500,
    OPTIMIZELY_TIMEOUT_MS: 1000,
    OPTIMIZELY_TIMEDOUT_LOG_PERCENT_CHANCE: 5,
    OPTIMIZELY_TIMEDOUT_EVENT_NAME: 'optimizely_timedout',
    FIT_HEIGHT: 98,
    TOOLS_MENU_BTN_SELECTOR: '#toolsMenu',
    CALCULATOR_SCRIPT_TEMPLATE: calcJs => `//@ sourceURL=calculatorScript\n${calcJs}`,
    CALCULATOR_INIT_JAVASCRIPT: '//@ sourceURL=initCalc\nif (typeof initCalc == \'function\'){ initCalc(); }\nif (typeof initDropdowns == \'function\'){ initDropdowns(); }',
    TOPIC_TAB_HASH: 'topicContent',
    GRAPHIC_TAB_HASH: 'topicGraphics',
    TOPIC_TAB_INDEX: 0,
    GRAPHIC_TAB_INDEX: 1,
    SOURCE: {
        TOPIC_TABS: 'topic_tabs',
        GRAPHICS_GALLERY: 'graphics_gallery'
    },
    TYPES: {
        DRUG: 'drug',
        MEDICAL: 'medical'
    },
    SUBTYPES: {
        GENERAL_MEDICAL: 'medical_review',
        BASICS: 'medical_basics',
        DXY_DRUG: 'drug_dxy',
        DXY_PACKAGE_INSERT: 'drug_dxy_package_insert',
        LAB_INTERPRETATION: 'narrative_labi',
        DRUG_GENERAL: 'drug_general',
        DRUG_PEDIATRIC: 'drug_pediatric',
        DRUG_INTERNATIONAL: 'drug_international'
    },
    TOPIC_NOT_AVAILABLE: 'Topic Not Available'
});

export const C_SEARCH = Object.freeze({
    SUGGESTION_TYPE: {
        MATCHED: 'MATCHED',
        REPLACEMENT: 'REPLACEMENT',
        RELAUNCH: 'RELAUNCH',
        SEMANTIC: 'SEMANTIC',
        MISSPELL: 'MISSPELL',
        AUTOCORRECT: 'AUTOCORRECT'
    },
    SEARCH_TYPES: {
        PLAIN_TEXT: 'PLAIN_TEXT',
        GRAPHICS: 'GRAPHICS'
    },
    SOURCE: {
        USER_INPUT: 'USER_INPUT',
        HL7: 'HL7',
        USER_PREF: 'USER_PREF',
        PAGING: 'PAGING',
        COLLAPSE_VIEW: 'COLLAPSE_VIEW',
        GRAPHICS_PANEL: 'Graphic_Panel',
        GRAPHICAL_ANSWERS: 'Graphic_Answer'
    },
    CONTROL: {
        TOP_PULLDOWN: 'TOP_PULLDOWN'
    },
    PRIORITY: {
        ALL_TOPICS: '0',
        ADULT: '1',
        PEDIATRIC: '2',
        PATIENT: '3',
        GRAPHICS: '4'
    },
    TYPE_TITLES: {
        ICG: 'UpToDate Pathways: ',
        NARRATIVE: 'Lab Interpretation: '
    },
    WARNING_TEXT: {
        BRAND_NAMES_DIFFER_BY_COUNTRY: 'Brand name matches different drugs depending on country'
    },
    INVALID_TAB_IDX: -1,
    RETRIGGER_TAB_WATCH_IDX: -2
});

export const C_MYUTD_TABS = Object.freeze({
    HISTORY: 0,
    MOST_VIEWED: 1,
    BOOKMARKS: 2
});

export const UCC_FQDN_PATH = 'http://www.uptodate.cn/home';

export const C_HEADER = Object.freeze({
    URLS: {
        WHY_UPTODATE: '/home/why-uptodate',
        PRODUCT: '/home/product',
        EDITORIAL: '/home/editorial',
        SUBOPTS: '/home/subscription-options',
        PRIVACY_POLICY: '/home/privacy-policy',
        CN_PRIVACY_POLICY: 'http://www.uptodate.cn/home/uptodate-china-privacy-policy'
    },
    UCC_URLS: {
        WHY_UPTODATE: `${UCC_FQDN_PATH}/customer-testimonials`,
        PRODUCT: `${UCC_FQDN_PATH}/product`,
        EDITORIAL: `${UCC_FQDN_PATH}/editorial`,
        SUBOPTS: `${UCC_FQDN_PATH}/subscribe-options`
    }
});

export const BCDR_MESSAGE = Object.freeze({
    DEFAULT: 'This feature is currently unavailable due to scheduled maintenance. Please try again later.',
    APOLOGY: 'We apologize for the inconvenience and appreciate your patience. Thank you for using UpToDate.'
});

const SIDEBAR_TOGGLE_GITT_TEXT = 'Graphics in this topic';
const SIDEBAR_TOGGLE_OG_TEXT = 'Other graphics';
const SIDEBAR_TOGGLE_AIT_TEXT = 'Appears in topics';
export const C_GRAPHICS = Object.freeze({
    MULTI_GRAPHIC_SEPARATOR: '~',
    VIEWER_DEFAULT_SIDEBAR_TITLE: SIDEBAR_TOGGLE_GITT_TEXT,
    SIDEBAR_TOGGLE_GITT_TEXT,
    SIDEBAR_TOGGLE_OG_TEXT,
    SIDEBAR_TOGGLE_AIT_TEXT,
    SIDEBAR_ACCORDION_TITLE: 'Related Content',
    SIDEBAR_ACCORDION_AIT: Object.freeze({
        label: SIDEBAR_TOGGLE_AIT_TEXT,
        id: 'appearsInTopics'
    }),
    SIDEBAR_ACCORDION_GITT: Object.freeze({
        label: SIDEBAR_TOGGLE_GITT_TEXT,
        id: 'graphicsInTopic'
    }),
    SIDEBAR_ACCORDION_OG: Object.freeze({
        label: SIDEBAR_TOGGLE_OG_TEXT,
        id: 'otherGraphics'
    }),
    TYPE: {
        MOVIE: 'MOVIE',
        EMMI: 'emmi'
    },
    SUB_TYPE: {
        MOVIE: 'graphic_movie'
    },
    SECTION_TITLE: {
        ALGORITHM: 'Algorithms',
        DIAGNOSTICIMAGE: 'Diagnostic Images',
        FIGURE: 'Figures',
        FORM: 'Forms',
        PICTURE: 'Pictures',
        MOVIE: 'Movies',
        TABLE: 'Tables',
        WAVEFORM: 'Waveforms',
        GRAPHICALANSWER: 'Graphical Answers',
        OTHERGRAPHICS: 'Other Graphics'
    }
});

export const C_QAP = Object.freeze({
    UI: {
        QAP_TYPE: 'QAP',
        DEFAULT_MOBILE_HEIGHT: 300,
        LABI_MOBILE_HEIGHT: 500,
        MOBILE_HEIGHT_CHECK_DELAY_MS: 10
    },
    PANEL: {
        DRUG_INFO: 'drug_info',
        DRUG_INFO_PANEL: 'drug_info_panel',
        DRUG_INT: 'drug_international',
        DRUG_FORMULATION: 'drug_formulary',
        DRUG_DXY: 'drug_dxy',
        DRUG_FOREIGN: 'drug_foreign',
        DRUG_ROUTED: 'drug_routed',
        PCU: 'medical_pcu',
        LABI: 'labi',
        PATHWAYS: 'pathways',
        RX_TRANSITIONS: 'rx_transitions'
    },
    SECTION: {
        ACCORDION: 'accordion',
        ALERT: 'alert',
        LINKS: 'links',
        DRUG_INTERACTIONS: 'dip',
        RX_TRANSITIONS: 'rx_transitions'
    },
    TYPES: {
        DRUG: 'drug',
        ROUTED: 'DRUG_LANDING_PAGE',
        PATHWAY: 'ICG',
        LABI: 'narrative'
    },
    SUBTYPES: {
        LABI: 'narrative_labi',
        PCU: 'medical_pcu',
        INTERNATIONAL: 'drug_international'
    }
});

export const C_SEARCH_RESULTS = Object.freeze({
    RESULT_TYPE: {
        BROAD_QUERY_PANEL: 'BQP',
        KEY_POINTS_PANEL: 'KPP',
        GRAPHICS_PANEL: 'GP',
        GRAPHICAL_ANSWERS: 'GA',
        GRAPHIC: 'graphic',
        ICG: 'ICG',
        DRUG: 'drug',
        VISUALDX: 'visualdx',
        NARRATIVE_LABI: 'narrative_labi',
        RX_TRANSITIONS: 'rx_transitions',
        DIP: 'dip',
        BERT: 'BERT'
    },
    RESULT_SUBHIT_TYPE: {
        ICG: 'icg'
    }
});

export const C_PATHWAYS = Object.freeze({
    toc: 'table-of-contents/pathways',
    pathwayDisabledTooltipHTML: '<div class="pathway-unavailable-tooltip">This UpToDate Pathway has been disabled by your organization.</div>',
    pathwayNotEnabledTooltipHTML: '<div class="pathway-unavailable-tooltip">This Pathway is not included in your UpToDate Subscription.</div>',
    pathwayCustomizedTooltipHTML: '<div class="pathway-customized-tooltip">This Pathway has been edited by your organization and may not be consistent with UpToDate\'s recommendations.</div>'
});

export const C_TOC = Object.freeze({
    CHECK_HIDE_EXPAND_COLLAPSE_DELAY: 500,
    PATHWAYS_NAME: 'pathways',
    PATHWAYS_TOC_FILTER_NAME: 'pathways_pathwaymain',
    PATHWAYS_TOC_FILTER_SEARCH_SETTINGS_MAX: 10
});

export const FORMULARY_TYPES = Object.freeze({
    PATHWAYS: 'pathways',
    TOPIC: 'topic'
});

export const C_PAGE_TYPE = Object.freeze({
    DRUG_LANDING_PAGE: 'DRUG_LANDING_PAGE',
    FORMULATION_PAGE: 'FORMULATION_PAGE'
});

/**
 * Constants for My Account.
 */
export const C_ACCOUNT = Object.freeze({
    ROUTE_URLS: {
        CONSOLIDATE_ACCOUNTS: '/account/consolidate-accounts',
        RESET_PASSWORD: '/account/reset-password?status',
        CHANGE_PASSWORD: '/account/change-password',
        ERROR: '/account/error',
        LOGIN_REGISTER: '/login-register'
    },
    SIDEBAR: [
        { title: 'My Account',
            links: [
                { name: 'Contact Information', link: '/account/contact' },
                { name: 'Subscription', link: '/account/subscription2' },
                { name: 'User Name and Password', link: '/account/change-login' },
                { name: 'Account Access Points', link: '/account/access-points' },
                { name: 'Merge Accounts', link: '/account/consolidate-accounts', id: 'consolidate', refresh: true },
                { name: 'Download Center', link: '/account/download' },
                { name: 'Manage My Devices', link: '/account/manage-devices' }
            ] }
    ],
    FORM_MESSAGING: {
        INVAlid_LOGIN: 'INVALID_LOGIN',
        MAXIMUM_ATTEMPTS: 'MAXIMUM_ATTEMPTS',
        GENERIC_ERROR: 'GENERIC_ERROR',
        ERROR_TITLE: 'Incorrect Sign-in',
        USERNAME_ERROR: 'Username is required',
        PASSWORD_ERROR: 'Password is required',
        SAME_USERNAME_ERROR: 'You entered credentials for the account you are currently signed into. Please enter sign-in credentials for a different account.',
        SAME_USERNAME_ERROR_TITLE: 'Accounts must be different',
        MERGE_ERROR_TITLE: 'Merge Failed',
        FIND_ACCOUNT_ERROR_TITLE: 'Find Account Failed',
        FIND_ACCOUNT_DEFAULT_MESSAGE: 'Error occurred. Please check input data',
        MERGE_DEFAULT_TITLE: 'Cannot Merge',
        MERGE_DEFAULT_MESSAGE: 'These accounts cannot be merged.',
        CANNOT_MERGE_CONTACT: ' Please contact customer service for assistance at 1-800-998-6374 or &nbsp;+1-781-392-2000. You can also email us at <a href="mailto:customerservice@uptodate.com">customerservice@uptodate.com</a>',
        CANNOT_MERGE_UNMATCHING_LASTNAMES: 'We\'re sorry, accounts with different last names cannot be merged.',
        CANNOT_MERGE_PATIENT_ACCOUNTS: 'We\'re sorry, patient accounts cannot be merged.',
        CANNOT_MERGE_CLINICAL_CONSULTANT: 'We\'re sorry, these accounts cannot be merged.',
        CANNOT_MERGE_DIFFERENT_BIZ_UNITS: 'We\'re sorry, accounts from different business units cannot be merged.',
        MERGE_SOURCE_TARGET_SAME: '<ul class="merge-notification-list"><li>The source and the target are the same account.</li><li>The account "To Be Deactivated" and the account "Remaining" are the same.</li><li>Please enter sign-in information for a different account.</li></ul>',
        MERGE_CME_MESSAGE: 'Current Credit Total',
        MERGE_SUCCESS_TITLE: 'Success',
        MERGE_SUCCESS_MESSAGE: '<ul class="merge-notification-list"><li>You have transferred your credits, if any, and deactivated your account.</li><li>Your credits may take up to 24 hours to update.</li><li>You may also need to sign out and sign back in to your account.</li></ul>',
        MERGE_FAILURE_MESSAGE: 'We\'re sorry, unable to merge the accounts online.',
        MERGE_FAILURE_TYPE: {
            INVALID_LOGIN: 'global.login.error',
            MAXIMUM_ATTEMPTS: 'login.error.96.message'
        },
        MERGE_NOT_ALLOWED: 'Account information can be changed when you purchase a full individual subscription. <a href="/store?SHORTCUT=true">Click here</a> to subscribe to UpToDate.',
        MERGE_NOT_ALLOWED_FOR_GUESTS: 'We\'re sorry, guest accounts cannot be merged.',
        ERROR_TITLE_LOGIN_REQUIRED: 'Sign-in Required',
        ERROR_MESSAGE_MY_ACCOUNT_LOGIN: 'To access My Account, please verify your identity by signing in with your UpToDate password.',
        WAYF_GENERIC_ERROR_ID: 'global.login.error.wayf',
        EMAIL_NOTIFICATION_TITLE: 'Entry of a different email than your enterprise email',
        EMAIL_NOTIFICATION_BODY: emailAddress => `<ul class="email-notification-content"><li>It is <b>ok to use a different email</b> for your UpToDate account.</li><li>When signing in to <b>UpToDate with Single Sign-On (SSO)</b>, please use your enterprise email, <b id="emailDisclaimerMessage" title="${emailAddress}">${emailAddress}</b></li></ul>`,
        FIELDS_NEED_INFO_TITLE: 'Fields need information',
        FIELDS_NEED_INFO_BODY: '<ul class="fields-notification-content"><li>Field(s) on this form require a selection or value.</li><li>Please scroll to view fields needing information.</li></ul>',
        PRACTICE_SETTING_EMPTY_VALUE: '0'
    },
    MESSAGE_TYPE: {
        SUCCESS: 'success',
        ERROR: 'error',
        INFO: 'info'
    }
});

export const C_LANGUAGES_NAMES = Object.freeze({
    IN: { NAME: 'BAHASA_INDONESIAN', CODE: 'in' },
    ZH_HANS: { NAME: 'SIMPLIFIED_CHINESE', CODE: 'zh-Hans' },
    ZH_HANT: { NAME: 'TRADITIONAL_CHINESE', CODE: 'zh-Hant' },
    DE: { NAME: 'GERMAN', CODE: 'de' },
    EN: { NAME: 'ENGLISH', CODE: 'en', COUNTRY_CODE: 'en-US' },
    ES: { NAME: 'SPANISH', CODE: 'es' },
    FR: { NAME: 'FRENCH', CODE: 'fr' },
    IT: { NAME: 'ITALIAN', CODE: 'it' },
    JA: { NAME: 'JAPANESE', CODE: 'ja' },
    KO: { NAME: 'KOREAN', CODE: 'ko' },
    NL: { NAME: 'DUTCH', CODE: 'nl' },
    PL: { NAME: 'POLISH', CODE: 'pl' },
    PT: { NAME: 'PORTUGESE', CODE: 'pt' },
    RU: { NAME: 'RUSSIAN', CODE: 'ru' },
    TR: { NAME: 'TURKISH', CODE: 'tr' },
    VI: { NAME: 'VIETNAMESE', CODE: 'vi' },
    AR: { NAME: 'ARABIC', CODE: 'ar' }
});

export const C_LANGUAGES_LIST = Object.freeze([
    { name: C_LANGUAGES_NAMES.IN.NAME, value: C_LANGUAGES_NAMES.IN.CODE },
    { name: C_LANGUAGES_NAMES.ZH_HANS.NAME, value: C_LANGUAGES_NAMES.ZH_HANS.CODE },
    { name: C_LANGUAGES_NAMES.ZH_HANT.NAME, value: C_LANGUAGES_NAMES.ZH_HANT.CODE },
    { name: C_LANGUAGES_NAMES.DE.NAME, value: C_LANGUAGES_NAMES.DE.CODE, marketing: true },
    { name: C_LANGUAGES_NAMES.EN.NAME, value: C_LANGUAGES_NAMES.EN.CODE, marketing: true },
    { name: C_LANGUAGES_NAMES.ES.NAME, value: C_LANGUAGES_NAMES.ES.CODE, marketing: true },
    { name: C_LANGUAGES_NAMES.FR.NAME, value: C_LANGUAGES_NAMES.FR.CODE },
    { name: C_LANGUAGES_NAMES.IT.NAME, value: C_LANGUAGES_NAMES.IT.CODE },
    { name: C_LANGUAGES_NAMES.JA.NAME, value: C_LANGUAGES_NAMES.JA.CODE, marketing: true },
    { name: C_LANGUAGES_NAMES.KO.NAME, value: C_LANGUAGES_NAMES.KO.CODE },
    { name: C_LANGUAGES_NAMES.NL.NAME, value: C_LANGUAGES_NAMES.NL.CODE },
    { name: C_LANGUAGES_NAMES.PL.NAME, value: C_LANGUAGES_NAMES.PL.CODE },
    { name: C_LANGUAGES_NAMES.PT.NAME, value: C_LANGUAGES_NAMES.PT.CODE, marketing: true },
    { name: C_LANGUAGES_NAMES.RU.NAME, value: C_LANGUAGES_NAMES.RU.CODE },
    { name: C_LANGUAGES_NAMES.TR.NAME, value: C_LANGUAGES_NAMES.TR.CODE },
    { name: C_LANGUAGES_NAMES.VI.NAME, value: C_LANGUAGES_NAMES.VI.CODE }
]);

export const C_SMGR_ERRORS = Object.freeze({
    invalidGuid: 'ERROR.SYSTEM_ERROR',
    systemError: 'ERROR.SYSTEM_ERROR',
    expiredGuid: 'MYACCOUNT.ACCOUNT_GUID_EXPIRED',
    consumedGuid: 'MYACCOUNT.ACCOUNT_GUID_CONSUMED',
    accountExpired: 'MYACCOUNT.ACCOUNT_EXPIRED',
    disabledUser: 'MYACCOUNT.ACCOUNT_DISABLED'
});

const uccUrl = 'http://www.uptodate.cn/home/';
export const C_FOOTER_CORE = Object.freeze({
    help: {
        url: '/home/help',
        title: 'Help',
        uccUrl: `${uccUrl}help`
    },
    aboutUs: {
        url: '/home/about-us',
        title: 'About Us',
        uccUrl: `${uccUrl}about-us`
    },
    contactUs: {
        url: '/home/contact-us',
        title: 'Contact Us',
        uccUrl: `${uccUrl}contact-us`
    },
    sla: {
        url: '',
        title: 'Terms of Use'
    },
    policies: {
        url: '',
        title: 'Policies',
        uccUrl: `${uccUrl}policies`
    },
    supportTag: {
        url: '',
        title: 'Support Tag'
    },
    uptodateNews: {
        url: '',
        title: 'UpToDate News'
    },
    accessOptions: {
        url: '',
        title: 'Access Options',
        uccUrl: `${uccUrl}mobile-apps-and-mobile-web`
    },
    trainingCenter: {
        url: '',
        title: 'Training Center'
    },
    demos: {
        url: '',
        title: 'Demos'
    },
    editorialPolicy: {
        url: '/home/editorial-policy',
        uccUrl: `${uccUrl}policies`
    },
    testimonials: {
        url: '/home/customer-testimonials',
        uccUrl: `${uccUrl}customer-testimonials`
    },
    woltersKluwer: {
        url: 'http://wolterskluwer.com/'
    },
    careers: {
        url: 'http://careers.wolterskluwer.com/'
    },
    helpTraining: {
        url: '/home/help',
        uccUrl: `${uccUrl}help`
    },
    citingOurContent: {
        url: '/home/linking-policy',
        uccUrl: `${uccUrl}citing-an-uptodate-topic`
    },
    whatsNew: {
        url: '/contents/table-of-contents/whats-new'
    },
    clinicalPodcasts: {
        url: '/home/clinical-podcasts'
    },
    pressAnnouncements: {
        url: '/home/uptodate-press-releases',
        uccUrl: `${uccUrl}press-release`
    },
    intheNews: {
        url: '/home/uptodate-news',
        uccUrl: `${uccUrl}uptodate-in-media`
    },
    events: {
        url: '/home/events'
    },
    uptodateLogin: {
        url: '/login'
    },
    cme: {
        url: '/home/earning-cme-ce-cpd-credit-uptodate'
    },
    mobileApps: {
        url: '/home/how-access-uptodate',
        uccUrl: `${uccUrl}mobile-apps-and-mobile-web`
    },
    webinars: {
        url: '/home/uptodate-lfw'
    },
    EHRIntegration: {
        url: '/home/ehr-interface'
    },
    healthIndustryPodcasts: {
        url: '/home/uptodate-talk-podcasts'
    },
    signUp: {
        url: 'https://go.wolterskluwer.com/utd_ind_cms_contact_info.html',
        uccUrl: 'https://uptodatechina.secure.force.com/IndividualSubscribers?SourceCode=MarketingSiteSignUp'
    },
    privacyPolicy: {
        url: '/home/privacy-policy',
        uccUrl: `${uccUrl}uptodate-china-privacy-policy`
    },
    trademarks: {
        url: '/home/trademark'
    },
    subscriptionLicense: {
        url: 'https://www.wolterskluwer.com/en/know/clinical-effectiveness-terms',
        target: '_blank',
        rel: 'noopener noreferrer',
        uccUrl: '/legal/china/license',
        uccTarget: '',
        uccRel: ''
    }
});

/**
 * Event names
 */
export const C_EVENTS = Object.freeze({
    TRACK_CLICK_EVENT: 'wkutd.trackClickEvent',
    TRACK_UI_CLICK_EVENT: 'wkutd.trackUiClickEvent',
    OPEN_MODAL: 'wkutd.open-myuptodate-modal',
    START_POSITION_FOOTER: 'wkutd.startPositionFooter',
    OPEN_SUPPORT_TAG_MODAL: 'wkutd.open-support-tag-modal',
    POSITION_FOOTER: 'wkutd.position-footer',
    HOVER_TOOLTIP_CLICK: 'wkutd.hover-tooltip-click',
    SET_CREDITS_TO_REDEEM: 'wkutd.set-credits-to-redeem',
    OPEN_CME_DETAILS_MODAL: 'wkutd.open-cme-details-modal',
    OPEN_CONTACT_INFO_MODAL: 'wkutd.open-contact-info-modal',
    MOC_BOARD_REMOVED: 'MOC_BOARD_REMOVED',
    TRANSLATE_FEATURED_SEARCH_RESULT: 'wkutd.translate-featured-result',
    MOC_USER_INITIALISED: 'wkutd.moc-user-initialised',
    OPEN_MYUPTODATE_MODAL: 'wkutd.open-myuptodate-modal',
    ASSIGNED_OPTIMIZELY_FEATURES: 'wkutd.assigned-optimizely-features',
    APP_DATA_LOADED: 'wkutd.data-loaded',
    SLA_MESSAGE_EVENT: 'wkutd.sla-message-event',
    NEXT_STEPS_MERGE_CHECK: 'wkutd.checkNextStepsMergeStatus',
    REFRESH_TOC: 'wkutd.refreshToc',
    CLOSE_TRANSLATION_WARNING: 'wkutd.close-translation-warning-modal',
    OPEN_TRANSLATION_WARNING: 'wkutd.open-translation-warning-modal',
    OPEN_TRANSLATION_FEEDBACK_INSTRUCTION: 'wkutd.open-translation-feedback-instruction-modal',
    REMOUNT_TOPIC_VIEW: 'wkutd.remount-topic-view',
    OPEN_LEARN_MORE: 'wkutd.open-learn-more-modal'
});

export const C_CSRF = Object.freeze({
    COOKIES_TOKEN: 'UTD-X-CSRF-TOKEN',
    HEADER_TOKEN: 'ANTI-CSRF-UTD-TOKEN'
});

export const C_ACTIVE_TRACK_TYPES = Object.freeze({
    SEARCH: 'SEARCH',
    TIME: 'TIME'
});

export const C_MONTH_NAMES = Object.freeze(
    [ 'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December' ]
);

export const C_TABS = Object.freeze({
    WIDTH_BOUNDS_RANGE_PX: 8,
    INITIAL_TAB_RECHECK_DELAY_MS: 200,
    RESIZE_RECHECK_THROTTLEBOUNCE_DELAY_MS: 200
});

export const C_MY_ACCOUNT = Object.freeze({
    MAX_DEVICES_ALLOWED: 2,
    MAX_DEVICES_ALLOWED_UCC: 1,
    MIN_USERNAME_LENGTH: 6,
    MAX_USERNAME_LENGTH: 50,
    UPDATE_LOGIN_SUCCESS: 'SUCCESS',
    UPDATE_LOGIN_PENDING: 'PENDING',
    OLD_PASSWORD_INVALID_ERROR: 'OLD_PASSWORD_INVALID_ERROR',
    NEW_USERNAME_ERROR: 'NEW_USERNAME_ERROR',
    USER_NAME_IN_USE_ERROR: 'USER_NAME_IN_USE_ERROR',
    NEW_PASSWORD_ERROR: 'NEW_PASSWORD_ERROR',
    NEW_PASSWORD_USER_NAME_ERROR: 'NEW_PASSWORD_USER_NAME_ERROR',
    DESKTOP_DEVICE: 'desktop',
    MOBILE_DEVICE: 'mobile',
    ACCESS_LEVELS: {
        UNREGISTERED: 'UNREGISTERED',
        NOT_LOGGED_IN: 'NOT_LOGGED_IN',
        GUEST_ACCESS: 'GUEST_ACCESS',
        NO_MY_ACCOUNT_FEATURE: 'NO_MY_ACCOUNT_FEATURE',
        INCOMPLETE_MY_ACCOUNT_FEATURE: 'INCOMPLETE_MY_ACCOUNT_FEATURE',
        FULL_ACCESS: 'FULL_ACCESS'
    },
    PHONE_TYPES: [ 'work', 'home', 'cell' ],
    SIDEBAR_REQUEST: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        SHOWN: 'SHOWN'
    },
    MY_ACCOUNT_QUERY_PARAM: 'myAccount',
    MISSING_PARAMETER: 'MISSING_PARAMETER',
    CONTACT_INFO_LABEL: 'Link to contact information dialog'
});

export const C_DROPDOWN_TYPES = Object.freeze({
    HEADER: 'header',
    DEFAULT: 'default',
    LINK: 'link'
});

export const C_LOGIN_WORKFLOW = Object.freeze({
    PROMPT_USER_FOR_PASSWORD: 1,
    REDIRECT_TO_SSO: 2,
    SHOW_NEXT_STEPS_FOR_SIGN_IN: 3
});

export const C_LOCALIZATION = Object.freeze({
    LOCAL_LANGUAGE_ID: 0,
    ENGLISH_ID: 1,
    ENGLISH_CODE: C_LANGUAGES_NAMES.EN.COUNTRY_CODE,
    LANGUAGE_PARAM_POSITION: 2,
    SOURCE: 'machinetranslate',
    PP_SOURCE: {
        KPP: 'KPP',
        BQP: 'BQP',
        TOPIC: 'TOPIC'
    }
});

export const C_TABLE_SIZE_CLASS = {
    default: '',
    small: 'wk-table-small',
    extraSmall: 'wk-table-extra-small'
};

export const C_KEY_POINTS = Object.freeze({
    CONTRIBUTORS_TEXT: 'Key Points Panels are created and maintained by our Deputy Editors in conjunction with the authors and section editors of the underlying topics. They provide select key points from the underlying topics, and the underlying evidence is explicitly available in the linked topics.'
});

/**
 * @type {string} sso selection cookies name
 * Remember sso institution selection and put value to cookies.
 */
export const SSO_INSTITUTION_SELECTION = 'SSO_INSTITUTION_SELECTION';

/**
 * This regular expression outlines our rules for a syntactically correct email address.
 * Please check with Business Systems Team and consider other downstream parts of the company before changing.
 *The first part of the regex accepts only letters, numbers, underscores, hyphens, plus-signs, periods in the username.
 *The second part allows for an unlimited number of subdomains. Only letters, numbers, and hyphens are accepted.
 *The third part ensures that the top-level domain has between 2 and 24 characters, inclusive.
 * Only letters, numbers, and hyphens are accepted in the top-level domain.
 */
// eslint-disable-next-line no-useless-escape
export const emailRegex = /^([\w_\-\.\+]+)@(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z0-9\-]{2,24}$/;
/* eslint-disable max-len */
export const xssInjectionPattern = /<\/?[^>]*(img|script|href|on[^>\s]+|src|iframe|input|background|dynsrc|lowsrc|style|size|meta|url)[^>]*>/ig;

export const numericRegex = /^[0-9]*$/;

export const isOidcRegex = /.*OI\(.*/;

/**
 * The regular expressions below outline our rules for the special characters that a password can and cannot have.
 * Only the following special characters are allowed:
 *       . - _ @ # $ * ! ( ) + =
 */
// eslint-disable-next-line no-useless-escape
export const passwordSpecialCharactersRegex = /[.\-_@#\$*!()+=]/;

export const validUserNamePwdCharactersRegex = /^[A-Za-z0-9.\-_@#$*!()+=]*$/;

export const validDomesticPhoneRegex = /^1?\s*-?\s*(\d{3}|\(\s*\d{3}\s*\))\s*-?\.?\/?\s*\d{3}\s*-?\.?\/?\s*\d{4}$/;

export const validInternationalPhoneRegex = /^(\d|-|\+| |\.|){6,15}$/;

export const pathwaysUrlRegex = /^\/?pathway\/\d+$/i;
export const rxTransitionsUrlRegex = /^\/?rxtransitions$/i;
export const drugInteractionsUrlRegex = /^\/?drug-interactions\/?$/i;

export const numberWithSideSpacesPattern = '^\\s*\\d+\\s*';

export const dateTimeDayRegex = /^\d\d?$/;
export const dateTimeMonthRegex = /^\d\d?$/;
export const dateTimeYearRegex = /^(\d){4}$/;
export const validMailingAddressRegex = /^[-.#',/ A-Za-z0-9]*$/;
export const validZipCodeRegex = /^[- A-Za-z0-9]*$/;
export const validPhoneNumberRegex = /^[-+() 0-9]+$/;

export const externalImages = Object.freeze({
    UTD_LOGO: '/images/UTD3_masthead.png'
});

export const utdCcLogoAlternateText = 'UpToDate\u4E34\u5E8A\u987E\u95EE';
export const invisibleCharacter = '\ufeff';

export const millisecondsToDaysMultiplier = 1 / 1000 / 60 / 60 / 24;

export const maxEmailLength = 50;
export const maxSearchInputLength = 70;
export const maxContactFieldLength = 30;
export const maxPasswordFieldLength = 24;

export const boardAuthorityCodeMe = 'ME';

export const dateOfBirthMinDate = new Date(1900, 0, 1);

export const MONTH_NAMES = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul',
    'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];