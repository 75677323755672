<template>
  <div v-if="isVisible"
       ref="viewInLanguage"
       class="view-in-language ds1-pa-compact ds1-utd-size-1 wk-js2">
    <utd-select :options="options"
                label="View in"
                select-class="view-in-language-selector"
                placeholder="Language"
                is-inline
                small
                :use-index="false"
                value-prop="url"
                label-prop="displayName"
                :value="lang"
                @input="handleOnInput($event)" />
  </div>
</template>

<script>
import UtdSelect from '_acaSrc/components/shared/input/UtdSelect.vue';
import { mapGetters, mapActions } from 'vuex';
import { getSetSearchUrlWithParams } from '_acaSrc/utility/contents/search/search';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import { Listener } from '_acaSrc/utility/Events';
import { debounce } from '_acaSrc/utility/timers';
import PubSub from '_acaSrc/utility/PubSub';

export const DEBOUNCE_MS = 30;
export const onScrollDebounce = debounce(() => {
    const activeElement = getDocument().activeElement;
    if (activeElement && activeElement.classList
        && activeElement.classList.contains('view-in-language-selector')) {

        activeElement.blur();
    }
}, DEBOUNCE_MS);

export default {
    components: {
        UtdSelect
    },
    props: {
        topicInfo: {
            type: Object
        }
    },
    data() {
        return {
            lang: null
        };
    },
    computed: {
        ...mapGetters('app', [ 'isPrintView' ]),
        ...mapGetters('topic', [ 'topicHasError', 'isTopicView' ]),
        isVisible() {
            return this.topicInfo
                && this.topicInfo.translatedTopicInfos
                && this.topicInfo.translatedTopicInfos.length > 0
                && !this.isPrintView
                && !this.topicHasError;
        },
        options() {
            return this.topicInfo.translatedTopicInfos
                .map(info => {
                    info.url = getSetSearchUrlWithParams(info, this.topicInfo, { setSource: true });
                    return info;
                });
        }
    },
    watch: {
        lang(newval) {
            if (newval) {
                getWindow().location.href = newval;
            }
        }
    },
    mounted() {
        this.injectSelectorOnTopicPage();
        this.windowScrollListener = new Listener(getWindow(),
            'scroll', onScrollDebounce, { passive: true });
        this.setListener(this.windowScrollListener);
        new PubSub().subscribe('wkutd.relink-outline-events', this.injectSelectorOnTopicPage);
    },
    beforeUnmount() {
        this.clearListener(this.windowScrollListener);
        new PubSub().unsubscribe('wkutd.relink-outline-events', this.injectSelectorOnTopicPage);
    },
    methods: {
        ...mapActions('app', [
            'setListener',
            'clearListener'
        ]),
        handleOnInput(event) {
            this.lang = event;
        },
        injectSelectorOnTopicPage() {
            this.$nextTick(() => {
                if (!this.isTopicView) {
                    return;
                }

                const viewParentEle = getDocument().querySelector('.view');
                if (!viewParentEle || !this.$refs.viewInLanguage) {
                    return;
                }
                viewParentEle.appendChild(this.$refs.viewInLanguage);
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import "~_acaAssets/utd-rtl-styles.less";

.view-in-language-label {
  display: inline-block;
  justify-content: space-between;
}

.view-in-language {
  display: none;
  text-align: left;
  color: @DS1-UTD-SUB-TEXT-COLOR;

  .topicView & {
    .ds1-mb-2;
    .ds1-pl-0();
    .ds1-utd-size-2();
    .utd-not-rtl();
    margin-right: -27px;
    display: inline-block;
  }

  .search-result & {
    position: absolute;
    top: 10px;
    right: -10px;
  }
}

.view-in-language-selector {
  position: relative;
  width: 87px;
  background-color: transparent;

  .topicView & {
    width: 132px;
  }
}

.wkce-icon-chevron-up,
.wkce-icon-chevron-down {
  pointer-events: none;
  position: relative;
  right: 24px;

  .topicView & {
    right: 30px;
  }
}

select::-ms-expand {
  display: none; /* remove default arrow on ie10 and ie11 */
}

@media only screen and (min-width: 768px) {
  .view-in-language {
    display: inline-block;

    .isDesktop .search-result & {
      .ds1-pa-0();
      margin-left: 10px;
      position: static;
    }
  }
}

@media only screen and (min-width: 1140px) {
  .view-in-language {
    .topicView & {
      .ds1-mb-0;
    }
  }
}

</style>
