<template>
  <div class="utd-customer-support">
    <div class="customer-service-explanation">
      <h4 class="ds1-utd-weight-normal"> For further assistance,
        contact UpToDate Customer Service in the following ways:</h4>
      <p>
        <span class="ds1-utd-weight-bold">Email:</span>
        <a v-if="isUccState" href="mailto:ChinaSupport@uptodate.com">
          ChinaSupport@uptodate.com
        </a>
        <a v-else href="mailto:customerservice@uptodate.com">
          customerservice@uptodate.com
        </a>
      </p>
    </div>

    <div class="sign-in-explanation">
      <div v-if="isUccState">
        <p>
          Or Monday through Friday, 9:00-17:30 at:
        </p>
        <p>
          <span class="ds1-utd-weight-bold">Phone:</span>
          <a href="tel://400-886-7266"> 400-886-7266</a>
        </p>
        <p>
          <span class="ds1-utd-weight-bold">Phone:</span>
          <a href="tel://028-8752-6133">(028)8752-6133</a>
        </p>
      </div>

      <div v-else>
        <p>
          Or Monday through Friday, 7 AM to 9 PM ET at:<br>
        </p>
        <p>
          <span class="ds1-utd-weight-bold">Phone:</span>
          <a href="tel://1-800-998-6374"> 1-800-998-6374</a> (U.S. and Canada)
        </p>
        <p>
          <span class="ds1-utd-weight-bold">Phone:</span>
          <a href="tel://+1-781-392-2000">+1-781-392-2000</a> (All countries)
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'UtdCustomerSupport',
    emits: [ 'closed' ],
    computed: {
        ...mapGetters('app', [ 'isUccState' ])
    },
    methods: {
        open() {
            this.$refs.modalDialogRef.open();
        },
        close() {
            this.$emit('closed');
            this.$refs.modalDialogRef.close();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-customer-support {
  p {
    .ds1-mt-0();
  }
}
</style>
