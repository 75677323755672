import {
    setQueryParamValue,
    getQueryParamValues,
    getLanguageFromImageUrl
} from '_acaSrc/utility/http';
import {
    makeThumbnailUrl,
    idFromImageKey,
    idFromTopicKey,
    getPrintAndPowerPointUrls
} from '_acaSrc/utility/contents/graphic/graphic';
import IndexManager from '_acaSrc/utility/IndexManager';
import { RESET_ERROR } from '_acaSrc/store/error.store';
import { usingModifierKeys } from '_acaSrc/utility/Events';
import { C_GRAPHICS, C_EVENTS } from '_acaSrc/utility/constants';
import { makeRestUrl } from '_acaSrc/utility/http/UtdUrlUtilities';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { findLast, findLastIndex } from '_acaSrc/utility/Array';
import PubSub from '_acaSrc/utility/PubSub';
import Logger from '_acaSrc/utility/Logger';
import UtdCache from '_acaSrc/utility/UtdCache';
import UtdQueuedCache from '_acaSrc/utility/UtdQueuedCache';
import ServerMessaging from '_acaSrc/utility/ServerMessaging';

const { SIDEBAR_ACCORDION_GITT, SIDEBAR_ACCORDION_AIT } = C_GRAPHICS;
const iFramedGraphicModalTypes = [ 'emmi' ];
const RESOLVE_BOOKMARK_RETRY_MS = 100;

export const getInitialState = () => {
    return {
        graphicUrl: {
            language: 'en'
        },
        graphicParams: {
            id: [],
            topicKey: '',
            sectionRank: '',
            search: '',
            rank: '',
            index: '',
            section: '',
            display_rank: '',
            usage_type: '',
            source: '',
            rawSentence: ''
        },
        graphic: {
            displayName: '',
            id: '',
            type: '',
            subtype: '',
            title: '',
            imageHtml: '',
            normalGraphicId: '',
            error: false,
            error403: false,
            imageUrl: '',
            imageKey: '',
            relatedTopics: [],
            relatedGraphics: [],
            language: '',
            alternateLanguages: [],
            showBookmarkLink: true,
            showGraphicFeedback: false
        },
        graphicLoaded: false,
        tools: {
            canPrintContent: true,
            printUrl: '',
            powerPointUrl: '',
            isPrintView: false,
            currentLanguage: '',
            currentGraphic: null,
            graphicReady: false,
            overflowGraphicTools: false,
            bookmarkedGraphics: [],
            bookmarkIsActive: false
        },
        isGraphicView: false,
        isPrintGraphic: false
    };
};

export const getDefaultAccordionItems = () => {
    return [ SIDEBAR_ACCORDION_AIT, SIDEBAR_ACCORDION_GITT ];
};

const state = {
    ...getInitialState(),
    bindGfxHoverTooltip: false,
    graphicLoaded: false,
    toolbar: {
        overflow: {
            visible: false
        }
    },
    allGraphics: {
        graphicInfo: []
    },
    error: {
        isHasError: false,
        message: ''
    },
    graphicViewer: {
        lastVisible: 0,
        visible: false,
        imageKey: '',
        imageUrl: '',
        language: '',
        imageIndex: -1,
        imageLoading: false,
        graphics: [],
        sidebarTitle: 'Graphics in this topic',
        collection: [],
        graphicsImageKeyMap: {},
        multigraphicsEnabled: false,
        accordionItems: getDefaultAccordionItems()
    },
    createPowerpoint: false
};

export const FEEDBACK_URL_PREFIX = '/feedback/letter?utdPopup=true&destination=editorial&imageKey=';
export const FEEDBACK_URL_REFERER = '&referer=';

export const SET_GRAPHIC_VIEWER_VISIBILITY = 'SET_GRAPHIC_VIEWER_VISIBILITY';
export const RESET_GRAPHIC_VIEWER_LAST_VISIBLE = 'RESET_GRAPHIC_VIEWER_LAST_VISIBLE';
export const BIND_GFX_HOVER_TOOLTIP = 'BIND_GFX_HOVER_TOOLTIP';
export const SET_GRAPHIC_VIEWER_IMAGE_KEY = 'SET_GRAPHIC_VIEWER_IMAGE_KEY';
export const SET_GRAPHIC_VIEWER_LANGUAGE = 'SET_GRAPHIC_VIEWER_LANGUAGE';
export const SET_GRAPHIC_VIEWER_IMAGE_URL = 'SET_GRAPHIC_VIEWER_IMAGE_URL';
export const SET_GRAPHIC_VIEWER_GRAPHICS = 'SET_GRAPHIC_VIEWER_GRAPHICS';
export const INCREMENT_GRAPHIC_VIEWER_GRAPHIC = 'INCREMENT_GRAPHIC_VIEWER_GRAPHIC';
export const DECREMENT_GRAPHIC_VIEWER_GRAPHIC = 'DECREMENT_GRAPHIC_VIEWER_GRAPHIC';
export const RESET_GRAPHIC_VIEWER_COLLECTION = 'RESET_GRAPHIC_VIEWER_COLLECTION';
export const SET_GRAPHIC_VIEWER_COLLECTION = 'SET_GRAPHIC_VIEWER_COLLECTION';
export const SET_GRAPHIC_VIEWER_IMAGE_INDEX = 'SET_GRAPHIC_VIEWER_IMAGE_INDEX';
export const SET_GRAPHIC_VIEWER_IMAGE_LOADING = 'SET_GRAPHIC_VIEWER_IMAGE_LOADING';
export const SET_GRAPHIC_VIEWER_COLLECTION_TITLES = 'SET_GRAPHIC_VIEWER_COLLECTION_TITLES';
export const SET_GRAPHIC_VIEWER_ACCORDION_ITEMS = 'SET_GRAPHIC_VIEWER_ACCORDION_ITEMS';
export const SET_GRAPHIC_LOADED = 'SET_GRAPHIC_LOADED';
export const SET_GRAPHIC_TOOLBAR_OVERFLOW_VISIBLE = 'SET_GRAPHIC_TOOLBAR_OVERFLOW_VISIBLE';
export const SET_GRAPHIC_RELATED_TOPICS = 'SET_GRAPHIC_RELATED_TOPICS';
export const SET_GRAPHIC_RELATED_GRAPHICS = 'SET_GRAPHIC_RELATED_GRAPHICS';
export const SET_MULTIGRAPHICS_MODE = 'SET_MULTIGRAPHICS_MODE';
export const SET_ALL_GRAPHICS = 'SET_ALL_GRAPHICS';
export const SET_GRAPHIC_LANGUAGE = 'SET_GRAPHIC_LANGUAGE';
export const SET_GRAPHIC_TITLE = 'SET_GRAPHIC_TITLE';
export const SET_GRAPHIC_ID = 'SET_GRAPHIC_ID';
export const SET_GRAPHIC_IMAGE_KEY = 'SET_GRAPHIC_IMAGE_KEY';
export const SET_GRAPHIC_IMAGE_URL = 'SET_GRAPHIC_IMAGE_URL';
export const SET_GRAPHIC_DISPLAY_NAME = 'SET_GRAPHIC_DISPLAY_NAME';
export const SET_GRAPHIC_IMAGE_HTML = 'SET_GRAPHIC_IMAGE_HTML';
export const SET_NORMAL_GRAPHIC_ID = 'SET_NORMAL_GRAPHIC_ID';
export const SET_GRAPHIC_ALTERNATE_LANGUAGES = 'SET_GRAPHIC_ALTERNATE_LANGUAGES';
export const SET_GRAPHIC_VERSION = 'SET_GRAPHIC_VERSION';
export const SET_GRAPHIC_TYPE = 'SET_GRAPHIC_TYPE';
export const SET_GRAPHIC_ERROR_403 = 'SET_GRAPHIC_ERROR_403';
export const SET_GRAPHIC_ERROR = 'SET_GRAPHIC_ERROR';
export const SET_GRAPHIC_SUBTYPE = 'SET_GRAPHIC_SUBTYPE';
export const SET_TOOLS_CURRENT_GRAPHIC = 'SET_TOOLS_CURRENT_GRAPHIC';
export const SET_TOOLS_CAN_PRINT_CONTENT = 'SET_TOOLS_CAN_PRINT_CONTENT';
export const SET_TOOLS_PRINT_URL = 'SET_TOOLS_PRINT_URL';
export const SET_TOOLS_POWER_POINT_URL = 'SET_TOOLS_POWER_POINT_URL';
export const SET_TOOLS_IS_PRINT_VIEW = 'SET_TOOLS_IS_PRINT_VIEW';
export const SET_TOOLS_GRAPHIC_READY = 'SET_TOOLS_GRAPHIC_READY';
export const SET_ERROR_IS_HAS_ERROR = 'SET_ERROR_IS_HAS_ERROR';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const RESET = 'RESET';
export const SET_GRAPHIC_PARAMS = 'SET_GRAPHIC_PARAMS';
export const SET_GRAPHIC_URL_LANGUAGE = 'SET_GRAPHIC_URL_LANGUAGE';
export const SET_OVERFLOW_GRAPHIC_TOOLS = 'SET_OVERFLOW_GRAPHIC_TOOLS';
export const ADD_BOOKMARKED_GRAPHIC = 'ADD_BOOKMARKED_GRAPHIC';
export const REMOVE_BOOKMARKED_GRAPHIC_AT_INDEX = 'REMOVE_BOOKMARKED_GRAPHIC_AT_INDEX';
export const SET_BOOKMARK_IS_ACTIVE = 'SET_BOOKMARK_IS_ACTIVE';
export const SET_BOOKMARKED_GRAPHICS = 'SET_BOOKMARKED_GRAPHICS';
export const SET_SHOW_GRAPHIC_FEEDBACK = 'SET_SHOW_GRAPHIC_FEEDBACK';
export const SET_IS_GRAPHIC_VIEW = 'SET_IS_GRAPHIC_VIEW';
export const SET_IS_PRINT_GRAPHIC = 'SET_IS_PRINT_GRAPHIC';
export const SET_CREATE_POWERPOINT = 'SET_CREATE_POWERPOINT';

export const getters = {
    isMultiGraphic: (state, getters) => {
        return getters.graphicImageKey.match(C_GRAPHICS.MULTI_GRAPHIC_SEPARATOR);
    },
    isGraphicTypeNormal: state => {
        return !!state.graphic.normalGraphicId;
    },
    graphicModalHtml: state => {
        return state.graphic
            ? state.graphic.imageHtml
            : '';
    },
    graphicRelatedTopics: state => {
        return state.graphic
            ? state.graphic.relatedTopics
            : [];
    },
    getGraphicFeedbackUrl: state => {
        return (_window = window) => {
            const imageKey = state.graphic
                ? state.graphic.imageKey
                : 'unknown';
            return `${FEEDBACK_URL_PREFIX}${imageKey}${FEEDBACK_URL_REFERER}${
                encodeURIComponent(_window.location.href)}`;
        };
    },
    powerpointLinkVisible: (state, getters) => {
        return getters.createPowerpoint
            && (state.graphic.language === 'en'
            || state.graphic.language === 'en-US'
            || state.graphic.language === 'zh-Hans'
            || state.graphic.language === 'zh-Hant')
            && state.graphic.subtype !== 'graphic_movie';
    },
    printLinkVisible: (state, getters, rootState, rootGetters) => {
        return state.tools.canPrintContent
            && !state.tools.isPrintView
            && rootGetters['feature/showPrintLink'];
    },
    shareLinkVisible: (state, getters, rootState, rootGetters) => {
        return rootGetters['feature/isHasContentShare'];
    },
    feedbackLinkVisible: (state, getters, rootState, rootGetters) => {
        return !rootGetters['app/isProspectView']
            && !rootGetters['app/isChinaProspectView']
            && state.graphic.showGraphicFeedback !== false;
    },
    bookmarkVisible: (state, getters, rootState) => {
        return state.graphic.showBookmarkLink
            && rootState.profile.myUpToDate.permissions.bookmarks;
    },
    isBindGfxHoverTooltip: state => state.bindGfxHoverTooltip,
    graphicViewerNavVisible: (state, getters, rootState) => {
        return state.graphicViewer.imageUrl
            && state.graphicViewer.graphics.length > 1
            && (rootState.topic.isTopicView
                || state.multigraphicsEnabled);
    },
    graphicViewerIsOrWasLastVisibleMsAgo: state => {
        return (timeSinceVisibleMs = 0) => {
            return state.graphicViewer.visible
                || (state.graphicViewer.lastVisible > 0
                    && Date.now() - state.graphicViewer.lastVisible <= timeSinceVisibleMs);
        };
    },
    showGraphicFeedback: state => state.graphic.showGraphicFeedback,
    graphicViewerLanguage: state => state.graphicViewer.language,
    graphicViewerVisible: state => state.graphicViewer.visible,
    graphicViewerImageKey: state => state.graphicViewer.imageKey,
    graphicViewerImageIndex: state => state.graphicViewer.imageIndex,
    graphicViewerImageUrl: state => state.graphicViewer.imageUrl,
    graphicViewerImageLoading: state => state.graphicViewer.imageLoading,
    graphicViewerGraphics: state => state.graphicViewer.graphics,
    graphicViewerSidebarTitle: state => state.graphicViewer.sidebarTitle,
    graphicViewerCollection: state => state.graphicViewer.collection,
    graphicViewerAccordionItems: state => state.graphicViewer.accordionItems,
    graphicUrlLanguage: state => state.graphicUrl.language,
    graphicParamsView: state => state.graphicParams.view,
    graphicParams: state => state.graphicParams,
    graphicLoaded: state => state.graphicLoaded,
    getGraphicByImageKey: state => imageKey => state.graphicViewer.graphicsImageKeyMap[imageKey],
    getAllGraphics: state => state.allGraphics,
    graphicLanguage: state => state.graphic.language,
    graphicTitle: state => state.graphic.title,
    graphicId: state => state.graphic.id,
    graphicImageKey: state => state.graphic.imageKey,
    graphicImageUrl: state => state.graphic.imageUrl,
    graphicVersion: state => state.graphic.version,
    graphicError403: state => state.graphic.error403,
    graphicType: state => state.graphic.type,
    graphicRelatedGraphics: state => state.graphic.relatedGraphics,
    graphic: state => state.graphic,
    errorMessage: state => state.error.message,
    tools: state => state.tools,
    toolsIsPrintView: state => state.tools.isPrintView,
    toolsBookmarkedGraphics: state => state.tools.bookmarkedGraphics,
    toolsBookmarkIsActive: state => state.tools.bookmarkIsActive,
    graphicSubtype: state => state.graphic.subtype,
    overflowGraphicTools: state => state.tools.overflowGraphicTools,
    printUrl: state => {
        return state.graphicLoaded
            ? state.tools.printUrl
            : '';
    },
    powerpointUrl: state => {
        return state.graphicLoaded
            ? state.tools.powerPointUrl
            : '';
    },
    isGraphicView: state => state.isGraphicView,
    isPrintGraphic: state => state.isPrintGraphic,
    createPowerpoint: state => state.createPowerpoint
};

export const mutations = {
    [BIND_GFX_HOVER_TOOLTIP](state, visible) {
        state.bindGfxHoverTooltip = visible;
    },
    [SET_GRAPHIC_VIEWER_VISIBILITY](state, visible) {
        state.graphicViewer.visible = visible;

        if (!visible) {
            state.graphicViewer.lastVisible = Date.now();
            state.graphicViewer.imageIndex = -1;
            return;
        }

        state.graphicViewer.lastVisible = 0;
    },
    [RESET_GRAPHIC_VIEWER_LAST_VISIBLE](state) {
        state.graphicViewer.lastVisible = 0;
    },
    [SET_GRAPHIC_VIEWER_COLLECTION](state, payload) {
        state.graphicViewer.sidebarTitle = payload.sidebarTitle;
        state.graphicViewer.collection = payload.collection;
    },
    [SET_GRAPHIC_VIEWER_ACCORDION_ITEMS](state, accordionItems) {
        state.graphicViewer['accordionItems'] = accordionItems;
    },
    [RESET_GRAPHIC_VIEWER_COLLECTION](state) {
        state.graphicViewer.sidebarTitle = C_GRAPHICS.VIEWER_DEFAULT_SIDEBAR_TITLE;
        state.graphicViewer.collection = [];
    },
    [SET_GRAPHIC_VIEWER_IMAGE_KEY](state, key) {
        state.graphicViewer.imageKey = key;
    },
    [SET_GRAPHIC_VIEWER_LANGUAGE](state, language) {
        state.graphicViewer.language = language;
    },
    [SET_GRAPHIC_VIEWER_IMAGE_LOADING](state, isLoading) {
        state.graphicViewer.imageLoading = isLoading;
    },
    [SET_GRAPHIC_VIEWER_COLLECTION_TITLES](state, data) {
        const newGraphics = state.graphicViewer.collection.map(graphic => {
            if (graphic.title) {
                return { ...graphic };
            }
            data.graphics.some(dataGraphic => {
                if (idFromImageKey(graphic.imageKey) === dataGraphic.graphicInfo.is) {
                    graphic.title = dataGraphic.graphicInfo.displayName;
                    return true;
                }
                return false;
            });
            return { ...graphic };
        });
        state.graphicViewer.collection = newGraphics;
    },
    [SET_GRAPHIC_VIEWER_IMAGE_URL](state, url) {
        url = setQueryParamValue(url, 'imageKey', state.graphicViewer.imageKey);
        state.graphicViewer.imageUrl = url;
    },
    [SET_GRAPHIC_VIEWER_IMAGE_INDEX](state) {
        const source = state.graphicViewer.collection.length > 0
            ? state.graphicViewer.collection
            : state.graphicViewer.graphics;

        state.graphicViewer.imageIndex = source.map(graphic => graphic.imageKey)
            .indexOf(state.graphicViewer.imageKey);
    },
    [INCREMENT_GRAPHIC_VIEWER_GRAPHIC](state) {
        const source = state.graphicViewer.collection.length > 0
            ? state.graphicViewer.collection
            : state.graphicViewer.graphics;

        state.graphicViewer.imageIndex++;
        if (state.graphicViewer.imageIndex === source.length) {
            state.graphicViewer.imageIndex = 0;
        }
    },
    [DECREMENT_GRAPHIC_VIEWER_GRAPHIC](state) {
        const source = state.graphicViewer.collection.length > 0
            ? state.graphicViewer.collection
            : state.graphicViewer.graphics;

        state.graphicViewer.imageIndex--;
        if (state.graphicViewer.imageIndex < 0) {
            state.graphicViewer.imageIndex = source.length - 1;
        }
    },
    // deprecated (except when called via setGraphicViewerGraphics action),
    // please use setGraphicViewerGraphics action so index is kept in sync.
    [SET_GRAPHIC_VIEWER_GRAPHICS](state, graphics) {
        graphics = Array.isArray(graphics) ? graphics : [];
        graphics.forEach(graphic => {
            if (graphic.thumbnailUrl && graphic.thumbnailUrl.length > 0) {
                return;
            }
            graphic.thumbnailUrl = makeThumbnailUrl(graphic.imageKey);
            state.graphicViewer.graphicsImageKeyMap[graphic.imageKey] = {
                thumbnailUrl: graphic.thumbnailUrl,
                title: graphic.title,
                type: graphic.type,
                tabTitle: graphic.tabTitle,
                accordionTitle: graphic.accordionTitle,
                rankIndex: graphic.rankIndex,
                searchRank: graphic.searchRank
            };
        });
        state.graphicViewer.graphics = graphics;
    },
    [SET_GRAPHIC_LOADED]: (state, loaded) => state.graphicLoaded = loaded,
    [SET_GRAPHIC_TOOLBAR_OVERFLOW_VISIBLE](state, visible) {
        state.toolbar.overflow.visible = visible;
    },
    [SET_GRAPHIC_RELATED_TOPICS](state, topics) {
        state.graphic.relatedTopics = topics;
    },
    [SET_GRAPHIC_RELATED_GRAPHICS](state, graphics) {
        state.graphic.relatedGraphics = graphics;
    },
    [SET_MULTIGRAPHICS_MODE](state, mode = true) {
        state.multigraphicsEnabled = mode;
    },
    [SET_ALL_GRAPHICS](state, graphics) {
        state.allGraphics = graphics;
    },
    [SET_GRAPHIC_TITLE](state, title) {
        state.graphic.title = title;
    },
    [SET_GRAPHIC_LANGUAGE](state, language) {
        state.graphic.language = language;
    },
    [SET_GRAPHIC_ID](state, id) {
        state.graphic.id = id;
    },
    [SET_GRAPHIC_IMAGE_KEY](state, key) {
        state.graphic.imageKey = key;
    },
    [SET_GRAPHIC_IMAGE_URL](state, url) {
        state.graphic.imageUrl = url;
    },
    [SET_GRAPHIC_DISPLAY_NAME](state, name) {
        state.graphic.displayName = name;
    },
    [SET_GRAPHIC_IMAGE_HTML](state, html) {
        state.graphic.imageHtml = html;
    },
    [SET_NORMAL_GRAPHIC_ID](state, normalGraphicId) {
        state.graphic.normalGraphicId = normalGraphicId;
    },
    [SET_GRAPHIC_SUBTYPE](state, subtype) {
        state.graphic.subtype = subtype;
    },
    [SET_OVERFLOW_GRAPHIC_TOOLS](state, visible) {
        state.tools.overflowGraphicTools = visible;
    },
    [RESET](state) {
        Object.assign(state, getInitialState());
    },
    [SET_GRAPHIC_ALTERNATE_LANGUAGES](state, alternateLanguages) {
        if (alternateLanguages === []) {
            state.graphic.alternateLanguages = [];
        }
        else {
            alternateLanguages.forEach(val => {
                state.graphic.alternateLanguages.push(val);
            });
        }
    },
    [SET_GRAPHIC_VERSION](state, version) {
        state.graphic.version = version;
    },
    [SET_GRAPHIC_TYPE](state, type) {
        state.graphic.type = type;
    },
    [SET_TOOLS_CURRENT_GRAPHIC](state, currentGraphic) {
        state.tools.currentGraphic = currentGraphic;
    },
    [SET_TOOLS_CAN_PRINT_CONTENT](state, canPrintContent) {
        state.tools.canPrintContent = canPrintContent;
    },
    [SET_TOOLS_PRINT_URL](state, printUrl) {
        state.tools.printUrl = printUrl;
    },
    [SET_TOOLS_POWER_POINT_URL](state, powerPointUrl) {
        state.tools.powerPointUrl = powerPointUrl;
    },
    [SET_GRAPHIC_ERROR_403](state, error403) {
        state.graphic.error403 = error403;
    },
    [SET_ERROR_IS_HAS_ERROR](state, isHasError) {
        state.error.isHasError = isHasError;
    },
    [SET_GRAPHIC_ERROR](state, error) {
        state.graphic.error = error;
    },
    [SET_ERROR_MESSAGE](state, message) {
        state.error.message = message;
    },
    [SET_GRAPHIC_PARAMS](state, params) {
        Object.assign(state.graphicParams, params);
    },
    [SET_GRAPHIC_URL_LANGUAGE](state, language) {
        state.graphicUrl.language = language;
    },
    [SET_TOOLS_IS_PRINT_VIEW](state, isPrintView) {
        state.tools.isPrintView = isPrintView;
    },
    [SET_TOOLS_GRAPHIC_READY](state, graphicReady) {
        state.tools.graphicReady = graphicReady;
    },
    [ADD_BOOKMARKED_GRAPHIC](state, graphic) {
        state.tools.bookmarkedGraphics.unshift(graphic);
    },
    [REMOVE_BOOKMARKED_GRAPHIC_AT_INDEX](state, index) {
        state.tools.bookmarkedGraphics.splice(index, 1);
    },
    [SET_BOOKMARK_IS_ACTIVE](state, active) {
        state.tools.bookmarkIsActive = active;
    },
    [SET_BOOKMARKED_GRAPHICS](state, bookmarkedGraphics) {
        state.tools.bookmarkedGraphics = bookmarkedGraphics;
    },
    [SET_SHOW_GRAPHIC_FEEDBACK](state, isShown) {
        state.graphic.showGraphicFeedback = isShown;
    },
    [SET_IS_GRAPHIC_VIEW](state, isGraphicView) {
        state.isGraphicView = isGraphicView;
        new IndexManager().bodyCss.setOrClear('gfxView', state.isGraphicView);
    },
    [SET_IS_PRINT_GRAPHIC](state, isPrintGraphic) {
        state.isPrintGraphic = isPrintGraphic;
        new IndexManager().bodyCss.setOrClear('graphicPrint', state.isPrintGraphic);
    },
    [SET_CREATE_POWERPOINT](state, data) {
        state.createPowerpoint = false;
        if ('create-powerpoint' in data && !!data['create-powerpoint']) {
            state.createPowerpoint = true;
        }
    }
};

export const actions = {
    updateGfxHoverTooltipBinding({ commit, getters }) {
        commit(BIND_GFX_HOVER_TOOLTIP, !getters.isBindGfxHoverTooltip);
    },
    getNextSingleModalGraphic({ commit, dispatch }) {
        commit(INCREMENT_GRAPHIC_VIEWER_GRAPHIC);
        return dispatch('launchGraphicViewerModal', { skipSidebarStateReset: true })
            .finally(() => {
                new PubSub().publish('wkutd.next/previousModalGraphic');
            });
    },
    getPreviousSingleModalGraphic({ commit, dispatch }) {
        commit(DECREMENT_GRAPHIC_VIEWER_GRAPHIC);
        return dispatch('launchGraphicViewerModal', { skipSidebarStateReset: true })
            .finally(() => {
                new PubSub().publish('wkutd.next/previousModalGraphic');
            });
    },
    setGraphicViewerGraphics({ commit }, { graphics }) {
        commit(SET_GRAPHIC_VIEWER_GRAPHICS, graphics);
        commit(SET_GRAPHIC_VIEWER_IMAGE_INDEX);
    },
    launchGraphicViewerModal({ getters, commit, dispatch }, payload) {
        commit(SET_GRAPHIC_VIEWER_IMAGE_LOADING, true);

        if (payload
        && payload.imageKey
        && !payload.isFromCollection
        && !isGraphicCollectionLink(payload.imageKey)) {
            commit(RESET_GRAPHIC_VIEWER_COLLECTION);
        }

        const imageKey = getActiveImageKey(getters, payload);
        commit(SET_GRAPHIC_VIEWER_IMAGE_KEY, imageKey);

        // Next set imageUrl
        let imageUrl = getters.graphicViewerImageUrl;
        if (payload && payload.imageUrl) {
            imageUrl = payload.imageUrl;
        }
        commit(SET_GRAPHIC_VIEWER_IMAGE_URL, imageUrl);
        commit(SET_GRAPHIC_VIEWER_IMAGE_INDEX);

        const url = setRankQPs(payload, getters.graphicViewerImageUrl);

        return dispatch('getGraphicForModal', { url })
            .then(result => {
                if (!(payload && payload.skipSidebarStateReset)) {
                    dispatch('setGraphicViewerGraphics', {
                        graphics: getters.graphicRelatedGraphics
                    });
                    commit(SET_GRAPHIC_VIEWER_ACCORDION_ITEMS, getDefaultAccordionItems());
                }
                commit(SET_GRAPHIC_LOADED, true);
                logPendoTrackEvent(getters.graphicId);
                return result || Promise.reject();
            })
            .catch(() => {
                commit(SET_GRAPHIC_VIEWER_IMAGE_KEY, '');
                commit(SET_GRAPHIC_LOADED, false);
            })
            .finally(() => {
                commit(SET_GRAPHIC_VIEWER_IMAGE_LOADING, false);
                commit(SET_GRAPHIC_VIEWER_VISIBILITY, true);
            });
    },
    showGraphicShareForm({ commit, getters, dispatch }, evt) {
        if (!getters.graphicLoaded) {
            return;
        }
        commit(SET_OVERFLOW_GRAPHIC_TOOLS, false);
        dispatch('share/showShareForm', evt, { root: true });
    },
    shouldOpenGraphicLinkAsModal({ rootGetters }, { evt } = {}) {
        return evt
            && rootGetters['device/isDesktopView']
            && !usingModifierKeys(evt)
            && ((evt.target
                 && getQueryParamValues(evt.target.getAttribute('href'), 'imageKey'))
            || (evt.currentTarget
                 && getQueryParamValues(evt.currentTarget.getAttribute('href'), 'imageKey')));
    },
    // eslint-disable-next-line complexity
    openGraphicLinkAsModal({ dispatch, rootGetters }, { evt, options = {} }) {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        let url = evt && ((evt.target && evt.target.getAttribute('href'))
                || (evt.currentTarget && evt.currentTarget.getAttribute('href')));


        // Workaround for bug where graphic links in topic body will sometimes
        // have the wrong topicKey in their url. This topicKey should always be the
        // current topic's (if on a topic page), so until the bug is fixed, we
        // can replace it with the current topic's id (which is interchangeable).
        const router = rootGetters['app/router'];
        const routeName = router && typeof router.current === 'function' && router.current().name;
        if (routeName === 'topic' || routeName === 'topicLanguage') {
            const topicKey = rootGetters['topic/topicId'];
            if (topicKey && topicKey.length > 0) {
                url = setQueryParamValue(url, 'topicKey', topicKey);
            }
        }
        // End workaround

        // Workaround for main search view
        // If we need to set appears in topic
        // we need to have search url param for appropriate backend response
        const search = getQueryParamValues(url, 'search');
        if (options.showAppearsInTopics && !search) {
            url = setQueryParamValue(url, 'search', '');
        }
        // End workaround

        dispatch('launchGraphicViewerModal', {
            ...options,
            imageKey: getQueryParamValues(url, 'imageKey'),
            imageUrl: url
        });
    },
    async handleUseGraphicLink({ dispatch }, payload) {
        if (await dispatch('shouldOpenGraphicLinkAsModal', payload)) {
            return dispatch('openGraphicLinkAsModal', payload);
        }
    },
    setGraphicViewerCollection({ dispatch, commit }, payload) {
        if (!payload.event || !payload.imageKeys) {
            return;
        }
        const graphicCollection = {
            sidebarTitle: C_GRAPHICS.VIEWER_DEFAULT_SIDEBAR_TITLE,
            collection: []
        };
        if (payload.imageKeys.length > 1) {
            graphicCollection.sidebarTitle = payload.event.target.innerText;
            const promises = [];
            payload.imageKeys.forEach(imageKey => {
                promises.push(
                    dispatch('getTooltipGraphic', {
                        event: payload.event,
                        imageKey,
                        setUrl: false
                    })
                );
            });
            return Promise.all(promises)
                .then(values => {
                    graphicCollection.collection = values;
                    commit(SET_GRAPHIC_VIEWER_COLLECTION, graphicCollection);

                    payload.checkCollectionTitles
                    && dispatch('updateGraphicViewerCollectionTitles', {
                        url: payload.event.target.href
                    });
                })
                .catch(err => {
                    Logger.error('Error during setting graphic viewer collection', err);
                });
        }

        commit(SET_GRAPHIC_VIEWER_COLLECTION, graphicCollection);
    },
    updateGraphicViewerCollectionTitles({ commit, getters }, payload) {
        const hasEmptyTitle = getters.graphicViewerCollection.some(graphic => {
            return !graphic.title;
        });

        if (!hasEmptyTitle) {
            return;
        }

        const topicKey = getQueryParamValues(payload.url, 'topicKey');
        let topicLanguage = getQueryParamValues(payload.url, 'language');
        const topicId = idFromTopicKey(topicKey);
        if (!topicLanguage) {
            topicLanguage = 'en-US';
        }
        utdRest('contents/outline/related-graphics', { topicId, topicLanguage, params: {} })
            .then(data => {
                commit(SET_GRAPHIC_VIEWER_COLLECTION_TITLES, {
                    graphics: data.graphics
                });
            })
            .catch(err => {
                Logger.error('Error during get related graphic', err);
            });
    },
    getTooltipGraphic({ getters }, payload) {
        const graphicObj = getters.getGraphicByImageKey(payload.imageKey) || {};
        return {
            url: payload.setUrl === false ? null : payload.event.target.href,
            title: graphicObj.title || '',
            thumbnailUrl: graphicObj.thumbnailUrl || makeThumbnailUrl(payload.imageKey),
            target: payload.event.target,
            imageKey: payload.imageKey,
            type: graphicObj.type,
            tabTitle: graphicObj.tabTitle,
            accordionTitle: graphicObj.accordionTitle,
            rankIndex: graphicObj.rankIndex,
            searchRank: graphicObj.searchRank
        };
    },
    // eslint-disable-next-line complexity
    getGraphicForModal({ getters, commit, rootGetters, dispatch }, payload) {
        const url = payload.url;
        let id = payload.id || '';

        dispatch('reset');

        const search = encodeURIComponent(rootGetters['search/searchParamsSearchText']);
        let params = url.substr(url.indexOf('?'));
        params = setQueryParamValue(params, 'tabTitle');
        params = setQueryParamValue(params, 'accordionTitle');
        const imageKey = getQueryParamValues(url, 'imageKey');
        id = id || idFromImageKey(imageKey);
        const source = getQueryParamValues(url, 'source');
        const searchRank = getQueryParamValues(url, 'rank');
        const rankIndex = getQueryParamValues(url, 'display_rank');
        let section = getQueryParamValues(url, 'tabTitle');
        let term = getQueryParamValues(url, 'accordionTitle');

        // Store url to solo image for 'Full View' link in modal
        commit(SET_GRAPHIC_IMAGE_URL, url);
        commit(SET_GRAPHIC_IMAGE_KEY, imageKey);

        commit(SET_GRAPHIC_ERROR_403, false);

        const urlLang = getLanguageFromImageUrl(url);
        const language = urlLang || rootGetters['app/router'].stateParams().language || 'en_us';

        const titleObject = checkIfFromKPP(source, section, term);
        section = titleObject.section;
        term = titleObject.term;

        return utdRest('contents/graphic/detailed', {
            language,
            id,
            search,
            source,
            rankIndex,
            searchRank,
            params,
            section,
            term
        })
            .then(data => resolveGraphicForModal({ getters, commit, rootGetters, dispatch }, data))
            .catch(err => resolveError({ commit, dispatch }, err));
    },
    showMediaModal({ rootGetters }, payload = {}) {
        const { mediaModalDialog, event } = payload;

        if (!rootGetters['device/isDesktopView']) {
            return false;
        }

        if (event && event.preventDefault) {
            event.preventDefault();
        }

        if (!isShowMediaPayloadValid(rootGetters, payload)) {
            return;
        }

        logMediaModalOpen(rootGetters, payload);

        mediaModalDialog.open();
    },
    getGraphic({ getters, commit, rootGetters, dispatch }) {
        const restUrl = makeRestUrl('contents/graphic', getters.graphicUrlLanguage, '/json');
        if (getters.toolsIsPrintView) {
            commit(SET_GRAPHIC_PARAMS, { view: 'print' });
        }

        commit(SET_GRAPHIC_ERROR_403, false);
        const promise = getGraphicFromUri(restUrl,
            getters.graphicParams,
            getters.graphicUrlLanguage)
            .then(data => resolveGraphic({ getters, commit, rootGetters, dispatch }, {
                ...data,
                reqParams: getters.graphicParams
            }))
            .catch(error => resolveError({ commit, dispatch }, error));

        return promise;
    },
    reset({ commit }) {
        new ServerMessaging().resetModal();
        commit(`error/${RESET_ERROR}`, {}, { root: true });
        // Disabling graphic tools till graphic loads.
        commit(SET_GRAPHIC_LOADED, false);
        commit(RESET);
    },
    bookmarkGraphic({ getters, commit, dispatch, rootGetters }) {
        // TODO - use getters.isMultiGraphic
        if (getters.graphicImageKey.match(C_GRAPHICS.MULTI_GRAPHIC_SEPARATOR)) {
            const keys = getters.graphicImageKey.split(C_GRAPHICS.MULTI_GRAPHIC_SEPARATOR);

            keys.forEach(key => {
                addGraphic({ getters, commit, dispatch, rootGetters }, key);
            });
        }
        else {
            addGraphic({ getters, commit, dispatch, rootGetters }, getters.graphicImageKey);
        }

        return true;
    },
    deleteBookmark({ getters, commit, dispatch }) {
        const getKeyId = key => key.match(/\d+/)[0];

        // TODO - use getters.isMultiGraphic
        if (getters.graphicImageKey.match(C_GRAPHICS.MULTI_GRAPHIC_SEPARATOR)) {
            const keys = getters.graphicImageKey.split(C_GRAPHICS.MULTI_GRAPHIC_SEPARATOR);
            for (let i = 0; i < keys.length; i++) {
                removeGraphic({ getters, commit, dispatch },
                    getKeyId(keys[i]), getters.graphicLanguage);
            }
        }
        else {
            removeGraphic({ getters, commit, dispatch },
                getKeyId(getters.graphicImageKey),
                getters.graphicLanguage);
        }

        return true;
    },
    async resolveBookmarkContent({ getters, commit, rootGetters, dispatch }) {
        if (rootGetters['app/isAppDataLoaded']) {
            await tryMarkBookmarkedGraphic({ getters, commit, rootGetters, dispatch });
        }
        else {
            setTimeout(() => dispatch('resolveBookmarkContent'), RESOLVE_BOOKMARK_RETRY_MS);
        }
    }
};

const graphic = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default graphic;

const checkIfFromKPP = (source, section, term) => {
    if (source === 'kpp-featured-graphic' || source === 'kpp' || source === 'kpp-graphic-section') {
        return { section: term, term: null };
    }
    return { section, term };
};

const setRankQPs = (payload, url) => {
    if (payload && url) {
        let newUrl = url;
        if (payload.searchRank) {
            newUrl = setQueryParamValue(url, 'rank', payload.searchRank);
        }
        if (payload.rankIndex) {
            newUrl = setQueryParamValue(newUrl, 'display_rank', payload.rankIndex);
        }
        return newUrl;
    }
    return url;
};

const logPendoTrackEvent = graphicId => {
    if (typeof pendo !== 'undefined') {
        pendo.track('LightboxOpen', { id: graphicId });
    }
};

const getGraphicFromUri = async(uri, params, language) => {
    const cacheKey = `${uri}?id=${params.id}`;
    let promise = await new UtdQueuedCache().getDeferred('utdSPAContentCache', cacheKey);
    // If we didn't get anything make the REST call
    if (!promise) {
        // bypass true tells the global 404 interceptor to ignore a 404 from this call
        promise = utdRest('contents/graphic', { language, params });
    }
    else if (new UtdCache().doCacheHitEvent) {
        try {
            params.languageCode = language;
            utdRest('event/graphic/json', params);
        }
        catch (e) {
            Logger.warn(`Could not record graphic event for graphicUrl:${uri},exception:${e}`);
        }
    }
    return promise;
};

const getActiveImageKey = (getters, payload) => {
    let imageKey;

    let graphicSet = getters.graphicViewerGraphics;
    if (getters.graphicViewerCollection.length > 0) {
        graphicSet = getters.graphicViewerCollection;
    }

    if (getters.graphicViewerImageIndex > -1) {
        imageKey = graphicSet[getters.graphicViewerImageIndex].imageKey;
    }

    if (payload && payload.imageKey) {
        imageKey = payload.imageKey;
        if (isGraphicCollectionLink(payload.imageKey) && graphicSet.length) {
            imageKey = graphicSet[0].imageKey;
        }
    }

    return imageKey;
};

const isGraphicCollectionLink = imageKey => {
    return imageKey.split(C_GRAPHICS.MULTI_GRAPHIC_SEPARATOR).length > 1;
};

const logMediaModalOpen = (rootGetters, payload) => {
    const { graphic, inlineGraphicsType, source } = payload;
    const graphicUrl = graphic && graphic.url || '';
    const graphicDisplayName = graphic && graphic.displayName || '';
    const clickEventPayload = {
        targetUrl: graphicUrl,
        uiElementName: `${inlineGraphicsType}.${source}`,
        optData: graphicDisplayName
    };
    const topicKey = rootGetters['topic/topicId'];
    if (topicKey && topicKey.length > 0) {
        clickEventPayload.contentId = topicKey;
    }

    new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, clickEventPayload);
};

const isShowMediaPayloadValid = (rootGetters, payload) => {
    const { graphic, inlineGraphicsType } = payload;
    const graphicUrl = graphic && graphic.url || '';

    if (!(graphicUrl
        && iFramedGraphicModalTypes.includes(inlineGraphicsType))) {
        Logger.warn('Error displaying media modal:'
            + ` graphicUrl=[${graphicUrl}] graphicsType=[${inlineGraphicsType}]`);
        return false;
    }
    return true;
};

const resolveGraphic = ({ getters, commit, rootGetters, dispatch }, data) => {
    try {
        return tryResolveGraphic({ getters, commit, rootGetters, dispatch }, data);
    }
    catch (e) {
        const error = {
            status: -2
        };
        return resolveError({ commit, dispatch }, error);
    }
};

const tryResolveGraphic = ({ getters, commit, rootGetters, dispatch }, data) => {
    const graphics = data.graphics;
    let graphicInfo = null;
    let imageHtml = '';

    storeResultsInCache({ rootGetters }, data);

    if (graphics instanceof Array) {
        const result = resolveMultiGraphicImageHtmlAndKey({ getters, commit }, imageHtml, graphics);
        imageHtml = result.imageHtml;
        graphicInfo = result.graphicInfo;
    }
    else {
        graphicInfo = graphics.graphicInfo;
        imageHtml = graphics.imageHtml;
        commit(SET_GRAPHIC_IMAGE_KEY, graphics.graphicInfo.imageKey);
    }

    // Using the "trustAsHtml" here to prevent sanitizer from removing element
    // ID's which screws up styling.
    resolveGraphicProperties({ commit }, graphicInfo, imageHtml);

    commit(SET_TOOLS_CURRENT_GRAPHIC, {
        contentId: getters.graphicId,
        contentType: getters.graphicType,
        exists: true,
        languageCode: getters.graphicLanguage,
        title: `Graphic: ${getters.graphicTitle}`,
        url: rootGetters['app/router'].url()
    });

    dispatch('resolveBookmarkContent');

    if (graphicInfo.languageDisplayNames) {
        commit(
            SET_GRAPHIC_ALTERNATE_LANGUAGES,
            getAlternateLanguages(graphicInfo.languageDisplayNames));
    }

    const pUrls = getPrintAndPowerPointUrls({ language: getters.graphicLanguage });

    setCommonInfo({ commit, rootGetters }, graphicInfo, pUrls.printUrl, pUrls.powerPointUrl);

    commit(SET_IS_GRAPHIC_VIEW, true);

    // Trigger footer repositioning
    new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
        fromLocation: 'graphic.service: resolveGraphic'
    });

    // Enabling graphic tools after graphic loads.
    // vm.graphic.graphicLoaded = true; // DEPRECATED - use vuex topic store
    // ???
    commit(SET_GRAPHIC_LOADED, true);
    return true;
};

const storeResultsInCache = ({ rootGetters }, data) => {
// CACHE>>>: Store results into session cache
    // TODO: Add language for Spanish Ed when needed
    const cacheKey = `${data.route}?id=${data.reqParams.id}`;
    new UtdQueuedCache()
        .setPersistent(
            'utdSPAContentCache',
            cacheKey,
            data,
            rootGetters['app/contentVersion']);
    // <<<CACHE
};

const resolveMultiGraphicImageHtmlAndKey = ({ getters, commit }, baseImageHtml, graphics) => {
    const graphicInfo = graphics[0].graphicInfo;
    let imageHtml = baseImageHtml;
    for (let i = 0; i < graphics.length; i++) {
        imageHtml += graphics[i].imageHtml;
        if (i > 0) {
            commit(
                SET_GRAPHIC_IMAGE_KEY,
                `${getters.graphicImageKey}~${graphics[i].graphicInfo.imageKey}`);
        }
        else {
            commit(SET_GRAPHIC_IMAGE_KEY, graphics[i].graphicInfo.imageKey);
        }
    }
    commit(SET_ALL_GRAPHICS, graphics);

    return { graphicInfo, imageHtml };
};

const resolveGraphicProperties = ({ commit }, graphicInfo, imageHtml) => {
    commit(SET_GRAPHIC_SUBTYPE, graphicInfo.subtype);
    commit(SET_GRAPHIC_TITLE, graphicInfo.title);
    commit(SET_GRAPHIC_DISPLAY_NAME, graphicInfo.displayName);
    commit(SET_GRAPHIC_IMAGE_HTML, imageHtml);
    commit(SET_GRAPHIC_ALTERNATE_LANGUAGES, []);
    commit(SET_GRAPHIC_ID, graphicInfo.id);

    // Assign language of graphic returned
    commit(SET_GRAPHIC_LANGUAGE, graphicInfo.languageCode);
    commit(SET_GRAPHIC_VERSION, graphicInfo.version);
    commit(SET_GRAPHIC_TYPE, graphicInfo.type);
};

const getAlternateLanguages = languageDisplayNames => {
    return Object.keys(languageDisplayNames).map(langId => {
        return {
            id: langId,
            name: languageDisplayNames[langId]
        };
    });
};

const setCommonInfo = ({ commit, rootGetters }, graphicInfo, printUrl, powerPointUrl) => {
    if (graphicInfo.subtype === 'graphic_movie') {
        commit(SET_TOOLS_CAN_PRINT_CONTENT, false);
    }
    else {
        commit(SET_TOOLS_CAN_PRINT_CONTENT, !rootGetters['app/isProspectMode']);
        commit(SET_TOOLS_POWER_POINT_URL, powerPointUrl);
    }
    commit(SET_TOOLS_PRINT_URL, printUrl);
};

const resolveError = ({ commit, dispatch }, error) => {
    if (error.status === 403) {
        commit(SET_GRAPHIC_ERROR_403, true);
        commit(SET_ERROR_IS_HAS_ERROR, dispatch('error/resolveContent403', error, { root: true }));
    }
    else if (error.status === 404) {
        commit(SET_GRAPHIC_ERROR, true);
        commit(SET_ERROR_MESSAGE, 'This graphic wasn\'t found');
    }
    else if (error.status !== '-1' && error.status !== -1) {
        commit(SET_GRAPHIC_ERROR, true);
        commit(SET_ERROR_MESSAGE, 'Error loading this graphic, please try again.');
    }
    return false;
};

const resolveGraphicForModal = ({ getters, commit, rootGetters, dispatch }, data) => {
    commit(SET_GRAPHIC_TITLE, data.graphicInfo.title);
    commit(SET_GRAPHIC_IMAGE_HTML, data.imageHtml);
    commit(SET_NORMAL_GRAPHIC_ID, data.normalGraphicId || '');
    commit(SET_GRAPHIC_RELATED_TOPICS, data.relatedTopics || []);
    commit(SET_GRAPHIC_RELATED_GRAPHICS, data.relatedGraphics || []);
    commit(SET_GRAPHIC_LANGUAGE, data.graphicInfo.languageCode);
    commit(SET_GRAPHIC_VIEWER_LANGUAGE, data.graphicInfo.languageCode);
    commit(SET_GRAPHIC_SUBTYPE, data.graphicInfo.subtype);
    commit(SET_GRAPHIC_TYPE, data.graphicInfo.type);
    commit(SET_GRAPHIC_ID, data.graphicInfo.id);
    commit(SET_GRAPHIC_VERSION, data.graphicInfo.version);
    commit(SET_SHOW_GRAPHIC_FEEDBACK, data.showGraphicFeedback);

    commit(
        SET_GRAPHIC_IMAGE_URL,
        setQueryParamValue(
            getters.graphicImageUrl,
            'search',
            rootGetters['search/searchParamsSearchText']));

    const pUrls = getPrintAndPowerPointUrls({ url: getters.graphicImageUrl,
        language: getters.graphicLanguage });

    // Reset vm.allGraphics before this call, so potential bookmark will get assigned correctly
    commit(SET_ALL_GRAPHICS, {});

    dispatch('resolveBookmarkContent');

    setCommonInfo({ commit, rootGetters }, data.graphicInfo, pUrls.printUrl, pUrls.powerPointUrl);

    return true;
};

const addGraphic = ({ getters, commit, dispatch, rootGetters }, contentId) => {
    const graphicParams = {};

    graphicParams.contentId = encodeURIComponent(contentId);
    graphicParams.languageCode = getters.graphicLanguage;

    if (getters.getAllGraphics.length > 0) {
        for (let i = 0; i < getters.getAllGraphics.length; i++) {
            const graphic = getters.getAllGraphics[i];
            if (graphic.graphicInfo.imageKey === contentId) {
                commit(ADD_BOOKMARKED_GRAPHIC,
                    buildGraphicBookmark({ rootGetters }, graphic.graphicInfo));
                break;
            }
        }
    }
    else if (getters.graphic) {
        if (getters.graphicImageKey === contentId) {
            commit(ADD_BOOKMARKED_GRAPHIC, buildGraphicBookmark({ rootGetters }, getters.graphic));
        }
    }

    dispatch('profile/putProfileItem', {
        path: 'bookmark/graphic',
        params: graphicParams
    }, { root: true });
};

const buildGraphicBookmark = ({ rootGetters }, graphic) => {
    return {
        contentId: graphic.id,
        contentType: graphic.type,
        exists: true,
        languageCode: graphic.language ? graphic.language : graphic.languageCode,
        title: `Graphic: ${graphic.title}`,
        url: graphic.imageUrl || rootGetters['app/router'].url()
    };
};

const removeGraphic = ({ getters, commit, dispatch }, contentId, languageCode) => {
    const graphicParams = {};

    const graphicIndex = findLastIndex(getters.toolsBookmarkedGraphics,
        graphic => graphic.contentId === contentId && graphic.languageCode === languageCode);

    if (graphicIndex > -1) {
        const graphic = getters.toolsBookmarkedGraphics[graphicIndex];
        graphicParams.contentId = graphic.contentId;
        graphicParams.contentType = graphic.contentType;
        graphicParams.contentVersion = graphic.contentVersion;
        graphicParams.languageCode = graphic.languageCode;

        commit(REMOVE_BOOKMARKED_GRAPHIC_AT_INDEX, graphicIndex);
        dispatch('profile/deleteProfileItem', {
            path: 'bookmark/delete',
            params: graphicParams
        }, { root: true });
    }
    return true;
};

const tryMarkBookmarkedGraphic = async({ getters, commit, dispatch, rootGetters }) => {
    commit(SET_BOOKMARK_IS_ACTIVE, false);

    const bookmarkPermissions = rootGetters['profile/permissions'].bookmarks;
    const shouldRefreshAndMark = bookmarkPermissions
        && await dispatch('profile/isCanRefresh', 'bookmarks', { root: true });
    if (shouldRefreshAndMark) {
        dispatch('profile/getBookmarks', {}, { root: true }).then(() => {
            markBookmarkedGraphic({ getters, commit, rootGetters, dispatch });
        });
    }
    else if (bookmarkPermissions) {
        markBookmarkedGraphic({ getters, commit, rootGetters, dispatch });
    }
};

const markBookmarkedGraphic = ({ getters, commit, rootGetters }) => {
    commit(SET_BOOKMARKED_GRAPHICS, rootGetters['profile/bookmarksData']);

    const value = findLast(getters.toolsBookmarkedGraphics,
        value => value.contentId === getters.graphicId
            && value.languageCode === getters.graphicLanguage);

    if (value) {
        commit(SET_BOOKMARK_IS_ACTIVE, true);
    }
};
