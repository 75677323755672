<!-- eslint-disable vue/no-v-html -->
<template>
  <utd-modal-dialog ref="myAccountDialogRef"
                    :modal-classes="'contact-information-modal'"
                    :close-on-overlay-click="isMobileOnDesktop"
                    :close-on-escape="!isMobileOnDesktop"
                    @closed="handleClose()">
    <template #header>{{ $t('MYACCOUNT.CONTACT_INFORMATION') }}</template>

    <div class="contact-information-dialog">
      <div class="dialog__profile-info">
        <p class="ds1-utd-weight-bold"
           :class="showSubscriberNumber ? 'ds1-mb-0' : ''">{{ welcomeName }}</p>
        <p v-if="showSubscriberNumber" class="ds1-utd-weight-bold ds1-mt-0">
          Subscriber number: {{ contactFieldValue('accountNumber') }}
        </p>
      </div>
      <utd-notification v-if="submitSuccess && !isUnexpectedError"
                        id="contactInfoNotification"
                        class="contactNotificationStyle"
                        :notification-mode="C_ACCOUNT.MESSAGE_TYPE.SUCCESS">
        <template #title>Success</template>
        <div class="dialog__server-text">{{ submitMessage }}</div>
      </utd-notification>
      <utd-notification v-if="showErrorNotification"
                        id="unexpectedServerError"
                        class="contactNotificationStyle"
                        :notification-mode="'error'">
        <template #title>Error</template>
        <ul class="ds1-pl-3">
          <li>
            <p class="dialog__server-error-text">
              {{ $t(formErrorMessage) }}
            </p>
          </li>
          <li v-if="isUnexpectedError">
            <span v-html="$t('ERRORS.CONTACT_ON_ERROR')" />
          </li>
        </ul>
      </utd-notification>
      <utd-notification v-if="isShowEmailDisclaimer"
                        id="emailDisclaimer"
                        class="contactNotificationStyle"
                        :notification-mode="C_ACCOUNT.MESSAGE_TYPE.INFO">
        <template #title>{{ $t(C_ACCOUNT.FORM_MESSAGING.EMAIL_NOTIFICATION_TITLE) }}</template>
        <span class="dialog__server-text"
              v-html="$t(C_ACCOUNT.FORM_MESSAGING.EMAIL_NOTIFICATION_BODY(disclaimerEmail))" />
      </utd-notification>
      <utd-notification v-if="displayUserProfessionNotification"
                        id="practiceSettingDisclaimer"
                        class="contactNotificationStyle"
                        :notification-mode="C_ACCOUNT.MESSAGE_TYPE.INFO"
                        :has-close-notification="true"
                        @closed="close">
        <template #title>{{ $t(C_ACCOUNT.FORM_MESSAGING.FIELDS_NEED_INFO_TITLE) }}</template>
        <span class="dialog__server-text"
              v-html="$t(C_ACCOUNT.FORM_MESSAGING.FIELDS_NEED_INFO_BODY)" />
      </utd-notification>
      <form class="dialog__contact-form">
        <div class="dialog__contact-info-container">
          <fieldset class="dialog__fieldset wk-js2">
            <utd-text-input :label="$t('MYACCOUNT.CONTACT_EMAIL')"
                            :is-error="isEmailError"
                            :error-message="emailValidator"
                            :is-disabled="!contactField('email').isEditable"
                            input-id="emailInput"
                            class="my-account-email-input">
              <input id="emailInput"
                     v-model="fields.emailAddress.emailAddress"
                     class="wk-field-input"
                     @blur="toggleEmailDisclaimerForSsoEmail()">
            </utd-text-input>
            <p v-if="shouldShowEmailChangedError" class="dialog__email-changed-error">
              Email has been changed. Send a new verification code.
            </p>
          </fieldset>
          <p v-if="isTwoFactorEmailVerification" class="dialog__email-verification-note">
            You will need access to the new account to complete an email address change.
          </p>
          <p v-if="showVerificationCodeError" class="dialog__verification-code-error">
            {{ $t("ERRORS.VERIFICATION_CODE_ERROR") }}
          </p>
          <utd-email-code-input v-if="showEmailCodeInput"
                                :input="emailCodeInput"
                                :new-code-sent="newCodeSent"
                                @send-email-verification-code="sendVerificationCode"
                                @utd-email-code-input="handleEmailCodeInput" />
          <fieldset class="dialog__fieldset">
            <utd-phone-input ref="phoneInputRef"
                             :input="phoneInput"
                             :options="phoneOptions"
                             :is-dirty-override="isDirty['phone1']"
                             required
                             @on-phone-type-change="handlePhoneType"
                             @on-phone-input-blur="handlePhoneBlur"
                             @on-phone-input="handlePhoneInput" />
          </fieldset>
          <fieldset class="dialog__fieldset wk-js2">
            <utd-select id="countryInput"
                        :options="myAccountCountries"
                        :selected="selectedIndices.country"
                        :label="$t('MYACCOUNT.CONTACT_COUNTRY')"
                        @utd-select="handleCountrySelect" />
          </fieldset>
          <fieldset class="dialog__fieldset wk-js2">
            <utd-text-input :label="$t('MYACCOUNT.CONTACT_ADDRESS1')"
                            :is-error="!isAddressValid && isDirty.address"
                            :error-message="addressValidator"
                            :is-disabled="!contactField('address1').isEditable"
                            input-id="mailingAddress">
              <input id="mailingAddress"
                     v-model="fields.address.address1"
                     class="wk-field-input"
                     @blur="handleInputBlur('address')">
            </utd-text-input>
          </fieldset>
          <fieldset class="dialog__fieldset wk-js2">
            <utd-text-input :label="$t('MYACCOUNT.CONTACT_ADDRESS2')"
                            :is-error="!isAddress2Valid && isDirty.address"
                            :error-message="address2Validator"
                            :optional="true"
                            :is-disabled="!contactField('address2').isEditable"
                            input-id="addressLine2">
              <input id="addressLine2"
                     v-model="fields.address.address2"
                     class="wk-field-input"
                     @blur="handleInputBlur('address2')">
            </utd-text-input>
          </fieldset>
          <fieldset v-if="!hideAddress3" class="dialog__fieldset wk-js2">
            <utd-text-input :label="$t('MYACCOUNT.CONTACT_ADDRESS3')"
                            :is-error="!isAddress3Valid && isDirty.address"
                            :error-message="address3Validator"
                            :optional="true"
                            :is-disabled="!contactField('address3').isEditable"
                            input-id="addressLine3">
              <input id="addressLine3"
                     v-model="fields.address.address3"
                     class="wk-field-input"
                     @blur="handleInputBlur('address3')">
            </utd-text-input>
          </fieldset>
          <div class="dialog__add-address-line">
            <utd-button class="dialog__add-address-line-btn"
                        button-style="text"
                        @click.prevent="addAddressLine">
              <span :class="{'wkce-icon-plus-circle': hideAddress3,
                             'wkce-icon-minus-circle': !hideAddress3}" />
              <span>{{ hideAddress3 ? 'Add' : 'Hide' }} address line</span>
            </utd-button>
          </div>
          <fieldset class="dialog__fieldset wk-js2">
            <utd-text-input :label="$t('MYACCOUNT.CONTACT_ZIP')"
                            :is-error="!isZipCodeValid && isDirty.zip"
                            :optional="!isUsOrCanada"
                            :error-message="zipCodeValidator"
                            :is-disabled="!contactField('zip').isEditable"
                            input-id="zipCode">
              <input id="zipCode"
                     v-model="fields.address.zip"
                     class="wk-field-input"
                     @blur="fetchZipCodeData()">
            </utd-text-input>
          </fieldset>
          <fieldset class="dialog__fieldset wk-js2">
            <utd-select v-if="showCityDropdown"
                        id="citySelect"
                        :options="citiesList"
                        :selected="selectedIndices.city"
                        :label="$t('MYACCOUNT.CONTACT_CITY')"
                        @utd-select="handleCitySelect" />
            <utd-text-input v-else
                            :is-error="!isCityValid && isDirty.city"
                            :error-message="cityValidator"
                            :is-disabled="!contactField('city').isEditable"
                            :label="$t('MYACCOUNT.CONTACT_CITY')"
                            input-id="cityInput">
              <input id="cityInput"
                     v-model="fields.address.city"
                     class="wk-field-input"
                     @blur="handleInputBlur('city')">
            </utd-text-input>
          </fieldset>
          <fieldset class="dialog__fieldset wk-js2">
            <utd-select v-show="isUsOrCanada"
                        id="stateSelect"
                        :options="filteredStates"
                        :selected="selectedIndices.state"
                        :label="$t('MYACCOUNT.CONTACT_STATE')"
                        @utd-select="handleStateSelect" />
          </fieldset>
        </div>
        <div v-if="hasUserProfessions" class="dialog__user-profession-container ds1-pv-3">
          <fieldset class="dialog__fieldset wk-js2">
            <utd-select class="practiceSettingSelect"
                        :options="myAccountPracticeSettingOptions"
                        :selected="selectedIndices.practiceSetting"
                        :placeholder="$t('MYACCOUNT.SELECT_PRACTICE_SETTING')"
                        label="Practice Setting"
                        @utd-select="handleUserProfessionsSelect('practiceSetting', $event, myAccountPracticeSettingOptions)" />
          </fieldset>
          <fieldset class="dialog__fieldset wk-js2">
            <utd-select class="practiceTypeSelect"
                        :options="myAccountpracticeTypeOptions"
                        :selected="selectedIndices.practiceType"
                        :placeholder="$t('MYACCOUNT.SELECT_ROLE')"
                        label="Role"
                        @utd-select="handleUserProfessionsSelect('practiceType', $event, myAccountpracticeTypeOptions)" />
          </fieldset>
          <fieldset class="dialog__fieldset wk-js2">
            <utd-select class="specialtySelect"
                        :options="myAccountSpecialtyOptions"
                        :selected="selectedIndices.specialty"
                        :placeholder="$t('MYACCOUNT.SELECT_SPECIALTY')"
                        label="Specialty"
                        @utd-select="handleUserProfessionsSelect('specialty', $event, myAccountSpecialtyOptions)" />
          </fieldset>
        </div>
      </form>
      <p class="ds1-utd-size-1">
        {{ $t('MYACCOUNT.CONTACT_PRIVACY_DISCLOSURE') }}
      </p>
    </div>

    <template #footer>
      <utd-button id="closeDialogButton"
                  button-style="ghost"
                  @click.prevent="closeDialog()">
        {{ submitSuccess ? 'Close' : 'Cancel' }}
      </utd-button>
      <utd-button v-if="!showSendCodeButton"
                  id="saveChangesButton"
                  :disabled="isSubmitDisabled"
                  @click.prevent="submitForm()">
        Save Changes
      </utd-button>
      <utd-button v-if="showSendCodeButton"
                  id="sendVerificationCodeButton"
                  :disabled="isSendCodeDisabled"
                  @click.prevent="sendVerificationCode()">
        Send verification code
      </utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UtdTextInput from '_acaSrc/components/shared/input/UtdTextInput.vue';
import UtdPhoneInput from '_acaSrc/components/shared/input/UtdPhoneInput.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import UtdSelect from '_acaSrc/components/shared/input/UtdSelect.vue';
import UtdEmailCodeInput from '_acaSrc/components/shared/input/UtdEmailCodeInput.vue';
import {
    C_ACCOUNT,
    C_MY_ACCOUNT,
    emailRegex,
    maxContactFieldLength,
    maxEmailLength,
    numericRegex,
    validMailingAddressRegex,
    validZipCodeRegex
} from '_acaSrc/utility/constants';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import PubSub from '_acaSrc/utility/PubSub';
import UtdRestClient from '_acaSrc/utility/http/UtdRestClient';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import { SET_CONTACT_INFO_FIELDS } from '_acaSrc/store/account.store';

const FETCH_SUCCESS = 0;

export default {
    components: {
        UtdModalDialog,
        UtdPhoneInput,
        UtdButton,
        UtdTextInput,
        UtdNotification,
        UtdSelect,
        UtdEmailCodeInput
    },
    props: {
        isSso: {
            type: Boolean,
            default: false
        },
        isEditMyProfileLink: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            C_MY_ACCOUNT,
            C_ACCOUNT,
            isSubmitting: false,
            isUnexpectedError: false,
            isFormFieldError: false,
            formErrorMessage: 'MYACCOUNT.UPDATE_INFORMATION_UNEXPECTED_ERROR',
            canResetForm: true,
            hideAddress3: true,
            oldEmailAddress: '',
            pendingEmailAddress: '',
            serverErrors: {
                address1: '',
                address2: '',
                address3: '',
                city: '',
                state: '',
                county: '',
                zip: '',
                country: '',
                email: '',
                phone1: '',
                practiceSetting: '',
                practiceType: '',
                specialty: '',
                verificationCode: ''
            },
            isDirty: {
                address: false,
                city: false,
                zip: false,
                email: false,
                phone1: false
            },
            fields: {
                address: {
                    address1: '',
                    address2: '',
                    address3: '',
                    city: '',
                    state: '',
                    county: '',
                    zip: '',
                    country: ''
                },
                emailAddress: {
                    emailAddress: ''
                },
                phone1: '',
                phone1Type: ''
            },
            userProfession: {
                practiceSetting: '',
                practiceType: '',
                specialty: ''
            },
            isPhoneValid: false,
            selectedIndices: {
                phone1Type: 0,
                city: 0,
                country: 0,
                state: 0,
                practiceSetting: null,
                practiceType: null,
                specialty: null
            },
            hasFormChanged: false,
            emailVerificationCode: '',
            verificationCodeStatus: '',
            codeLimitReached: false,
            attemptLimitReached: false,
            filteredStates: [],
            citiesList: [],
            submitSuccess: false,
            submitMessage: '',
            formSubmitted: false,
            verificationCodeSent: false,
            shouldShowEmailChangedError: false,
            emailVerificationStarted: false,
            newCodeSent: false,
            isShowEmailDisclaimer: false,
            disclaimerEmail: '',
            isInfoNotificationVisible: true
        };
    },
    computed: {
        ...mapGetters('device', [ 'isMobileOnDesktop' ]),
        ...mapGetters('feature', [ 'isTwoFactorEmailVerification', 'featureValue' ]),
        ...mapGetters('app', [ 'isUccState' ]),
        ...mapGetters('profile', [
            'welcomeName'
        ]),
        ...mapGetters('account', [
            'myAccountContactInfo',
            'myAccountStates',
            'myAccountCountries',
            'myAccountPracticeSettingOptions',
            'myAccountSpecialtyOptions',
            'myAccountpracticeTypeOptions',
            'hasUserProfessions'
        ]),
        showErrorNotification() {
            return this.isUnexpectedError || this.isFormFieldError;
        },
        emailLimitReached() {
            return this.contactField('disableSendingEmail').name;
        },
        phoneOptions() {
            return {
                showError: true,
                showSuccess: false,
                serverError: this.serverErrors.phone1,
                isDisabled: !this.contactField('phone1').isEditable,
                formSubmitted: this.formSubmitted
            };
        },
        isDomestic() {
            return this.fields.address.country === 'USA';
        },
        isUsOrCanada() {
            return this.fields.address.country === 'USA'
                || this.fields.address.country === 'CAN';
        },
        showSubscriberNumber() {
            return !this.contactField('accountNumber').isHidden
                && this.contactFieldValue('accountNumber') !== '';
        },
        showSendCodeButton() {
            return this.isTwoFactorEmailVerification
                && this.verificationCodeStatus !== C_ACCOUNT.MESSAGE_TYPE.SUCCESS
                && this.fields.emailAddress.emailAddress !== this.oldEmailAddress;
        },
        showEmailCodeInput() {
            return this.emailVerificationStarted
                && this.verificationCodeStatus !== ''
                && this.isTwoFactorEmailVerification
                && this.fields.emailAddress.emailAddress !== this.oldEmailAddress;
        },
        showVerificationCodeError() {
            return (this.verificationCodeStatus === C_ACCOUNT.MESSAGE_TYPE.ERROR
                && !this.isFormValid)
                && this.isTwoFactorEmailVerification;
        },
        emailCodeInput() {
            return {
                inputSize: 'small',
                error: this.serverErrors.verificationCode,
                email: this.fields.emailAddress.emailAddress,
                emailLimitReached: this.emailLimitReached,
                codeLimitReached: this.codeLimitReached,
                attemptLimitReached: this.attemptLimitReached,
                formSubmitted: this.formSubmitted
            };
        },
        phoneInput() {
            return {
                inputSize: 'small',
                phoneValue: this.fields.phone1,
                phoneType: this.selectedIndices.phone1Type
            };
        },
        isSendCodeDisabled() {
            return this.isSubmitting
                || this.isUnexpectedError
                || this.emailValidator !== '';
        },
        isSubmitDisabled() {
            return this.isSubmitting
                || this.isUnexpectedError
                || this.shouldShowEmailChangedError
                || this.codeLimitReached
                || this.attemptLimitReached
                || !this.isFormValid;
        },
        cityValidator() {
            if (this.serverErrors.city) {
                return this.$t(this.serverErrors.city);
            }
            if (!this.fields.address.city.length > 0) {
                return this.$t('MYACCOUNT.EMPTY_FIELD');
            }
            const maxLength = this.contactField('city').maxLength || maxContactFieldLength;
            if (this.fields.address.city.length > maxLength) {
                return this.$t('MYACCOUNT.INVALID_FIELD_LENGTH', { maxLength });
            }
            if (!this.fields.address.city.match(validMailingAddressRegex)) {
                return this.$t('MYACCOUNT.INVALID_CITY');
            }
            return '';
        },
        zipCodeValidator() {
            if (this.serverErrors.zip) {
                return this.$t(this.serverErrors.zip);
            }
            if (!this.fields.address.zip.length > 0 && this.isUsOrCanada) {
                return this.$t('MYACCOUNT.EMPTY_FIELD');
            }
            const maxLength = this.contactField('zip').maxLength || maxContactFieldLength;
            if (this.fields.address.zip.length > maxLength) {
                return this.$t('MYACCOUNT.INVALID_FIELD_LENGTH', { maxLength });
            }
            if (!this.fields.address.zip.match(validZipCodeRegex)) {
                return this.$t('MYACCOUNT.INVALID_ZIPCODE');
            }
            return '';
        },
        addressValidator() {
            if (this.serverErrors.address1) {
                return this.$t(this.serverErrors.address1);
            }
            if (!this.fields.address.address1.length > 0) {
                return this.$t('MYACCOUNT.EMPTY_FIELD');
            }
            const maxLength = this.contactField('address1').maxLength || maxContactFieldLength;
            if (this.fields.address.address1.length > maxLength) {
                return this.$t('MYACCOUNT.INVALID_FIELD_LENGTH', { maxLength });
            }
            if (!this.fields.address.address1.match(validMailingAddressRegex)) {
                return this.$t('MYACCOUNT.INVALID_ADDRESS');
            }
            return '';
        },
        address2Validator() {
            if (this.serverErrors.address2) {
                return this.$t(this.serverErrors.address2);
            }
            const maxLength = this.contactField('address2').maxLength || maxContactFieldLength;
            if (this.fields.address.address2.length > maxLength) {
                return this.$t('MYACCOUNT.INVALID_FIELD_LENGTH', { maxLength });
            }
            if (!this.fields.address.address2.match(validMailingAddressRegex)) {
                return this.$t('MYACCOUNT.INVALID_ADDRESS');
            }
            return '';
        },
        address3Validator() {
            if (this.serverErrors.address3) {
                return this.$t(this.serverErrors.address3);
            }
            const maxLength = this.contactField('address2').maxLength || maxContactFieldLength;
            if (this.fields.address.address3.length > maxLength) {
                return this.$t('MYACCOUNT.INVALID_FIELD_LENGTH', { maxLength });
            }
            if (!this.fields.address.address3.match(validMailingAddressRegex)) {
                return this.$t('MYACCOUNT.INVALID_ADDRESS');
            }
            return '';
        },
        isEmailError() {
            return (!this.isEmailValid && this.isDirty.email) || this.shouldShowEmailChangedError;
        },
        emailValidator() {
            if (this.serverErrors.email) {
                return this.$t(this.serverErrors.email);
            }
            if (!this.fields.emailAddress.emailAddress.length > 0) {
                return this.$t('MYACCOUNT.EMPTY_FIELD');
            }
            if (this.fields.emailAddress.emailAddress.length > maxEmailLength) {
                return this.$t('MYACCOUNT.INVALID_FIELD_LENGTH', { maxLength: maxEmailLength });
            }
            if (!this.fields.emailAddress.emailAddress.match(emailRegex)) {
                return this.$t('MYACCOUNT.INVALID_EMAIL');
            }
            return '';
        },
        isAddressValid() {
            return this.addressValidator === '' && !this.serverErrors.address1;
        },
        isAddress2Valid() {
            return this.address2Validator === '' && !this.serverErrors.address2;
        },
        isAddress3Valid() {
            return this.address3Validator === '' && !this.serverErrors.address3;
        },
        isEmailValid() {
            return this.emailValidator === '' && !this.serverErrors.email;
        },
        isZipCodeValid() {
            return this.zipCodeValidator === ''
                && !this.serverErrors.zip;
        },
        isCityValid() {
            return this.cityValidator === '' && !this.serverErrors.city;
        },
        isStateValid() {
            return (this.fields.address.state !== ''
                || !this.isDomestic)
                && !this.serverErrors.state;
        },
        isPracticeSettingValid() {
            return this.isUserProfessionValid('practiceSetting');
        },
        ispracticeTypeValid() {
            return this.isUserProfessionValid('practiceType');
        },
        isSpecialtyValid() {
            return this.isUserProfessionValid('specialty');
        },
        isEmailCodeValid() {
            return this.emailVerificationCode !== ''
                && this.emailVerificationCode.match(numericRegex);
        },
        showCityDropdown() {
            return this.isDomestic
                && this.citiesList.length > 0
                && this.isZipCodeValid;
        },
        // eslint-disable-next-line complexity
        isFormValid() {
            return this.isAddressValid
                && this.isAddress2Valid
                && this.isAddress3Valid
                && this.isEmailValid
                && this.isZipCodeValid
                && (this.isPhoneValid && !this.serverErrors.phone1)
                && this.isCityValid
                && this.isStateValid
                && this.isPracticeSettingValid
                && this.ispracticeTypeValid
                && this.isSpecialtyValid;
        },
        isEmailVerificationValid() {
            return !this.isTwoFactorEmailVerification
                || this.fields.emailAddress.emailAddress === this.oldEmailAddress
                || (this.showEmailCodeInput && this.isEmailCodeValid);
        },
        displayUserProfessionNotification() {
            return this.hasUserProfessions
                && (this.userProfession.practiceSetting
                === C_ACCOUNT.FORM_MESSAGING.PRACTICE_SETTING_EMPTY_VALUE
                || this.userProfession.practiceType
                === C_ACCOUNT.FORM_MESSAGING.PRACTICE_SETTING_EMPTY_VALUE
                || this.userProfession.specialty
                === C_ACCOUNT.FORM_MESSAGING.PRACTICE_SETTING_EMPTY_VALUE)
                && !this.isEditMyProfileLink
                && this.isInfoNotificationVisible;
        }
    },
    watch: {
        fields: {
            handler(newVal) {
                if (this.isTwoFactorEmailVerification
                && this.verificationCodeSent === true
                && this.pendingEmailAddress !== newVal.emailAddress.emailAddress
                && this.oldEmailAddress !== newVal.emailAddress.emailAddress) {
                    this.shouldShowEmailChangedError = true;
                }
                else {
                    this.shouldShowEmailChangedError = false;
                }
            },
            deep: true
        },
        hasUserProfessions: {
            async handler(newVal) {
                if (newVal && !(await this.fetchMyAccountProfileOptions())) {
                    this.handleUnexpectedError('MYACCOUNT.CONTACT_INFORMATION_SETUP_ERROR', false);
                }
            },
            immediate: true
        }
    },
    async mounted() {
        if (!(await this.fetchCountriesStatesData())) {
            this.handleUnexpectedError('MYACCOUNT.CONTACT_INFORMATION_SETUP_ERROR', false);
        }
        this.setup();
    },
    methods: {
        ...mapMutations('account', [
            SET_CONTACT_INFO_FIELDS
        ]
        ),
        ...mapActions('account', [
            'fetchCountriesStatesData',
            'fetchMyAccountProfileOptions',
            'getMyAccountInfo'
        ]),
        setup() {
            this.filteredStates = this.myAccountStates;
            this.prefillContactFields();
            this.prefillUserProfession();
            this.setPreFilledFields();
            this.oldEmailAddress = this.contactFieldValue('email');
        },
        contactField(field) {
            return this.myAccountContactInfo[field];
        },
        contactFieldValue(field) {
            return this.myAccountContactInfo[field].value || '';
        },
        setFormChanged() {
            this.hasFormChanged = true;
            this.handleFormReset();
        },
        resetErrors() {
            this.isUnexpectedError = false;
            this.isFormFieldError = false;
            this.serverErrors = {
                address1: '',
                address2: '',
                address3: '',
                city: '',
                state: '',
                county: '',
                zip: '',
                country: '',
                email: '',
                phone1: '',
                practiceSetting: '',
                practiceType: '',
                specialty: '',
                verificationCode: ''
            };
        },
        handleEmailCodeInput({ emailCode }) {
            this.emailVerificationCode = emailCode;
            this.setFormChanged();
        },
        handleCountrySelect(value) {
            if (value < 0) {
                return;
            }
            if (this.fields.address.country !== this.myAccountCountries[value].value) {
                this.fields.address.zip = '';
                this.fields.address.city = '';
                this.fields.address.state = '';
                this.selectedIndices.city = -1;
                this.selectedIndices.state = 0;
            }
            if (this.isUsOrCanada) {
                this.filteredStates = this.myAccountStates;
            }
            this.fields.address.country = this.myAccountCountries[value].value;
            this.selectedIndices.country = value;
            this.handleInputBlur('zip');
        },
        handleCitySelect(value) {
            if (value < 0) {
                return;
            }
            this.fields.address.city = this.citiesList[value].label;
            this.selectedIndices.city = value;
            this.handleInputBlur('city');
        },
        handleStateSelect(value) {
            if (value < 0) {
                return;
            }
            this.fields.address.state = this.myAccountStates[value].value;
            this.selectedIndices.state = value;
            this.handleInputBlur('state');
        },
        handleUserProfessionsSelect(key, value, list) {
            if (value < 0) {
                return;
            }
            this.userProfession[key] = list[value].value;
            this.selectedIndices[key] = value;
            this.setFormChanged();
        },
        prefillContactFields() {
            this.fields = {
                address: {
                    address1: this.contactFieldValue('address1'),
                    address2: this.contactFieldValue('address2'),
                    address3: this.contactFieldValue('address3'),
                    city: this.contactFieldValue('city'),
                    state: this.contactFieldValue('state'),
                    county: this.contactFieldValue('county'),
                    zip: this.contactFieldValue('zip'),
                    country: this.contactFieldValue('country')
                },
                emailAddress: {
                    emailAddress: this.contactFieldValue('email')
                },
                phone1: this.contactFieldValue('phone1')
            };
        },
        prefillUserProfession() {
            this.userProfession = {
                practiceSetting: this.contactFieldValue('practiceSetting'),
                practiceType: this.contactFieldValue('practiceType'),
                specialty: this.contactFieldValue('specialty')
            };
        },
        setPrefilledPhone1Type() {
            let phone1Type = this.contactFieldValue('phone1Type');
            if (!phone1Type && !this.contactFieldValue('phone2')) {
                phone1Type = this.contactFieldValue('phone2Type');
            }
            this.fields.phone1Type = phone1Type || 'Work';
        },
        setPrefilledCountry() {
            if (this.fields.address.country) {
                for (let i = 0; i < this.myAccountCountries.length; i++) {
                    const country = this.myAccountCountries[i];
                    if (country.value === this.fields.address.country) {
                        this.selectedIndices.country = i;
                        break;
                    }
                }
            }
        },
        setPrefilledState() {
            if (this.fields.address.state) {
                for (let i = 0; i < this.myAccountStates.length; i++) {
                    const state = this.myAccountStates[i];
                    if (state.value === this.fields.address.state) {
                        this.selectedIndices.state = i;
                        break;
                    }
                }
            }
        },
        setPrefilledCounty() {
            if (!this.isDomestic) {
                return;
            }
            if (!this.submitSuccess && !this.fields.address.county && this.fields.address.zip) {
                this.fetchZipCodeData();
            }
        },
        setPrefilledCity() {
            if (!this.fields.address.city) {
                return;
            }
            this.citiesList = [{
                label: this.fields.address.city,
                value: 0
            }];
        },
        setPrefilledPracticeSetting() {
            this.setPrefilledUserProfession('practiceSetting',
                this.myAccountPracticeSettingOptions);
        },
        setPrefilledpracticeType() {
            this.setPrefilledUserProfession('practiceType',
                this.myAccountpracticeTypeOptions);
        },
        setPrefilledSpecialty() {
            this.setPrefilledUserProfession('specialty',
                this.myAccountSpecialtyOptions);
        },
        setPrefilledUserProfession(label, list) {
            if (this.userProfession[label]) {
                for (let i = 0; i < list.length; i++) {
                    const option = list[i];
                    if (option.value === this.userProfession[label]) {
                        this.selectedIndices[label] = i;
                        break;
                    }
                }
            }
        },
        setPreFilledFields() {
            this.setPrefilledCountry();
            this.setPrefilledState();
            this.setPrefilledCounty();
            this.setPrefilledCity();
            this.setPrefilledPhone1Type();
            this.setPrefilledPracticeSetting();
            this.setPrefilledpracticeType();
            this.setPrefilledSpecialty();
            if (this.fields.phone1Type) {
                this.selectedIndices.phone1Type = C_MY_ACCOUNT.PHONE_TYPES
                    .indexOf(this.fields.phone1Type.toLowerCase());
            }
            if (this.fields.phone1) {
                this.setDirtyField('phone1');
                this.isPhoneValid = true;
            }
            if (this.fields.address.address3) {
                this.hideAddress3 = false;
            }
        },
        addAddressLine() {
            this.hideAddress3 = !this.hideAddress3;
        },
        handlePhoneInput(value) {
            this.fields.phone1 = value;
            this.handleInputBlur('phone1');
            this.setFormChanged();
        },
        handlePhoneBlur(isValid) {
            this.isPhoneValid = isValid;
        },
        handlePhoneType(value) {
            this.fields.phone1Type = value;
            this.setFormChanged();
        },
        handleInputBlur(field) {
            this.setDirtyField(field);
            this.setServerError(field, '');
            this.setFormChanged();
        },
        setDirtyField(field) {
            this.isDirty[field] = true;
        },
        setAllFieldsDirty(verificationCode = false) {
            this.isDirty = {
                address: true,
                city: true,
                zip: true,
                email: true,
                phone1: true
            };
            if (verificationCode) {
                this.verificationCodeSent = true;
            }
            else {
                this.formSubmitted = true;
            }
        },
        setServerError(field, error) {
            this.serverErrors[field] = error;
        },
        open() {
            this.$refs.myAccountDialogRef.open();
        },
        async handlePracticeSettingScroll() {
            await this.$nextTick();
            const practiceSettingElem = getDocument().querySelector('.practiceSettingSelect');
            if (practiceSettingElem) {
                practiceSettingElem.scrollIntoView();
            }
        },
        handleFormReset() {
            if (!this.canResetForm) {
                return;
            }
            this.submitSuccess = false;
            this.submitMessage = '';
            this.resetErrors();
        },
        closeDialog() {
            this.$refs.myAccountDialogRef.close();
            this.clearErrors();
            this.handleFormReset();
            new PubSub().publish('wkutd.closeMyAccountDialog');
        },
        handleClose() {
            this.clearErrors();
            this.handleFormReset();
            new PubSub().publish('wkutd.closeMyAccountDialog');
        },
        clearErrors() {
            if (this.formErrorMessage === 'MYACCOUNT.CONTACT_INFORMATION_SETUP_ERROR') {
                return;
            }
            this.canResetForm = true;
            this.hasFormChanged = false;
        },
        setZipCodeError() {
            if (!this.isDomestic) {
                return;
            }
            this.setServerError('zip', this.$t('MYACCOUNT.INVALID_ZIPCODE'));
        },
        fetchContactForm() {
            this.getMyAccountInfo();
            this.setup();
        },
        handleInvalidEmailValidation(emailStatus) {
            this.serverErrors.verificationCode = emailStatus.errorMessage;
            this.codeLimitReached = !emailStatus.isAllowOtcEntry;
            this.attemptLimitReached = !emailStatus.isAllowOtcEntry
                && !emailStatus.isAllowOtcGeneration;
        },
        handleSubmitSuccess() {
            this.verificationCodeStatus = '';
            this.codeLimitReached = false;
            this.attemptLimitReached = false;
            this.formSubmitted = false;
            this.hasFormChanged = false;
            this.emailVerificationStarted = false;
            this.oldEmailAddress = this.fields.emailAddress.emailAddress;
            this.pendingEmailAddress = '';
            this.verificationCodeSent = false;
        },
        async handleContactFormResponse(res) {
            if (!res.isSuccess) {
                this.handleServerErrors(res);
                return false;
            }
            return true;
        },
        async fetchZipCodeData() {
            if (this.formErrorMessage === 'MYACCOUNT.CONTACT_INFORMATION_SETUP_ERROR') {
                return;
            }
            this.handleInputBlur('zip');
            const userZipCode = this.fields.address.zip;
            if (!userZipCode || !this.isDomestic) {
                this.canResetForm = true;
                this.isUnexpectedError = false;
                return;
            }
            const zipCodeUrl = `/services/ZipCodeCities?zipcode=${userZipCode}`;
            try {
                const zipData = await UtdRestClient.getCustom(zipCodeUrl);
                const data = JSON.parse(zipData.data);

                if (data.STATUS === FETCH_SUCCESS) {
                    const citiesCounties = data.FIPSZIP.cities;
                    this.citiesList = citiesCounties.map((city, idx) => {
                        return {
                            label: city.name,
                            value: idx
                        };
                    });
                    const countiesList = citiesCounties.map(city => city.counties);

                    const selectedState = countiesList[0][0].state;
                    this.filteredStates = this.myAccountStates.filter(
                        state => state.value === selectedState);
                    this.fields.address.city = this.citiesList[0].label;
                    this.fields.address.state = this.filteredStates[0].value;
                    this.fields.address.county = countiesList[0][0].fips;
                    this.selectedIndices.state = 0;
                    this.selectedIndices.city = 0;
                    this.setServerError('zip', '');
                }
                else {
                    this.setZipCodeError();
                }
            }
            catch {
                this.handleUnexpectedError(
                    'MYACCOUNT.VALIDATE_INFORMATION_UNEXPECTED_ERROR',
                    false,
                    true);
            }
        },
        async submitForm() {
            this.isInfoNotificationVisible = false;
            this.setAllFieldsDirty();
            const res = await this.submitContactInfo();
            if (!res) {
                return;
            }
            const emailResponse = res.emailVerificationResponse;
            const contactInfoResponse = res.contactInfoResponse;
            if (emailResponse) {
                this.validateEmailCode(emailResponse);
            }
            this.handleFormScroll();
            if (contactInfoResponse) {
                this.submitSuccess = contactInfoResponse.isSuccess;
                this.submitMessage = contactInfoResponse.statusMessage;
                await this.handleContactFormResponse(contactInfoResponse);
            }
            if (this.submitSuccess) {
                this[SET_CONTACT_INFO_FIELDS](contactInfoResponse.formFields);
                this.handleSubmitSuccess();
                this.resetErrors();
            }
            else {
                this.isFormFieldError = true;
                this.formErrorMessage = 'ERRORS.SUBMISSION_GENERAL_ERROR';
            }
        },
        async toggleEmailDisclaimerForSsoEmail() {
            if (!this.isSso || !this.isEmailValid) {
                this.handleInputBlur('email');
                return;
            }

            try {
                const data = await utdRest('wayf/institutions/disclaimer-email', {
                    emailDomain: this.getContactEmailDomain()
                });
                if (data && data.data && data.data.trim() !== '') {
                    this.disclaimerEmail = data.data;
                    this.isShowEmailDisclaimer = true;
                }
                else {
                    this.disclaimerEmail = '';
                    this.isShowEmailDisclaimer = false;
                }
            }
            catch {
                // do nothing
            }
            finally {
                this.handleInputBlur('email');
            }
        },
        getContactEmailDomain() {
            const email = this.fields.emailAddress.emailAddress;
            let emailDomain = '';
            const domainIndex = email.indexOf('@');
            if (domainIndex >= 0) {
                emailDomain = email.substring(domainIndex + 1);
            }
            return emailDomain;
        },
        async submitContactInfo() {
            await this.$nextTick();
            if (!this.isFormValid) {
                return;
            }
            if (!this.isEmailVerificationValid) {
                return;
            }
            try {
                this.isFormFieldError = false;
                this.isUnexpectedError = false;
                this.isSubmitting = true;
                const data = await utdRest('profile/submitContact', {
                    contactInfo: this.fields,
                    code: this.emailVerificationCode,
                    userProfession: this.userProfession
                });
                const res = data.data || data;
                this.isSubmitting = false;
                return res;
            }
            catch {
                this.handleUnexpectedError(
                    'MYACCOUNT.UPDATE_INFORMATION_UNEXPECTED_ERROR',
                    true,
                    true);
            }
        },
        async sendVerificationCode() {
            this.setAllFieldsDirty(true);
            if (this.isSendCodeDisabled) {
                return;
            }
            try {
                this.isFormFieldError = false;
                this.isUnexpectedError = false;
                const data = await utdRest('myAccount/sendVerificationCode', {
                    contactInfo: this.fields,
                    userProfession: this.userProfession
                });
                const res = data.data || data;
                const isFormValid = await this.handleContactFormResponse(res);
                if (isFormValid) {
                    if (this.emailVerificationStarted) {
                        this.newCodeSent = true;
                    }
                    this.shouldShowEmailChangedError = false;
                    this.verificationCodeStatus = C_ACCOUNT.MESSAGE_TYPE.SUCCESS;
                    this.emailVerificationStarted = true;
                    this.pendingEmailAddress = this.fields.emailAddress.emailAddress;
                }
                else {
                    this.verificationCodeStatus = C_ACCOUNT.MESSAGE_TYPE.ERROR;
                    this.newCodeSent = false;
                }
                this[SET_CONTACT_INFO_FIELDS](res.formFields);
                this.codeLimitReached = false;
                this.setServerError('verificationCode', '');
            }
            catch {
                this.submitSuccess = false;
                this.isUnexpectedError = true;
                this.verificationCodeStatus = C_ACCOUNT.MESSAGE_TYPE.ERROR;
            }
        },
        async validateEmailCode(emailStatus) {
            if (!emailStatus) {
                return;
            }
            this.handleInvalidEmailValidation(emailStatus);
            return emailStatus.isValid;
        },
        handleFormScroll() {
            const dialogContainer = getWindow().document.querySelector('.utd-dialog-content');
            if (dialogContainer !== null) {
                dialogContainer.scrollTop = 0;
            }
        },
        handleServerErrors(contactInfoResponse) {
            if (Array.isArray(contactInfoResponse.formFields)) {
                contactInfoResponse.formFields.forEach(field => {
                    if (field.errorMessage && field.name) {
                        this.setServerError(field.name, field.errorMessage);
                    }
                });
            }
        },
        handleUnexpectedError(message, canResetForm, isHandleScroll = false) {
            this.canResetForm = canResetForm;
            this.formErrorMessage = message;
            this.isUnexpectedError = true;
            this.submitSuccess = false;
            this.newCodeSent = false;
            this.isSubmitting = false;
            if (isHandleScroll) {
                this.handleFormScroll();
            }
        },
        close(options = { doEmit: true }) {
            this.isInfoNotificationVisible = false;
            if (options.doEmit) {
                this.$emit('closed');
            }
        },
        isUserProfessionValid(label) {
            if (!this.hasUserProfessions) {
                return true;
            }
            return this.userProfession[label] !== ''
            && this.userProfession[label]
            !== C_ACCOUNT.FORM_MESSAGING.PRACTICE_SETTING_EMPTY_VALUE
            && !this.serverErrors[label];
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-UTD-DIALOG-OUTLINE-COLOR: @WKCE-WHITE;

.contact-information-modal {
  .utd-modal-content {
    .utd-dialog-frame {
      .ds1-mb-0();
      min-width: 0;
      max-width: 390px;
    }

    .utd-dialog-content {
      .ds1-pa-2();
    }

    .utd-dialog-footer .utd-button {
      .ds1-ml-2();
    }
  }
}

#emailDisclaimer, #practiceSettingDisclaimer {
  .email-notification-content, .fields-notification-content {
    padding-left: 20px;
  }

  #emailDisclaimerMessage {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .utd-notification-title {
    font-weight: 600;
  }

  b {
    font-weight: 600;
  }
}
.dialog__user-profession-container {
    padding-left: 12px;
    padding-right: 12px;
    background-color: @WKCE-BLUE-TINT6;

    .specialtySelect {
        .ds1-mb-0();
    }
}

@media screen and (min-width: 768px) {
  .contact-information-modal {
    .utd-modal-content {
      width: 390px;
    }
  }
}
</style>


<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.dialog__add-address-line {
  .ds1-mb-2();
  display: flex;
  align-items: center;
}

.dialog__add-address-line-btn {
  .ds1-utd-weight-600();
  .ds1-pa-0();
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  .wkce-icon-minus-circle,
  .wkce-icon-plus-circle {
    .ds1-utd-size-3();
    margin-right: 6px;
  }
}

.dialog__profile-info {
  .ds1-utd-size-2();
  overflow-wrap: break-word;
}

.dialog__email-verification-note {
  .ds1-utd-size-2();
}

.dialog__email-changed-error {
  .ds1-utd-size-2();
  color: @DS1-UTD-ERROR;
}

.dialog__verification-code-error {
  .ds1-utd-size-2();
  color: @DS1-UTD-ERROR;
}

.my-account-email-input {
  .ds1-mb-1();
}

.dialog__contact-info-container {
    padding: 12px;
}

.contactNotificationStyle {
    .ds1-mt-0();
    .ds1-mb-1();
}

.practiceSettingSelect,
.practiceTypeSelect,
.specialtySelect {
    :deep(select) {
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>