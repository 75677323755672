<template>
  <div class="topic-feedback" v-bind="$attrs">
    <utd-feedback-dialog ref="feedbackDialog"
                         :feedback-title="$t(feedbackText)"
                         :feedback-url="feedbackUrl" />

    <a class="user-feedback-link"
       @click.prevent="openFeedback()">
      <span class="wkce-icon-chat-box" />
      <span>{{ $t(feedbackText) }}</span>
    </a>
  </div>
</template>

<script>
import UtdFeedbackDialog from '_acaSrc/components/shared/utd/UtdFeedbackDialog.vue';

export default {
    components: {
        UtdFeedbackDialog
    },
    props: {
        feedbackUrl: {
            type: String
        },
        isForCalculator: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        feedbackText() {
            return this.isForCalculator ? 'CONTENT.CALCULATOR_FEEDBACK' : 'CONTENT.TOPIC_FEEDBACK';
        }
    },
    methods: {
        openFeedback() {
            this.$refs.feedbackDialog.openFeedbackForm();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.topic-feedback {
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  box-sizing: border-box;

  &:hover {
    background-color: @DS1-FLYOUT-TOGGLE-HOVER-BGCLR;
  }

  .user-feedback-link {
    padding: 6px 0;
    display: block;

    &:hover {
      text-decoration: none;
    }

    .wkce-icon-chat-box {
      font-size: 18px;
      display: block;

      &::before {
        width: 18px;
        height: 16px;
      }
    }
  }

  &.expanded {
    .user-feedback-link {
      padding: 10px 8px 6px;
      line-height: 24px;
      text-align: center;

      .wkce-icon-chat-box {
        display: inline-block;
      }
    }
  }
}
</style>
