/**
 * Directive which performs a stepped bi-linear scaling of an <img> tag, only for IE10+ browsers.
 * Idea obtained from combination of:
 * 		https://stackoverflow.com/a/17862644
 * 		https://codepen.io/greypants/pen/bmyKo/
 *
 * Used in template as:
 * <img width="284" gfxsrc="{{::subhit.thumbnailUrl}}" v-utd-canvas-scaler />
 */
import store from '_acaSrc/store';
const STEP_SCALE = 0.5;

export default {
    mounted: (el) => {
        const srcUrl = el.src;
        el.setAttribute('src', srcUrl);

        if (store.getters['device/supportsCanvas']) {
            const tgtCnv = document.createElement('canvas'),
                tgtCtx = tgtCnv.getContext('2d');

            const tgtWdt = el.width;
            const tgtHgt = el.height;

            const srcImg = new Image();
            srcImg.onload = () => {
                applyScale(el, tgtWdt, tgtHgt, tgtCnv, tgtCtx, srcImg);
            };
            srcImg.src = srcUrl;
        }
    }
};

function stepDown(image) {
    const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

    canvas.width = (image.width * STEP_SCALE) + 1;
    canvas.height = (image.height * STEP_SCALE) + 1;

    ctx.scale(STEP_SCALE, STEP_SCALE);
    ctx.drawImage(image, 0, 0);

    return canvas;
}

function scaleDown(image, tgtWdt) {
    while (image.width * STEP_SCALE > tgtWdt) {
        image = stepDown(image);
    }
    return image;
}

function applyScale(el, tgtWdt, tgtHgt, tgtCnv, tgtCtx, srcImg) {
    // Ensure aspect ratio is maintained
    if (typeof tgtHgt === 'undefined' || tgtHgt === null) {
        tgtHgt = (tgtWdt / srcImg.naturalWidth) * srcImg.naturalHeight;
    }
    if (typeof tgtWdt === 'undefined' || tgtWdt === null) {
        tgtWdt = (tgtHgt / srcImg.naturalHeight) * srcImg.naturalWidth;
    }

    // Set target canvas dimensions
    tgtCnv.width = tgtWdt;
    tgtCnv.height = tgtHgt;

    // Perform image scaling
    const scaledData = scaleDown(srcImg, tgtWdt);

    // Draw scaled image into target canvas
    tgtCtx.drawImage(scaledData, 0, 0, tgtWdt, tgtHgt);

    // Remove onload handler, then replace <img> tag src with new canvas data
    srcImg.onload = null;
    el.width = tgtWdt;
    el.height = tgtHgt;
    el.src = tgtCnv.toDataURL();
}