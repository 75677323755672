import cmeRest from '_acaSrc/cme/utility/CmeRestHooks';
import { getObjectCopy } from '_acaSrc/utility/Object';
import { debounce } from '_acaSrc/utility/timers';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import {
    C_EVENTS,
    C_RESPONSIVE,
    C_MOC_UI_LINKS,
    C_STATE_CME_UI_LINKS,
    C_MOC
} from '_acaSrc/utility/constants';
import {
    validateAuthorities,
    sortAuthorities,
    addTypeToUserAuthorities,
    hasActiveBoards
} from '_acaSrc/cme/utility/cme';
import PubSub from '_acaSrc/utility/PubSub';
import Logger from '_acaSrc/utility/Logger';

const AUTOSAVE_REFLECTIONS_DEBOUNCE_MS = 2000;

export const state = {
    activeTrack: null,
    activeTrackInfo: null,
    sortedTracks: null,
    tracks: null,
    trackBundles: null,
    tracksMap: {},
    trackBundlesMap: {},
    isFirstTimeCmeUser: false,
    moc: {
        detailLogs: [],
        hasBoards: false,
        notifyOnSuccess: false,
        boardUrls: {},
        urls: C_MOC_UI_LINKS,
        authorities: [],
        userAuthorities: []
    },
    stateCme: {
        authorities: [],
        userAuthorities: [],
        hasBoards: false,
        urls: C_STATE_CME_UI_LINKS
    },
    mocHistory: {},
    submissionsToConfirm: [],
    creditsToRedeem: [],
    debounceReflections: {},
    reflectedAccrual: {},
    cmeLabel: '',
    creditTitle: '',
    tickerValue: -1,
    showTicker: false,
    redemptionId: null,
    hasCmeAutoSubmitFeature: false,
    trackCme: false,
    inlineCmeEligible: false,
    lastCmeContentId: '',
    cmeDelayedHistoryRefresh: false
};

export const getters = {
    activeTrack: state => state.activeTrack,
    activeTrackInfo: state => state.activeTrackInfo,
    cmeSortedTracks: state => state.sortedTracks,
    cmeTracks: state => state.tracks,
    cmeTrackBundles: state => state.trackBundles,
    cmeTracksMap: state => state.tracksMap,
    cmeTrackBundlesMap: state => state.trackBundlesMap,
    isFirstTimeCmeUser: state => state.isFirstTimeCmeUser,
    detailsLogs: state => state.moc.detailLogs,
    mocHistory: state => state.mocHistory,
    submissionsToConfirm: state => state.submissionsToConfirm,
    debounceReflections: state => state.debounceReflections,
    creditsToRedeem: state => state.creditsToRedeem,
    reflectedAccrual: state => state.reflectedAccrual,
    cmeLabel: state => state.cmeLabel,
    creditTitle: state => state.creditTitle,
    showTicker: state => state.showTicker,
    tickerValue: state => state.tickerValue,
    redemptionId: state => state.redemptionId,
    cmeTickerId: (state, getters, rootState, rootGetters) => qualifier => {
        return `cmeTicker${
            rootGetters['device/isDesktopView'] ? 'Desktop' : 'Mobile'
        }${typeof qualifier !== 'undefined' ? qualifier : ''}`;
    },
    hasCmeAutoSubmitFeature: state => state.hasCmeAutoSubmitFeature,
    isTrackCme: state => state.trackCme,
    isInlineCmeEligible: state => state.inlineCmeEligible,
    lastCmeContentId: state => state.lastCmeContentId,
    mocHasBoards: state => state.moc.hasBoards,
    mocAuthorities: state => state.moc.authorities,
    stateCmeAuthorities: state => state.stateCme.authorities,
    mocUserAuthorities: state => state.moc.userAuthorities,
    stateCmeUserAuthorities: state => state.stateCme.userAuthorities,
    stateCmeHasBoards: state => state.stateCme.hasBoards,
    mocBoard: state => state.moc,
    boardByType: state => settingKey => {
        return state[settingKey];
    },
    cmeDelayedHistoryRefresh: state => state.cmeDelayedHistoryRefresh
};

export const SET_ACTIVE_TRACK = 'SET_ACTIVE_TRACK';
export const SET_ACTIVE_TRACK_INFO = 'SET_ACTIVE_TRACK_INFO';
export const SET_SELECTED_TRACK = 'SET_SELECTED_TRACK';
export const SET_SORTED_TRACKS = 'SET_SORTED_TRACKS';
export const SET_TRACKS = 'SET_TRACKS';
export const SET_TRACK_BUNDLES = 'SET_TRACK_BUNDLES';
export const SET_TRACKS_MAP = 'SET_TRACKS_MAP';
export const SET_TRACK_BUNDLES_MAP = 'SET_TRACK_BUNDLES_MAP';
export const SET_IS_FIRST_TIME_CME_USER = 'SET_IS_FIRST_TIME_CME_USER';
export const SET_DETAIL_LOGS = 'SET_DETAIL_LOGS';
export const SET_MOC_HISTORY = 'SET_MOC_HISTORY';
export const SET_SUBMISSIONS_TO_CONFIRM = 'SET_SUBMISSIONS_TO_CONFIRM';
export const RESET_MOC_HISTORY = 'RESET_MOC_HISTORY';
export const RESET_SUBMISSIONS_TO_CONFIRM = 'RESET_SUBMISSIONS_TO_CONFIRM';
export const SET_CREDITS_TO_REDEEM = 'SET_CREDITS_TO_REDEEM';
export const RESET_CREDITS_TO_REDEEM = 'RESET_CREDITS_TO_REDEEM';
export const SET_DEBOUNCE_REFLECTIONS = 'SET_DEBOUNCE_REFLECTIONS';
export const SET_REFLECTED_ACCRUAL = 'SET_REFLECTED_ACCRUAL';
export const REMOVE_DEBOUNCE_REFLECTION = 'REMOVE_DEBOUNCE_REFLECTION';
export const SET_CME_LABEL = 'SET_CME_LABEL';
export const SET_CME_TICKER = 'SET_CME_TICKER';
export const SET_REDEMPTION_ID = 'SET_REDEMPTION_ID';
export const SET_CME_AUTO_SUBMIT = 'SET_CME_AUTO_SUBMIT';
export const SET_IS_TRACK_CME = 'SET_IS_TRACK_CME';
export const SET_IS_INLINE_CME_ELIGIBLE = 'SET_IS_INLINE_CME_ELIGIBLE';
export const SET_LAST_CME_CONTENT_ID = 'SET_LAST_CME_CONTENT_ID';
export const SYNCHRONIZE_AUTHORITY_DATA = 'SYNCHRONIZE_AUTHORITY_DATA';
export const SET_MOC_BOARD_URLS = 'SET_MOC_BOARD_URLS';
export const SEGMENT_AUTHORITIES_BY_TYPE = 'SEGMENT_AUTHORITIES_BY_TYPE';
export const ADD_AUTHORITY_DETAIL = 'ADD_AUTHORITY_DETAIL';
export const SET_MOC_NOTIFY_ON_SUCCESS = 'SET_MOC_NOTIFY_ON_SUCCESS';
export const SET_MOC_HAS_BOARDS = 'SET_MOC_HAS_BOARDS';
export const SET_MOC_DATE_OF_BIRTH = 'SET_MOC_DATE_OF_BIRTH';
export const SET_MOC_EMAIL = 'SET_MOC_EMAIL';
export const SET_STATE_CME_HAS_BOARDS = 'SET_STATE_CME_HAS_BOARDS';
export const SET_CME_DELAYED_HISTORY_REFRESH = 'SET_CME_DELAYED_HISTORY_REFRESH';

export const mutations = {
    [SET_ACTIVE_TRACK]: (state, activeTrack) => state.activeTrack = activeTrack,
    [SET_ACTIVE_TRACK_INFO]: (state, activeTrackInfo) => state.activeTrackInfo = activeTrackInfo,
    [SET_SELECTED_TRACK]: (state, trackIndex) => {
        if (state.sortedTracks) {
            state.sortedTracks[trackIndex].selected = true;
        }
    },
    [SET_SORTED_TRACKS]: (state, sortedTracks) => state.sortedTracks = sortedTracks,
    [SET_TRACKS]: (state, tracks) => state.tracks = tracks,
    [SET_TRACK_BUNDLES]: (state, trackBundles) => state.trackBundles = trackBundles,
    [SET_TRACKS_MAP]: (state, payload) => {
        const { trackCode, cmeTrack } = payload;
        state.tracksMap[trackCode] = cmeTrack;
    },
    [SET_TRACK_BUNDLES_MAP]: (state, payload) => {
        const { trackCode, trackBundle } = payload;
        state.trackBundlesMap[trackCode] = trackBundle;
    },
    [SET_IS_FIRST_TIME_CME_USER]: (state, isFirstTimeCmeUser) => {
        state.isFirstTimeCmeUser = isFirstTimeCmeUser;
    },
    [SET_DETAIL_LOGS]: (state, payload) => {
        state.moc.detailLogs[payload.detailIndex] = payload.detailsLog;
    },
    [SET_MOC_HISTORY]: (state, payload) => {
        const { authCode, result } = payload;
        state.mocHistory[authCode] = result;
    },
    [SET_SUBMISSIONS_TO_CONFIRM]: (state, submissions) => {
        state.submissionsToConfirm = submissions;
    },
    [RESET_MOC_HISTORY]: state => {
        state.mocHistory = {};
    },
    [RESET_SUBMISSIONS_TO_CONFIRM]: state => {
        state.submissionsToConfirm = [];
    },
    [SET_DEBOUNCE_REFLECTIONS]: (state, payload) => {
        const { accrualId, cb } = payload;
        state.debounceReflections[accrualId] = cb;
    },
    [REMOVE_DEBOUNCE_REFLECTION]: (state, accrualId) => {
        delete state.debounceReflections[accrualId];
    },
    [SET_CREDITS_TO_REDEEM]: (state, credits) => {
        state.creditsToRedeem = credits;
    },
    [RESET_CREDITS_TO_REDEEM]: state => {
        state.creditsToRedeem = [];
    },
    [SET_REFLECTED_ACCRUAL]: (state, accrual) => {
        state.reflectedAccrual = accrual;
    },
    [SET_CME_LABEL]: (state, label) => {
        state.cmeLabel = label;
        // set full credit title based on label
        state.creditTitle
            = label === 'CPD' ? 'Continuing Professional Development (CPD)'
                : label === 'CE' ? 'Continuing Education (CE)'
                    : label === 'CME' ? 'Continuing Medical Education (CME)' : '';
    },
    [SET_CME_TICKER]: (state, cmeTickerValue) => {
        state.tickerValue = cmeTickerValue;
        state.showTicker = true;
        if (cmeTickerValue < 0) {
            state.tickerValue = '';
        }
    },
    [SET_REDEMPTION_ID]: (state, value) => {
        state.redemptionId = value;
    },
    [SET_IS_TRACK_CME]: (state, value) => state.trackCme = value,
    [SET_IS_INLINE_CME_ELIGIBLE]: (state, value) => state.inlineCmeEligible = value,
    [SET_LAST_CME_CONTENT_ID]: (state, value) => state.lastCmeContentId = value,
    [SET_CME_AUTO_SUBMIT]: (state, data) => {
        state.hasCmeAutoSubmitFeature = false;
        if ('isCmeAutoSubmit' in data && !!data.isCmeAutoSubmit) {
            state.hasCmeAutoSubmitFeature = true;
        }
    },
    [SYNCHRONIZE_AUTHORITY_DATA]: () => {
        const buckets = [ state.moc, state.stateCme ];
        buckets.forEach(bucket => {
            if (bucket.userAuthorities && bucket.authorities) {
                bucket.userAuthorities = bucket.userAuthorities.map(userAuth => {
                    const authority = bucket.authorities.find(auth => {
                        return auth.authorityCode === userAuth.mocAuthorityCode;
                    }) || {};
                    return { ...userAuth, ...authority };
                });
            }
        });
    },
    [SET_MOC_BOARD_URLS]: (state, data) => {
        state.moc.boardUrls = data && data.mocBoardUrls || {};
    },
    [SET_MOC_NOTIFY_ON_SUCCESS]: (state, value) => {
        state.moc.notifyOnSuccess = value;
    },
    [SET_MOC_HAS_BOARDS]: (state, value) => {
        state.moc.hasBoards = value;
    },
    [SET_MOC_DATE_OF_BIRTH]: (state, value) => {
        state.moc.dateOfBirth = value;
    },
    [SET_MOC_EMAIL]: (state, value) => {
        state.moc.email = value;
    },
    [SET_STATE_CME_HAS_BOARDS]: (state, value) => {
        state.stateCme.hasBoards = value;
    },
    [SET_CME_DELAYED_HISTORY_REFRESH]: (state, value) => {
        state.cmeDelayedHistoryRefresh = value;
    },
    [SEGMENT_AUTHORITIES_BY_TYPE]: (state, { authorities, authoritiesKey }) => {
        state.moc[authoritiesKey] = [];
        state.stateCme[authoritiesKey] = [];
        return authorities && authorities.forEach(auth => {
            if (auth.type === C_MOC.MOC_AUTHORITY_TYPE_STATE_CME) {
                state.stateCme[authoritiesKey].push(auth);
            }
            else {
                state.moc[authoritiesKey].push(auth);
            }
        });
    },
    [ADD_AUTHORITY_DETAIL]: (state, authorities) => {
        authorities.forEach(board => {
            board.url = state.moc.boardUrls && state.moc.boardUrls[board.authorityCode];
            board.descriptionAndCode = `${board.description} (${board.authorityCode})`;
        });
    }
};

export const actions = {
    /*
     * Retrieves eligible MOC credits for added authorities
     */
    async getAllEligibleCredits({ getters }, settingsKey = 'moc') {
        return await cmeRest('cme/moc/getEligibleMocCredits').then(data => {

            const settings = getters.boardByType(settingsKey);

            // Filter out unwanted eligible credits from other types of boards.
            data && data.forEach && data.forEach(year => {
                year.eligibleCredits = year.eligibleCredits.filter(board => {
                    return settings.authorities.find(a => {
                        return a.authorityCode === board.authorityCode;
                    });
                });
            });
            return data ? data.filter(year => year.eligibleCredits.length > 0) : data;
        });
    },
    async setMocAuthorityData({ commit, getters }, authData) {
        if (!(validateAuthorities(authData.authorities)
        && validateAuthorities(authData.userAuthorities, 'mocAuthorityCode'))) {
            return;
        }

        const authorities = sortAuthorities(authData.authorities);
        const userAuthorities = sortAuthorities(authData.userAuthorities, 'mocAuthorityCode');

        addTypeToUserAuthorities(authorities, userAuthorities);

        commit(SEGMENT_AUTHORITIES_BY_TYPE, { authorities, authoritiesKey: 'authorities' });
        commit(SEGMENT_AUTHORITIES_BY_TYPE,
            { authorities: userAuthorities, authoritiesKey: 'userAuthorities' });

        commit(SET_MOC_NOTIFY_ON_SUCCESS, authData.notifyOnSuccess);
        commit(SET_MOC_HAS_BOARDS, hasActiveBoards(getters.mocUserAuthorities));
        commit(SET_STATE_CME_HAS_BOARDS, hasActiveBoards(getters.stateCmeUserAuthorities));
        commit(ADD_AUTHORITY_DETAIL, getters.mocAuthorities);
        commit(ADD_AUTHORITY_DETAIL, getters.stateCmeAuthorities);

        commit(SET_MOC_DATE_OF_BIRTH, authData.dob);
        commit(SET_MOC_EMAIL, authData.email);

        commit(SYNCHRONIZE_AUTHORITY_DATA);
    },
    async acknowledgeMocNotification({ getters, commit }, payload) {
        const { authCode, transactionId } = payload;
        const history = getters.mocHistory[authCode];
        if (!history) {
            return;
        }
        history.notifications.forEach(submission => {
            if (submission.transactionId === transactionId) {
                submission.statusAcknowledged = true;
            }
        });
        commit(SET_MOC_HISTORY, { authCode, result: history });
        return history;
    },
    async setTrackBundlesToCache({ commit }, trackBundles) {
        commit(SET_TRACK_BUNDLES, trackBundles);
        const tracks = trackBundles.map(trackBundle => trackBundle.cmeTrack);
        commit(SET_TRACKS, tracks);
        commit(SET_SORTED_TRACKS, tracks);
        trackBundles.forEach(trackBundle => {
            commit(SET_TRACKS_MAP, {
                trackCode: trackBundle.cmeTrack.trackCode,
                cmeTrack: trackBundle.cmeTrack
            });
            commit(SET_TRACK_BUNDLES_MAP, {
                trackCode: trackBundle.cmeTrack.trackCode,
                trackBundle
            });
        });
        return tracks;
    },
    async getTracks({ getters, dispatch }) {
        if (getters.cmeTracks) {
            return Promise.resolve(getObjectCopy(getters.cmeTracks));
        }
        try {
            const trackBundles = await cmeRest('cme/tracks/getAllTracks');
            const tracks = await dispatch('setTrackBundlesToCache', trackBundles);
            if (!trackBundles || !tracks) {
                return Promise.reject();
            }
            return getObjectCopy(tracks);
        }
        catch (error) {
            Logger.warn(`Error fetching all tracks - error: ${error.message}`);
        }
    },
    async getTrack({ getters, commit }, trackCode) {
        if (getters.cmeTracksMap && getters.cmeTracksMap[trackCode]) {
            return Promise.resolve(getObjectCopy(getters.cmeTracksMap[trackCode]));
        }
        try {
            const trackBundles = await cmeRest('cme/tracks/getTrackDetails', { trackCode });
            if (!trackBundles) {
                return Promise.reject();
            }
            commit(SET_TRACKS_MAP, {
                trackCode,
                cmeTrack: trackBundles[0].cmeTrack
            });
            commit(SET_TRACK_BUNDLES_MAP, {
                trackCode,
                trackBundle: trackBundles[0]
            });

            return getObjectCopy(trackBundles[0].cmeTrack);
        }
        catch (error) {
            Logger.warn(`Error loading trackCode [${trackCode}] - error: ${error.message}`);
        }
    },
    async getTrackBundle({ getters, commit }, trackCode) {
        if (getters.cmeTrackBundlesMap[trackCode]) {
            return getObjectCopy(getters.cmeTrackBundlesMap[trackCode]);
        }
        const trackBundles = await cmeRest('cme/tracks/getTrackDetails', { trackCode });
        if (!trackBundles) {
            return;
        }
        commit(SET_TRACK_BUNDLES_MAP, {
            trackCode,
            trackBundle: trackBundles[0]
        });
        commit(SET_TRACKS_MAP, {
            trackCode,
            cmeTrack: trackBundles[0].cmeTrack
        });

        return getObjectCopy(trackBundles[0]);
    },
    async getTrackBundles({ dispatch }) {
        if (getters.trackBundles) {
            return getObjectCopy(getters.trackBundles);
        }
        const trackBundles = await cmeRest('cme/tracks/getAllTracks');
        if (!trackBundles) {
            return;
        }
        dispatch('setTrackBundlesToCache', trackBundles);

        return getObjectCopy(trackBundles);
    },
    async getSortedTracks({ dispatch }) {
        return Promise.all([
            dispatch('getTracks'),
            dispatch('getActiveTrackInfo')
        ]).then(([ tracks, activeTrack ]) => {
            return dispatch('sortTracks', { tracks, activeTrack });
        });
    },
    sortTracks({ commit }, payload) {
        const { tracks, activeTrack } = payload;
        if (!tracks || !activeTrack) {
            return;
        }
        const activeTrackIndex = tracks.indexOf(tracks.find(track => {
            return track.trackCode === activeTrack.trackCode;
        }));

        const toReturn = [
            tracks[activeTrackIndex]
        ];

        // remove active track from sorted list
        tracks.splice(activeTrackIndex, 1);

        const sortedTracks = toReturn.concat(tracks);
        commit(SET_SORTED_TRACKS, sortedTracks);
        commit(SET_SELECTED_TRACK, 0);
        const firstTrack = sortedTracks[0];
        commit(SET_ACTIVE_TRACK, firstTrack);
        return sortedTracks;
    },
    async getActiveTrackInfo({ getters, commit }) {
        if (getters.activeTrackInfo) {
            return getObjectCopy(getters.activeTrackInfo);
        }
        const trackInfo = await cmeRest('cme/settings/getUserTrack');
        if (!trackInfo) {
            return;
        }
        commit(SET_ACTIVE_TRACK_INFO, trackInfo);
        return getObjectCopy(trackInfo);
    },
    async saveTrack({ commit, dispatch }, trackCode) {
        try {
            const data = await cmeRest('cme/settings/updateUserTrack', { trackCode });
            dispatch('invalidateCache');
            const track = await dispatch('getTrack', data.cmeUserTrackSetting.trackCode);
            commit(SET_CME_LABEL, track.labels.linkName);
        }
        catch (error) {
            return error;
        }
    },
    setFirstTimeCmeUser({ commit }, isFirstTimeCmeUser) {
        commit(SET_IS_FIRST_TIME_CME_USER, isFirstTimeCmeUser);
    },
    invalidateCache({ commit }) {
        commit(SET_ACTIVE_TRACK_INFO, null);
        commit(SET_ACTIVE_TRACK, null);
    },
    getHistory({ commit, getters }, authCode) {
        return getters.mocHistory[authCode]
            ? Promise.resolve(getObjectCopy(getters.mocHistory[authCode]))
            : cmeRest('cme/moc/submission/submissionHistory', { authCode }).then(result => {
                // Cache result until user leaves view
                commit(SET_MOC_HISTORY, { authCode, result });

                // If this.submissionsToConfirm object is empty, this means the user
                // came directly to the Status & History page, not from submitting MOC.
                if (!getters.submissionsToConfirm) {
                    return result;
                }

                // User has submitted MOC, the results of which are contained in
                // this.submissionsToConfirm. Retrieve the submission object
                // that matches selected board's authorityCode.
                // If found, then merge that submission data with the 'notifications' and
                // 'submissions' properties from the history response.
                const submission = getters.submissionsToConfirm.filter(
                    submission => submission.authorityCode === authCode
                )[0];
                if (submission) {
                    result = setStatusNotifications(submission, result);
                    result = setStatusSubmissions(submission, result);
                }
                return result;
            });
    },
    getFirstUserMocAuthorityCode({ getters }, settingsKey = 'moc') {
        const mocSettings = getters.boardByType(settingsKey);
        if (mocSettings.userAuthorities && mocSettings.userAuthorities.length) {
            return mocSettings.userAuthorities[0].mocAuthorityCode;
        }
    },
    onPageLeave({ commit }) {
        commit(RESET_MOC_HISTORY);
        commit(RESET_SUBMISSIONS_TO_CONFIRM);
    },
    onUpdateAccrualReflection({ getters, dispatch, commit }, payload) {
        const { accrualId } = payload;

        if (!getters.debounceReflections[accrualId]) {
            commit(SET_DEBOUNCE_REFLECTIONS, {
                accrualId,
                cb: debounce(() => {
                    dispatch('saveReflectionAnswers', payload);
                    commit(REMOVE_DEBOUNCE_REFLECTION, accrualId);
                }, AUTOSAVE_REFLECTIONS_DEBOUNCE_MS)
            });
        }
        getters.debounceReflections[accrualId] && getters.debounceReflections[accrualId]();
    },
    saveReflectionAnswers({ dispatch }, { accrualId, ...rest }) {
        dispatch('logSavedReflection', rest);

        utdRest('cme/accrual/saveReflectionByAccrualId', {
            accrualId,
            postData: { reflectionResponses: rest.responses }
        });
    },
    logSavedReflection({ rootGetters }, reflection) {
        const type = reflection.isUpdate ? 'update' : 'save';
        const device = rootGetters['device/isDesktopView']
            ? C_RESPONSIVE.DESKTOP_VIEW
            : C_RESPONSIVE.MOBILE_VIEW;

        new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
            uiElementName: `autosave-credit-reflection-${type}-${device}`,
            optData: getOptEventData(reflection)
        });
    },
    setSubmissionsToConfirm({ commit }, submissions) {
        if (submissions && submissions.length) {
            submissions.forEach(submission => {
                // A quirk of the api is that new submissions have submissionIds rather
                // than transactionIds (different label for the same thing). Normalize this here.
                if (!submission.transactionId) {
                    submission.transactionId = submission.submissionId;
                }
            });
        }

        commit(SET_SUBMISSIONS_TO_CONFIRM, submissions);
    }
};

const setStatusNotifications = (submission, result) => {
    const { transactionId } = submission;

    if (!result.notifications) {
        result.notifications = [ submission ];
    }
    else if (!result.notifications.some(n => n.transactionId === transactionId)) {
        result.notifications.push(submission);
    }
    return result;
};

const setStatusSubmissions = (submission, result) => {
    // Make sure submission we're confirming was submitted is also included
    // in submissions by year. This is in case the back-end data omits due
    // to timing constraints.
    if (!result.submissions) {
        result.submissions = { };
    }

    const { year, transactionId } = submission;
    const yearSubs = result.submissions[year];

    if (!yearSubs) {
        result.submissions[year] = [ submission ];
    }
    else if (!yearSubs.some(s => s.transactionId === transactionId)) {
        result.submissions[year].unshift(submission);
    }
    return result;
};

const cme = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default cme;

export const getOptEventData = ({ creditValue, questions, responses }) => {
    const delimiter = '---';
    const mySearchGoal = getMySearchGoal({
        questions,
        responses
    });
    const applicationToPractice
        = getApplicationToPractice({
            questions,
            responses
        });
    return [ mySearchGoal, applicationToPractice, creditValue ].join(delimiter);
};

const getMySearchGoal = ({ questions, responses }) => {
    const applicationQuestion
        = questions.find(q => q.questionType === 'searchGoal' && q.active);

    return getResponse({
        applicationQuestion,
        responses
    });
};
const getResponse = ({ applicationQuestion, responses }) => {
    const applicationResponse = applicationQuestion
            && responses.find(r => r.questionId === applicationQuestion.id);

    const answerId = applicationResponse
            && applicationResponse.answerIds
            && applicationResponse.answerIds[0];

    const applicationToPractice = answerId
            && applicationQuestion.reflectionAnswers.find(a => a.id === answerId);

    return applicationToPractice ? applicationToPractice.answer : '';
};

const getApplicationToPractice = ({ questions, responses }) => {
    const applicationQuestion = questions.find(q => q.questionType === 'application' && q.active);

    return getResponse({ applicationQuestion, responses });
};
