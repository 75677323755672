<template>
  <div v-show="toolbarOverflowVisible"
       id="overflowTopicDropdown"
       class="overflowTopicDropdown">
    <ul>
      <li class="topoverflowTopicDropdown" />
      <li v-show="toolbarFindInTopicVisible"
          class="mobileOverflow">
        <a @click="toggleFit">Find in topic</a>
      </li>
      <li v-if="hasMachineTranslation"
          class="mobileOverflow learn-more">
        <a id="learn-more-link"
           @click="openLearnMore">
          <div class="learn-more-label">
            {{ learnMoreLabel }}
          </div>
        </a>
      </li>
      <li v-show="toolbarFormularyVisible"
          id="topicFormularyMobile"
          class="mobileOverflow menuItem">
        <a @click="$emit('formulink-clicked')">{{ $t("FORMULINK.FORMULARY") }}</a>
      </li>
      <li v-show="toolbarPrintVisible"
          id="topicPrint"
          class="mobileOverflow">
        <a id="printTopic"
           target="_blank"
           rel="noopener"
           :href="toolbarPrintUrl">{{ $t("CONTENT.TOOLS_PRINT") }}</a>
      </li>
      <li v-show="toolbarShareVisible"
          id="topicShare"
          class="mobileOverflow">
        <topic-toolbar-share />
      </li>
      <li v-show="toolbarFontSizeVisible"
          id="topicFontSize"
          class="mobileOverflow topicFontSize">
        <font-size />
      </li>
    </ul>
  </div>
</template>

<script>

import { createPositionManager } from '_acaSrc/utility/DOM';
import topicToolbarShare from '_acaSrc/components/contents/topic/toolbar/TopicToolbarShare.vue';
import fontSize from '_acaSrc/components/contents/topic/toolbar/FontSize.vue';
import { mapGetters, mapMutations } from 'vuex';
import { C_TOPIC, C_EVENTS, C_LANGUAGES_NAMES } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';
import {
    SET_IS_FIT_DIALOG_SHOWN,
    SET_TOOLBAR_OVERFLOW_VISIBLE
} from '_acaSrc/store/topic/topic.store';
const ENGLISH_CODE = C_LANGUAGES_NAMES.EN.COUNTRY_CODE;

export default {
    components: {
        topicToolbarShare,
        fontSize
    },
    computed: {
        ...mapGetters('topic', [
            'toolbar',
            'toolbarPrintUrl',
            'toolbarPrintVisible',
            'toolbarOverflowVisible',
            'toolbarShareVisible',
            'toolbarFormularyVisible',
            'toolbarFontSizeVisible',
            'toolbarFindInTopicVisible',
            'isFitDialogShown',
            'hasMachineTranslation',
            'machineTranslationLabels',
            'isNotLocalizedTopicView',
            'contentLanguage'
        ]),
        learnMoreLabel() {
            const localLanguage = this.contentLanguage && this.contentLanguage.toLowerCase();
            const languageCode
                = this.isNotLocalizedTopicView ? ENGLISH_CODE : localLanguage || ENGLISH_CODE;
            const learnMoreTitle = this.machineTranslationLabels
                && this.machineTranslationLabels[languageCode]
                && this.machineTranslationLabels[languageCode].disclaimerTitle;
            return learnMoreTitle || 'Machine Translation Disclaimer';
        }
    },
    watch: {
        toolbarOverflowVisible(newVal) {
            if (newVal) {
                new PubSub().publish('wkutd.close-toolbar-menus', this.uuid);
                this.$nextTick(() => {
                    this.positionManager.update();
                    this.utdLockScroll();
                });
            }
            else {
                this.utdUnlockScroll();
            }
        }
    },
    mounted() {
        this.toolbarOverflowVisible && this.utdLockScroll();
        this.positionManager = createPositionManager(C_TOPIC.TOOLS_MENU_BTN_SELECTOR, this.$el);
        new PubSub().subscribe('wkutd.close-toolbar-menus', this.closeMenu);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.close-toolbar-menus', this.closeMenu);
        this.toolbarOverflowVisible && this.utdUnlockScroll();
    },
    methods: {
        ...mapMutations('topic', [
            SET_TOOLBAR_OVERFLOW_VISIBLE,
            SET_IS_FIT_DIALOG_SHOWN
        ]),
        closeMenu(exceptMenuUUID) {
            const isNotThisMenu = !this.uuid || !exceptMenuUUID || exceptMenuUUID !== this.uuid;
            if (isNotThisMenu) {
                this[SET_TOOLBAR_OVERFLOW_VISIBLE](false);
                this.utdUnlockScroll();
            }
        },
        toggleFit() {
            new PubSub().publish('wkutd.close-toolbar-menus');
            this[SET_IS_FIT_DIALOG_SHOWN](!this.isFitDialogShown);
        },
        openLearnMore() {
            new PubSub().publish(C_EVENTS.OPEN_LEARN_MORE);
            this.closeMenu();
        }
    }
};
</script>

<style lang="less" scoped>
  .mobileOverflow {
    white-space: nowrap;
  }

  #overflowTopicDropdown :deep(.wkce-icon-share) {
    display: none;
  }
</style>
